// core
import React, { useEffect, useState } from 'react';

// apollo
import { useQuery } from '@apollo/client';
import reviewsQuery from '../graphql/ReviewsQuery.graphql';

// material
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Rating from '@material-ui/lab/Rating';

// components
import { Review } from './Review';
import { LoadingDiv } from 'components/LoadingDiv';
import { ErrorMessage } from 'components/ErrorMessage';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2)
  },
  averageRating: {
    position: 'absolute',
    top: 17,
    right: 0,
    [theme.breakpoints.down('xs')]: {
      position: 'static'
    }
  },
  buttonWrap: {
    textAlign: 'center',
    marginTop: theme.spacing(4)
  }
}));

export const Reviews = () => {
  const css = useStyles();

  const { data, loading, error } = useQuery(reviewsQuery);

  // store random indexes beacause of SSR
  const [randomIndexes, setRandomIndexes] = useState<number[]>([]);

  function getRandomIndexes(arr: any[], n: number) {
    const result = new Array(n);
    let len = arr.length;
    const taken = new Array(len);

    if (n > len) throw new RangeError('getRandomIndexes: more elements taken than available');

    while (n--) {
      const x = Math.floor(Math.random() * len);
      result[n] = x in taken ? taken[x] : x;
      taken[x] = --len in taken ? taken[len] : len;
    }

    return result;
  }

  useEffect(() => {
    if (!randomIndexes.length && data && data.reviews.best) {
      setRandomIndexes(getRandomIndexes(data.reviews.best, 3));
    }
  }, [randomIndexes, data]);

  return loading ? (
    <LoadingDiv />
  ) : error ? (
    <ErrorMessage>{error.message}</ErrorMessage>
  ) : (
    <>
      <Rating
        readOnly
        size="large"
        value={data.reviews.averageRating}
        className={css.averageRating}
      />

      <Grid container spacing={3} className={css.root}>
        {randomIndexes.map((index) => {
          const { id, reviewerName, reviewerPhoto, rating, comment } = data.reviews.best[index];
          return (
            <Grid key={id} item xs={12} md={4}>
              <Review
                reviewerName={reviewerName}
                reviewerPhoto={reviewerPhoto}
                rating={rating}
                comment={comment}
              />
            </Grid>
          );
        })}
      </Grid>

      <div className={css.buttonWrap}>
        <Button
          variant="contained"
          color="primary"
          href="https://www.google.sk/search?q=EDENbazar#lrd=0x476c8fb07c0c3db5:0xabc5a3ab24d8104a,1,,,"
        >
          Všetky recenzie
        </Button>
      </div>
    </>
  );
};
