// core
import React, { useState, useEffect } from 'react';

// libraries
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

// material
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';

// material icons
import CloseIcon from '@material-ui/icons/Close';

// utils
import { usePrevious } from 'utils/functions';

export interface Props {
  photo: string | null;
  title: string;
  hasPrev: boolean;
  hasNext: boolean;
  openPrev: () => void;
  openNext: () => void;
  onClose: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'none'
  },
  paper: {
    backgroundColor: '#333',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '16px !important',
      marginRight: '16px !important'
    }
  },
  content: {
    padding: 0,
    '& > img': {
      minWidth: 300,
      minHeight: 180,
      maxWidth: '100%',
      display: 'block',
      color: '#fff',
      zIndex: 2,
      position: 'relative'
    }
  },
  closeButton: {
    position: 'absolute',
    top: 4,
    right: 4,
    color: '#fff',
    zIndex: 10
  },
  loading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
    color: '#fff',
    zIndex: 1
  }
}));

export const Popup = () => {
  const sendDataToGTM = useGTMDispatch();

  const [open, setOpen] = useState(true);
  const prevOpen = usePrevious(open);

  useEffect(
    () => {
      if (!open && !!prevOpen) {
        sendDataToGTM({
          event: 'modalview',
          modalName: 'popup'
        });
      }
    },
    // we dont want to run on each dep update, only on "open" change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [open]
  );

  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  const container = typeof window !== 'undefined' ? document.getElementById('popup') : null;

  if (!container) {
    return null;
  }

  return (
    <Dialog
      open={open}
      maxWidth="md"
      classes={{ root: classes.root, paper: classes.paper }}
      disableEscapeKeyDown
      container={container}
    >
      <IconButton aria-label="Zatvoriť" className={classes.closeButton} onClick={handleClose}>
        <CloseIcon />
      </IconButton>

      <DialogContent className={classes.content}>
        <CircularProgress size={24} className={classes.loading} />
        <img alt="Marketingový banner" />
      </DialogContent>
    </Dialog>
  );
};
