// core
import React, { useCallback } from 'react';

// apollo
import { CarDetailsFormFragment } from '../graphql/CarDetailsFormFragment';
import { CarDetailsFormTemplateFragment } from '../graphql/CarDetailsFormTemplateFragment';

// components
import { Subheading } from 'components/Subheading';

// formik
import { Form, useFormikContext } from 'formik';

// libraries
import { useHistory } from 'react-router-dom';

// material
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

// partials
import { CarDetailsFormValues } from './CarDetailsForm';
import { CarDetailsFormFields } from './CarDetailsFormFields';
import { CarDetailsFormPhotos } from './CarDetailsFormPhotos';
import { CarDetailsFormAuthenticityReport } from './CarDetailsFormAuthenticityReport';
import { CarDetailsFormAttachments } from './CarDetailsFormAttachments';

export interface Props {
  data: (CarDetailsFormFragment & Omit<CarDetailsFormTemplateFragment, '__typename'>) | null;
  isTemplate: boolean;
}

const useStyles = makeStyles((theme) => ({
  subheading: {
    marginTop: 32,
    '&:before': {
      bottom: 6
    }
  },
  submitButton: {
    marginRight: 16
  },
  errorMessage: {
    display: 'inline-block',
    marginLeft: 32
  }
}));

export const CarDetailsFormComposer = ({ isTemplate, data }: Props) => {
  const classes = useStyles();

  const history = useHistory();
  const { values, errors, isSubmitting, setFieldValue, submitForm } =
    useFormikContext<CarDetailsFormValues>();

  const handleTemplateSave = useCallback(() => {
    setFieldValue('saveType', 'template');
    setTimeout(() => {
      submitForm();
    }, 0);
  }, [setFieldValue, submitForm]);

  const handleCarSave = useCallback(() => {
    setFieldValue('saveType', 'car');
    setTimeout(() => {
      submitForm();
    }, 0);
  }, [setFieldValue, submitForm]);

  const handleCancel = useCallback(() => {
    if (data && data.mainCategory) {
      history.push(`/vozidla-na-predaj/${data.mainCategory.slug}/${data.slug}`);
    } else {
      history.push('/vozidla-na-predaj');
    }
  }, [data, history]);

  return (
    <Form>
      <CarDetailsFormFields isTemplate={isTemplate} isEdit={Boolean(!isTemplate && data?.id)} />

      {(!isTemplate || data) && (
        <>
          <Subheading variant="h5" className={classes.subheading}>
            Fotky
          </Subheading>

          <CarDetailsFormPhotos car={data} />

          <Subheading variant="h5" className={classes.subheading}>
            Zaverečná správa z prehliadky
          </Subheading>

          <CarDetailsFormAuthenticityReport car={data} />

          {data?.id && (
            <>
              <Subheading variant="h5" className={classes.subheading}>
                Prilohy
              </Subheading>

              <CarDetailsFormAttachments car={data} />
            </>
          )}
        </>
      )}

      <br />
      <br />
      <Button
        variant="contained"
        color="primary"
        disabled={isSubmitting}
        onClick={isTemplate && !data ? handleTemplateSave : handleCarSave}
        className={classes.submitButton}
      >
        {isSubmitting &&
        ((isTemplate && !data && values.saveType === 'template') ||
          values.saveType !== 'template') ? (
          <CircularProgress size={24} />
        ) : isTemplate && !data ? (
          'Uložiť nový vzor'
        ) : !isTemplate && data ? (
          'Uložiť'
        ) : (
          'Pridať vozidlo'
        )}
      </Button>

      {isTemplate && data ? (
        <Button
          variant="text"
          color="primary"
          disabled={isSubmitting}
          onClick={handleTemplateSave}
          className={classes.submitButton}
        >
          {isSubmitting && values.saveType === 'template' ? (
            <CircularProgress size={24} />
          ) : (
            'Aktualizovať vzor'
          )}
        </Button>
      ) : null}

      <Button variant="text" color="default" disabled={isSubmitting} onClick={handleCancel}>
        Zrušiť
      </Button>

      {Object.keys(errors).length > 0 ? (
        <Typography variant="body2" color="error" className={classes.errorMessage}>
          Formulár obsahuje neplatné údaje
        </Typography>
      ) : null}
    </Form>
  );
};
