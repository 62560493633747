// core
import React from 'react';

export interface Props {
  decimals?: number;
  children: number | string;
}

export const formatNumber = (value: number | string, decimals?: number): string => {
  const formattedValue = Number(value).toFixed(typeof decimals === 'number' ? decimals : 2);

  // TODO: internationalization
  return formattedValue
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    .replace('.', ',');
};

export const NumberString = ({ decimals, children }: Props) => {
  return <>{formatNumber(children, decimals)}</>;
};
