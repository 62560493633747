// core
import React, { ReactNode } from 'react';

// libraries
import classnames from 'classnames';

// material
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

export interface Props {
  car: {
    /**
     * Whether the car is active
     */
    readonly active: boolean;
    /**
     * If car is set as comming soor
     */
    readonly soon: boolean;
    /**
     * If car is set as new
     */
    readonly new: boolean;
    /**
     * If car is set as reserved
     */
    readonly reserved: boolean;
    /**
     * If car is set as sold
     */
    readonly sold: boolean;
    /**
     * Fuel type id
     */
    readonly fuel: string | null;
    /**
     * State id of car
     */
    readonly state: string;
    /**
     * Current discount
     */
    readonly discount: number;
    /**
     * List of hightlight names for car
     */
    readonly highlightsName: ReadonlyArray<string> | null;
  };
  small?: boolean;
}

const useStyles = makeStyles((theme) => ({
  badge: {
    position: 'absolute',
    top: -16,
    left: -24,
    width: 96,
    height: 96,
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
    textAlign: 'center',
    lineHeight: '1.3em',
    color: '#fff',
    transform: 'rotate(-15deg)',
    zIndex: 2,
    fontSize: '1.2em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'normal',
    [theme.breakpoints.down('md')]: {
      left: -12
    },
    '& strong': {
      display: 'inline-block',
      marginTop: '0.5em',
      fontSize: '1.3em'
    }
  },
  small: {
    top: -12,
    left: -16,
    width: 64,
    height: 64,
    fontSize: '0.9em',
    [theme.breakpoints.down('sm')]: {
      width: 128,
      height: 128,
      fontSize: '1.6em',
      top: -20,
      left: -18
    },
    [theme.breakpoints.down('xs')]: {
      width: 96,
      height: 96,
      fontSize: '1.2em',
      top: -18,
      left: -11
    }
  },
  second: {
    top: 64,
    zIndex: 1,
    '&$small': {
      top: 32,
      [theme.breakpoints.down('sm')]: {
        top: 72
      },
      [theme.breakpoints.down('xs')]: {
        top: 48
      }
    }
  },
  dark: {
    backgroundColor: '#666'
  },
  electro: {
    backgroundColor: '#43a047'
  },
  canterBig: {
    top: '50%',
    left: '50%',
    width: 192,
    height: 192,
    marginTop: -96,
    marginLeft: -96,
    fontSize: '2.5em'
  }
}));

export const CarBadge = ({ car, small = false }: Props) => {
  const classes = useStyles();

  const getBadge = (label: ReactNode, className?: string) => (
    <Typography
      variant={small ? 'body2' : 'h6'}
      component="span"
      className={classnames(classes.badge, small ? classes.small : '', className || '')}
    >
      {label}
    </Typography>
  );

  const hasTwoBadges = !car.sold && (car.new || car.discount > 0);

  return (
    <>
      {car.sold || !car.active ? (
        getBadge('Predané', classes.canterBig)
      ) : (
        <>
          {car.new
            ? getBadge('Novinka')
            : car.discount > 0
            ? getBadge(
                <span>
                  <strong>Akcia</strong>
                  <br />
                  <small>{-car.discount}&nbsp;&euro;</small>
                </span>
              )
            : null}

          {car.reserved
            ? getBadge('Reserve', classnames(classes.dark, hasTwoBadges ? classes.second : ''))
            : car.soon
            ? getBadge('Čoskoro', classnames(classes.dark, hasTwoBadges ? classes.second : ''))
            : car.fuel === '6'
            ? getBadge('Elektro', classnames(classes.electro, hasTwoBadges ? classes.second : ''))
            : car.highlightsName &&
              Array.isArray(car.highlightsName) &&
              car.highlightsName.find((name) => name === 'Automat')
            ? getBadge('Automat', classnames(classes.dark, hasTwoBadges ? classes.second : ''))
            : car.state === '1'
            ? getBadge('Nové', classnames(classes.dark, hasTwoBadges ? classes.second : ''))
            : null}
        </>
      )}
    </>
  );
};
