import Resizer from 'react-image-file-resizer';

export const getOrientation = (file: File): Promise<number> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      if (!e.target?.result) {
        return resolve(0);
      }

      const view = new DataView(e.target.result as ArrayBuffer);

      if (view.getUint16(0, false) !== 0xffd8) {
        return resolve(-2);
      }

      const length = view.byteLength;
      let offset = 2;

      while (offset < length) {
        if (view.getUint16(offset + 2, false) <= 8) return resolve(-1);
        const marker = view.getUint16(offset, false);
        offset += 2;
        if (marker === 0xffe1) {
          if (view.getUint32((offset += 2), false) !== 0x45786966) {
            return resolve(-1);
          }

          const little = view.getUint16((offset += 6), false) === 0x4949;
          offset += view.getUint32(offset + 4, little);
          const tags = view.getUint16(offset, little);
          offset += 2;
          for (let i = 0; i < tags; i++) {
            if (view.getUint16(offset + i * 12, little) === 0x0112) {
              return resolve(view.getUint16(offset + i * 12 + 8, little));
            }
          }
        } else if ((marker & 0xff00) !== 0xff00) {
          break;
        } else {
          offset += view.getUint16(offset, false);
        }
      }
      return resolve(-1);
    };

    reader.onerror = (err) => reject(err);

    reader.readAsArrayBuffer(file);
  });
};

export const resizeImage = (file: File): Promise<Blob> => {
  return getOrientation(file).then(
    (orientation) =>
      new Promise((resolve) => {
        Resizer.imageFileResizer(
          file,
          1200,
          1200,
          'JPEG',
          100,
          orientation > 0
            ? orientation >= 7
              ? 270
              : orientation >= 5
              ? 90
              : orientation >= 3
              ? 180
              : 0
            : 0,
          (uri) => resolve(uri as Blob),
          'blob'
        );
      })
  );
};
