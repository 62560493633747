// core
import React, {
  memo,
  Children,
  ReactNode,
  ReactElement,
  useEffect,
  cloneElement,
  useMemo
} from 'react';

// libraries
import classnames from 'classnames';

// apollo
import { useQuery, DocumentNode } from '@apollo/client';
import loadSelectOptionsQuery from './graphql/LoadSelectOptionsQuery.graphql';
import {
  LoadSelectOptionsQuery,
  LoadSelectOptionsQueryVariables
} from './graphql/LoadSelectOptionsQuery';
import { SelectOptionFragment } from './graphql/SelectOptionFragment';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// components
import { LoadingDiv } from 'components/LoadingDiv';

export interface Props {
  id: string;
  filter?: {
    [key: string]: string;
  };
  query?: DocumentNode;
  sort?: (data1: SelectOptionFragment, data2: SelectOptionFragment) => number;
  renderOption?: (data: SelectOptionFragment) => ReactNode;
  children: ReactElement<any> | ((data: SelectOptionFragment[]) => ReactElement<any>);
  onLoad?: (data: LoadSelectOptionsQuery) => void;
}

const useStyles = makeStyles((theme) => ({
  loadingDiv: {
    height: 29,
    marginTop: 24,
    marginBottom: 0
  }
}));

export const LoadSelectOptions = memo(function LoadSelectOptions({
  id,
  filter,
  sort,
  query,
  renderOption,
  onLoad,
  children
}: Props) {
  const { loading, error, options } = useLoadSelectOptions({
    id,
    filter,
    query,
    onLoad,
    sort
  });

  const optionsElements = useMemo(() => {
    const optionsElements = options.map((option) =>
      typeof renderOption === 'function' ? (
        renderOption(option)
      ) : (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      )
    );

    optionsElements.unshift(<option key="0" value="" />);

    return optionsElements;
  }, [options, renderOption]);

  if (error) {
    console.error(error);
    global.bugsnagClient.notify(error);
  }

  const child = Children.only(typeof children === 'function' ? children(options) : children);

  return (
    <LoadSelectOptionsComponent loading={loading} error={!!error} hasLabel={!!child?.props.label}>
      {cloneElement(child, {
        children: Children.toArray(child.props.children).concat(optionsElements),
        SelectProps: Object.assign(
          {
            native: true
          },
          child.props.SelectProps
        )
      })}
    </LoadSelectOptionsComponent>
  );
});

export interface LoadSelectOptionsComponentProps {
  loading: boolean;
  error: boolean;
  children: ReactElement<any> | null;
  hasLabel: boolean;
}

export const LoadSelectOptionsComponent = memo(function LoadSelectOptionsComponent({
  loading,
  error,
  children,
  hasLabel
}: LoadSelectOptionsComponentProps) {
  const classes = useStyles();

  return loading ? (
    <LoadingDiv
      classes={{
        root: classnames({ [classes.loadingDiv]: hasLabel })
      }}
    />
  ) : error ? (
    <Typography variant="caption" color="error">
      Chyba v spojení so serverom. Skúste to prosím znova.
    </Typography>
  ) : (
    children
  );
});

export interface UseLoadSelectOptionsHookOptions {
  id: string;
  filter?: {
    [key: string]: string;
  };
  query?: DocumentNode;
  sort?: (data1: SelectOptionFragment, data2: SelectOptionFragment) => number;
  onLoad?: (data: LoadSelectOptionsQuery) => void;
}

export const useLoadSelectOptions = ({
  id,
  filter,
  query,
  onLoad,
  sort
}: UseLoadSelectOptionsHookOptions) => {
  const { data, loading, error } = useQuery<
    LoadSelectOptionsQuery,
    LoadSelectOptionsQueryVariables
  >(query || loadSelectOptionsQuery, {
    variables: { id, filter: filter ? JSON.stringify(filter) : null }
  });

  useEffect(() => {
    if (data && typeof onLoad === 'function') {
      onLoad(data);
    }
  }, [data, onLoad]);

  const options = useMemo(() => {
    let options = (data?.options && [...data.options]) || [];

    if (typeof sort === 'function') {
      options = options.sort(sort);
    }

    return options;
  }, [data, sort]);

  return {
    loading,
    error,
    options
  };
};
