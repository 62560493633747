// core
import React, { MouseEvent } from 'react';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import ImageList from '@material-ui/core/ImageList';
import GridListTile from '@material-ui/core/GridListTile';

// components

export interface Props {
  images: string[];
  carTitle: string;
  onPhotoClick: (key: number) => void;
}

const useStyles = makeStyles((theme) => ({
  gallery: {
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',
    '& > img': {
      width: 'auto'
    }
  },
  photoLink: {
    cursor: 'pointer',
    position: 'relative',
    display: 'block'
  },
  image: {
    width: '100%',
    height: 'auto'
  }
}));

export const Gallery = ({ images, carTitle, onPhotoClick }: Props) => {
  const css = useStyles();

  const handleClick = (key: number) => (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    onPhotoClick(key);
  };

  return (
    <ImageList rowHeight="auto" cols={3.3} className={css.gallery}>
      {images.map((url, key) => (
        <GridListTile key={key}>
          <a
            href={`${global.cdnUrl}/fotografia/${url}`}
            onClick={handleClick(key)}
            title={carTitle}
            className={css.photoLink}
          >
            <img
              src={`${global.cdnUrl}/fotografia/${url}`}
              alt={carTitle}
              className={css.image}
              width="600"
              height="450"
            />
          </a>
        </GridListTile>
      ))}
    </ImageList>
  );
};
