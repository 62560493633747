import React, { useRef, useState, useEffect, useCallback, ReactNode, createContext } from 'react';

// libraries
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'querystring';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

export interface Filter {
  manufacturer: string | null;
  models: string[] | null;
  yearFrom: number | null;
  yearTo: number | null;
  mileageFrom: number | null;
  mileageTo: number | null;
  priceFrom: number | null;
  priceTo: number | null;
  fuel: string[] | null;
  gearbox: string[] | null;
  drive: string[] | null;
}

export const carsFilterContext = createContext<{
  filter: Filter;
  hasFilter: boolean;
  setFilter: (filter: Filter) => void;
  resetFilter: () => void;
  filterCarsLength: number | null;
  setFilterCarsLength: (length: number | null) => void;
  allFilterResults: boolean;
  setAllFilterResults: (all: boolean) => void;
  setManufacturers: (values: any[]) => void;
  setModels: (values: any[]) => void;
  setDriveValues: (values: any[]) => void;
}>({
  filter: {
    manufacturer: null,
    models: null,
    yearFrom: null,
    yearTo: null,
    mileageFrom: null,
    mileageTo: null,
    priceFrom: null,
    priceTo: null,
    fuel: null,
    gearbox: null,
    drive: null
  },
  hasFilter: false,
  setFilter: () => {},
  resetFilter: () => {},
  filterCarsLength: null,
  setFilterCarsLength: () => {},
  allFilterResults: false,
  setAllFilterResults: () => {},
  setManufacturers: () => {},
  setModels: () => {},
  setDriveValues: () => {}
});

interface Props {
  children: ReactNode;
}

// keep filter also after unmount
// eslint-disable-next-line no-var
var persisted: {
  filter: Filter;
  allFilterResults: boolean;
} = {
  filter: {
    manufacturer: null,
    models: null,
    yearFrom: null,
    yearTo: null,
    mileageFrom: null,
    mileageTo: null,
    priceFrom: null,
    priceTo: null,
    fuel: null,
    gearbox: null,
    drive: null
  },
  allFilterResults: false
};

export const CarsFilterContextProvider = ({ children }: Props) => {
  const mounted = useRef<boolean>(false);
  const manufacturersRef = useRef<any[]>([]);
  const modelsRef = useRef<any[]>([]);
  const driveValuesRef = useRef<any[]>([]);
  const sendDataToGTM = useGTMDispatch();

  const location = useLocation<{ filter: string }>();
  const history = useHistory();

  const prepareFilter = (data: Partial<Filter>): Filter => ({
    manufacturer: data.manufacturer ? data.manufacturer.toString() : null,
    models: (data.models && data.models.map((model) => model.toString())) || null,
    yearFrom: data.yearFrom || null,
    yearTo: data.yearTo || null,
    mileageFrom: data.mileageFrom || null,
    mileageTo: data.mileageTo || null,
    priceFrom: data.priceFrom || null,
    priceTo: data.priceTo || null,
    fuel: data.fuel ? Object.values(data.fuel).filter(Boolean) : null,
    gearbox: data.gearbox ? Object.values(data.gearbox).filter(Boolean) : null,
    drive: data.drive ? Object.values(data.drive).filter(Boolean) : null
  });

  const [filter, setFilter] = useState<Filter>(prepareFilter({}));
  const [filterCarsLength, setFilterCarsLength] = useState<number | null>(null);
  const [allFilterResults, setAllFilterResults] = useState<boolean>(
    persisted.allFilterResults || false
  );

  // load filter from URL
  useEffect(
    () => {
      const { filter } = queryString.parse(location.search.substring(1));

      if (filter) {
        try {
          const filterValues: Partial<Filter> = JSON.parse(filter.toString());

          if (filterValues && Object.keys(filterValues).length > 0) {
            setFilter(prepareFilter(filterValues));
          }
        } catch (e) {
          global.bugsnagClient.notify('Failed to parse Filter settings from query string', {
            metaData: { queryString: filter }
          });
        }
      } else if (persisted.filter) {
        setFilter(prepareFilter(persisted.filter));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // store filter on unmount
  useEffect(
    () => {
      return () => {
        // Remember filter for the next mount
        persisted = { filter, allFilterResults };
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filter, allFilterResults]
  );

  useEffect(
    () => {
      if (!mounted.current) {
        mounted.current = true;
        return;
      }

      if (Object.keys(filter).find((key) => Boolean(filter[key as keyof Filter]))) {
        const clearValues = (values: Filter): Partial<Filter> => {
          return Object.keys(values).reduce(
            (result, key) =>
              values[key as keyof Filter]
                ? { ...result, [key]: values[key as keyof Filter] }
                : result,
            {}
          );
        };

        history.replace({
          pathname: history.location.pathname,
          search: '?filter=' + JSON.stringify(clearValues(filter))
        });

        const getDriveValues = (values: any) => {
          const keys = Object.keys(values).filter((key) => !!values[key]);

          return keys.map((key) => {
            const selected = driveValuesRef.current.find((o: any) => o.value === key);
            return selected ? selected.name : key;
          });
        };

        sendDataToGTM({
          event: 'event',
          eventCategory: 'Filter',
          eventAction: 'Set',
          eventValue: undefined,
          eventLabel: Object.keys(filter)
            .filter((key) => !!filter[key as keyof Filter])
            .sort()
            .map((key) => {
              const value: any = filter[key as keyof Filter];

              return key === 'manufacturer' && manufacturersRef.current
                ? `Manufacturer:${
                    (
                      manufacturersRef.current.find(
                        (manufacturer) => manufacturer.value === value
                      ) || {}
                    ).label
                  }`
                : key === 'models' && modelsRef.current
                ? `Model:${value
                    .map(
                      (v: string) =>
                        (modelsRef.current.find((model) => model.value === v) || {}).label
                    )
                    .join(',')}`
                : key === 'drive' && driveValuesRef.current
                ? `Drive:${getDriveValues(filter.drive).sort().join(',')}`
                : `${key.toLowerCase()}:${
                    value && typeof value === 'object'
                      ? Object.keys(value)
                          .filter((k) => k && value[k])
                          .sort()
                          .join(',')
                      : value || ''
                  }`;
            })
            .join(', ')
        });
      } else if (history.location.search) {
        history.replace({
          pathname: history.location.pathname,
          search: undefined
        });

        sendDataToGTM({
          event: 'event',
          eventCategory: 'Filter',
          eventAction: 'Reset',
          eventValue: undefined,
          eventLabel: undefined
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filter]
  );

  useEffect(() => {
    if (filter.manufacturer && manufacturersRef.current) {
      sendDataToGTM({
        event: 'event',
        eventCategory: 'Filter',
        eventAction: 'Changed',
        eventValue: undefined,
        eventLabel: `Manufacturer:${
          (
            manufacturersRef.current.find(
              (manufacturer) => manufacturer.value === filter.manufacturer
            ) || {}
          ).label
        }`
      });
    }
  }, [filter.manufacturer, sendDataToGTM]);

  useEffect(() => {
    if (filter.models && modelsRef.current) {
      sendDataToGTM({
        event: 'event',
        eventCategory: 'Filter',
        eventAction: 'Changed',
        eventValue: undefined,
        eventLabel: `Model:${filter.models
          .map((value) => (modelsRef.current.find((model) => model.value === value) || {}).label)
          .join(',')}`
      });
    }
  }, [filter.models, sendDataToGTM]);

  useEffect(() => {
    if (filter.yearFrom) {
      sendDataToGTM({
        event: 'event',
        eventCategory: 'Filter',
        eventAction: 'Changed',
        eventValue: undefined,
        eventLabel: `YearFrom:${filter.yearFrom}`
      });
    }
  }, [filter.yearFrom, sendDataToGTM]);

  useEffect(() => {
    if (filter.yearTo) {
      sendDataToGTM({
        event: 'event',
        eventCategory: 'Filter',
        eventAction: 'Changed',
        eventValue: undefined,
        eventLabel: `YearFrom:${filter.yearTo}`
      });
    }
  }, [filter.yearTo, sendDataToGTM]);

  useEffect(() => {
    if (filter.mileageFrom) {
      sendDataToGTM({
        event: 'event',
        eventCategory: 'Filter',
        eventAction: 'Changed',
        eventValue: undefined,
        eventLabel: `MileageTo:${filter.mileageFrom}`
      });
    }
  }, [filter.mileageFrom, sendDataToGTM]);

  useEffect(() => {
    if (filter.mileageTo) {
      sendDataToGTM({
        event: 'event',
        eventCategory: 'Filter',
        eventAction: 'Changed',
        eventValue: undefined,
        eventLabel: `MileageTo:${filter.mileageTo}`
      });
    }
  }, [filter.mileageTo, sendDataToGTM]);

  useEffect(() => {
    if (filter.priceFrom) {
      sendDataToGTM({
        event: 'event',
        eventCategory: 'Filter',
        eventAction: 'Changed',
        eventValue: undefined,
        eventLabel: `PriceTo:${filter.priceFrom}`
      });
    }
  }, [filter.priceFrom, sendDataToGTM]);

  useEffect(() => {
    if (filter.priceTo) {
      sendDataToGTM({
        event: 'event',
        eventCategory: 'Filter',
        eventAction: 'Changed',
        eventValue: undefined,
        eventLabel: `PriceTo:${filter.priceTo}`
      });
    }
  }, [filter.priceTo, sendDataToGTM]);

  useEffect(() => {
    if (filter.fuel) {
      sendDataToGTM({
        event: 'event',
        eventCategory: 'Filter',
        eventAction: 'Changed',
        eventValue: undefined,
        eventLabel: `Fuel:${filter.fuel}`
      });
    }
  }, [filter.fuel, sendDataToGTM]);

  useEffect(() => {
    if (filter.gearbox) {
      sendDataToGTM({
        event: 'event',
        eventCategory: 'Filter',
        eventAction: 'Changed',
        eventValue: undefined,
        eventLabel: `Gearbox:${filter.gearbox}`
      });
    }
  }, [filter.gearbox, sendDataToGTM]);

  useEffect(() => {
    if (filter.drive && driveValuesRef.current) {
      sendDataToGTM({
        event: 'event',
        eventCategory: 'Filter',
        eventAction: 'Changed',
        eventValue: undefined,
        eventLabel: `Drive:${
          (driveValuesRef.current.find((drive) => drive.value === filter.drive) || {}).label
        }`
      });
    }
  }, [filter.drive, sendDataToGTM]);

  const hasFilter = Boolean(
    filter &&
      typeof filter === 'object' &&
      Array.prototype.find.call(Object.keys(filter), (key) => !!filter[key as keyof Filter])
  );

  const handleReset = useCallback(() => {
    setFilter(prepareFilter({}));
  }, []);

  const setManufacturers = useCallback((manufacturers) => {
    manufacturersRef.current = manufacturers;
  }, []);

  const setModels = useCallback((models) => {
    modelsRef.current = models;
  }, []);

  const setDriveValues = useCallback((values) => {
    driveValuesRef.current = values;
  }, []);

  const Provider = carsFilterContext.Provider;

  return (
    <Provider
      value={{
        filter,
        setFilter,
        hasFilter,
        resetFilter: handleReset,
        filterCarsLength,
        setFilterCarsLength,
        allFilterResults,
        setAllFilterResults,
        setManufacturers,
        setModels,
        setDriveValues
      }}
    >
      {children}
    </Provider>
  );
};
