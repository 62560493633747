// core
import React from 'react';

// libraries
import classnames from 'classnames';

// material
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

export interface Props {
  /**
   * Whether to use full height of page and push footer out of screen
   */
  fullHeight?: boolean;
}

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    paddingTop: spacing(4),
    paddingBottom: spacing(4),
    textAlign: 'center'
  },
  fullHeight: {
    minHeight: '90vh'
  }
}));

export const Loading = ({ fullHeight }: Props) => {
  const classes = useStyles();

  return (
    <div className={classnames(classes.root, { [classes.fullHeight]: fullHeight })}>
      <CircularProgress size={32} />
    </div>
  );
};
