// core
import React, { memo, ReactNode } from 'react';

// material
import Box from '@material-ui/core/Box';

// formik
import { FastField as Field } from 'formik';
import { Checkbox } from 'formik-material-ui';

export interface Props {
  name: string;
  label: ReactNode;
  checked: boolean;
  disabled?: boolean;
}

export const EnableField = memo(function EnableField({ name, label, checked, disabled }: Props) {
  return (
    <Box display="flex" alignItems="center">
      <Field
        name={name}
        component={Checkbox}
        type="checkbox"
        checked={checked}
        disabled={disabled}
      />

      {label}
    </Box>
  );
});
