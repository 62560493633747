// core
import React, { useCallback } from 'react';

// apollo
import { CarDetailsFormFragment } from '../graphql/CarDetailsFormFragment';

// formik
import { useField } from 'formik';

// material
import UILink from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';

// material icons
import RemoveIcon from '@material-ui/icons/DeleteOutlined';

export interface Props {
  car: CarDetailsFormFragment | null;
}

export const CarDetailsFormAuthenticityReport = ({ car }: Props) => {
  const [field, meta, { setValue }] = useField('authenticityReport');

  const handleInputChange = useCallback(
    ({ target: { files } }) => {
      setValue(files[0]);
    },
    [setValue]
  );

  const handleDelete = useCallback(() => {
    setValue(null);
  }, [setValue]);

  return (
    <>
      <TextField
        type="file"
        margin="dense"
        {...field}
        value={undefined}
        onChange={handleInputChange}
        error={!!meta.error}
        helperText={meta.error}
      />

      {car?.authenticityReport && field.value && (
        <>
          <UILink href={`${global.cdnUrl}/subor/${car?.authenticityReport}`}>
            {car.authenticityReport}
          </UILink>

          <IconButton onClick={handleDelete}>
            <RemoveIcon />
          </IconButton>
        </>
      )}
    </>
  );
};
