// core
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import fetch from 'node-fetch';

// bugsnag
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

// firebase
import firebase from 'firebase/app';

// recaptcha
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

// apollo
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  ApolloLink,
  Observable,
  NormalizedCacheObject
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { createUploadLink, UploadLinkOptions } from 'apollo-upload-client';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { RetryLink } from '@apollo/client/link/retry';
import { sha256 } from 'crypto-hash';

import { App } from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import { MuiThemeProvider } from '@material-ui/core/styles';

import theme from './theme';

declare global {
  // eslint-disable-next-line no-var
  var baseUrl: string;
  // eslint-disable-next-line no-var
  var cdnUrl: string;
  // eslint-disable-next-line no-var
  var bugsnagClient: {
    getPlugin: (name: string) => any;
    notify: (severity: string | Error, options?: any) => void;
  };
  // eslint-disable-next-line no-var
  var __APOLLO_STATE__: NormalizedCacheObject;
}

global.baseUrl = 'https://www.edenbazar.sk';
global.cdnUrl = 'https://cdn.edenbazar.sk';

let b;
if (process.env.REACT_APP_BUGSNAG_API_KEY) {
  b = Bugsnag.start(
    Object.assign(
      {
        apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
        appType: 'client',
        appVersion: undefined,
        plugins: [new BugsnagPluginReact()],
        generateAnonymousId: false
      },
      process.env.REACT_APP_BUILD_NUMBER ? { appVersion: process.env.REACT_APP_BUILD_NUMBER } : {}
    )
  );
}
const ErrorBoundary = (b && b.getPlugin('react')?.createErrorBoundary(React)) || React.Fragment;
global.bugsnagClient = b || { notify: () => {}, getPlugin: () => {} };

class Main extends React.Component {
  componentDidMount() {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');

    if (jssStyles && jssStyles.parentElement) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }

  render() {
    return <App />;
  }
}

const isObject = (node: any) => typeof node === 'object' && node !== null;

const hasFiles = (node: { [key: string]: any }, found: any[] = []) => {
  Object.keys(node).forEach((key) => {
    if (!isObject(node[key]) || found.length > 0) {
      return;
    }

    if (
      (typeof File !== 'undefined' && node[key] instanceof File) ||
      (typeof Blob !== 'undefined' && node[key] instanceof Blob)
    ) {
      found.push(node[key]);
      return;
    }

    hasFiles(node[key], found);
  });

  return found.length > 0;
};

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      let handle: any;

      Promise.resolve(operation)
        .then((operation) =>
          new Promise((resolve) => {
            firebase.auth ? firebase.auth().onAuthStateChanged(resolve) : resolve(null);
          })
            .then((user: any) => user && user.getIdToken())
            .then((token) => {
              operation.setContext({
                headers: {
                  authorization: token ? `Bearer ${token}` : ''
                }
              });
            })
        )
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer)
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    })
);

const options = {
  uri: process.env.REACT_APP_API_URL,
  credentials: 'same-origin'
};

const persistedQueryLink = createPersistedQueryLink({ sha256 });
const uploadLink = createUploadLink(options as UploadLinkOptions);
const batchLink = new BatchHttpLink({ ...options, fetch } as any);
const retryLink = new RetryLink();

const div = document.getElementById('root');

if (div) {
  // firebase
  const config = {
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN
    // databaseURL: 'https://edenbazar-2998d.firebaseio.com',
    // storageBucket: 'edenbazar-2998d.appspot.com'
    // messagingSenderId: '545564231941'
  };
  firebase.initializeApp(config);

  // Apollo
  const apolloClient = new ApolloClient({
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
          graphQLErrors.forEach((error) => {
            const { message, locations, path } = error;
            console.error(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            );
            Bugsnag.notify(error.originalError || new Error(message), (event) =>
              event.addMetadata('error', error)
            );
          });
        if (networkError) {
          console.error(`[Network error]: ${networkError}`);
        }
      }),
      requestLink,
      ApolloLink.split(
        ({ variables }) => hasFiles(variables),
        uploadLink,
        persistedQueryLink.concat(retryLink).concat(batchLink)
      )
    ]),
    cache: new InMemoryCache({
      typePolicies: {
        Category: {
          keyFields: ['slug']
        }
      }
    }).restore(window.__APOLLO_STATE__),
    name: 'webpage',
    version: process.env.REACT_APP_BUILD_NUMBER
  });

  ReactDOM.hydrate(
    <ErrorBoundary>
      <ApolloProvider client={apolloClient}>
        <MuiThemeProvider theme={theme}>
          <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY}>
            <Router>
              <Main />
            </Router>
          </GoogleReCaptchaProvider>
        </MuiThemeProvider>
      </ApolloProvider>
    </ErrorBoundary>,
    div
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
if (window.Cypress) {
  serviceWorkerRegistration.unregister();
} else {
  serviceWorkerRegistration.register();
}
