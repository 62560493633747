// core
import React, { memo, useMemo } from 'react';

// formik
import { FastField as Field } from 'formik';
import { TextField } from 'formik-material-ui';

// components
import { LoadSelectOptions } from 'components/LoadSelectOptions';

export interface Props {
  hasValue: boolean;
}

export const EmissionClassField = memo(function EmissionClassField({ hasValue }: Props) {
  const inputProps = useMemo(
    () => ({
      shrink: hasValue
    }),
    [hasValue]
  );

  return (
    <LoadSelectOptions id="emissionClass">
      <Field
        name="emissionClass"
        select
        label="Emisná trieda"
        margin="dense"
        component={TextField}
        InputLabelProps={inputProps}
        fullWidth
      />
    </LoadSelectOptions>
  );
});
