// core
import React, { memo, useCallback, useMemo } from 'react';

// formik
import { FastField as Field } from 'formik';
import { TextField } from 'formik-material-ui';

// components
import { LoadSelectOptions } from 'components/LoadSelectOptions';

export interface Props {
  hasValue: boolean;
}

export const MonthField = memo(function MonthField({ hasValue }: Props) {
  const sort = useCallback((a, b) => parseInt(a.label) - parseInt(b.label), []);

  const inputProps = useMemo(
    () => ({
      shrink: hasValue
    }),
    [hasValue]
  );

  return (
    <LoadSelectOptions id="month" sort={sort}>
      <Field
        name="month"
        type="text"
        select
        label="Mesiac"
        margin="dense"
        component={TextField}
        InputLabelProps={inputProps}
        fullWidth
      />
    </LoadSelectOptions>
  );
});
