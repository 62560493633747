// core
import React from 'react';

// material
import Typography from '@material-ui/core/Typography';

export const EdenbazarIcon = () => {
  return (
    <Typography variant="h6" title="EDENbazar.sk" color="primary">
      E
    </Typography>
  );
};
