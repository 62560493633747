// core
import React, { ElementType } from 'react';

// libraries
import classnames from 'classnames';

// material
import { makeStyles } from '@material-ui/core/styles';
import Typography, { TypographyProps } from '@material-ui/core/Typography';

export interface Props extends TypographyProps {}

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 0,
    position: 'relative',
    paddingLeft: 12,
    fontWeight: 'bold',
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 6,
      bottom: 8,
      left: 0,
      width: 3,
      backgroundColor: theme.palette.primary.main
    }
  }
}));

export const Subheading = ({ children, className, ...passingProps }: Props) => {
  const classes = useStyles();

  return (
    <Typography
      variant="subtitle1"
      component={'h2' as ElementType}
      className={classnames(classes.root, className)}
      {...passingProps}
    >
      {children}
    </Typography>
  );
};
