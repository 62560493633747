// core
import React, { useCallback, useState, FocusEvent } from 'react';

// libraries
import classnames from 'classnames';
import { Link, useParams } from 'react-router-dom';

// graphql
import { useMutation } from '@apollo/client';
import editCarMutation from '../../Edit/graphql/EditCarMutation.graphql';
import { EditCarMutation, EditCarMutationVariables } from '../../Edit/graphql/EditCarMutation';
import { CarCardFragment } from '../graphql/CarCardFragment';

// material-ui
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Box from '@material-ui/core/Box';

// icons
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

// components
import { Price } from 'components/Price';
import { useSnackbar } from 'components/Snackbar';
import { CarHighlights } from 'components/CarHighlights';

import { UserContext } from 'AppRouter';

// partials
import { CarBadge } from '../../Car/components/CarBadge';
import { DiscountBadge } from '../../Car/components/DiscountBadge';
import { CarYear } from '../../Car/components/CarYear';
import { CarViews } from '../../Car/components/CarViews';
import { VerifiedBadge } from './VerifiedBadge';

// hooks
import { useCarsFilter } from '../hooks/useCarsFilter';

export interface Props {
  car: CarCardFragment;
  fancy?: boolean;
  price?: number;
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 16
  },
  fancy: {
    backgroundColor: theme.palette.secondary.main,
    '& h2': {
      color: '#fafafa'
    },
    '& $year': {
      color: 'rgba(255, 255, 255, 0.54)'
    },
    '& $priceWrap': {
      color: '#fafafa'
    },
    '& $priceWrap > span:first-child p': {
      color: 'rgba(255, 255, 255, 0.54)'
    },
    '& $priceWrap > div:last-child': {
      color: 'rgba(255, 255, 255, 0.54)'
    }
  },
  content: {
    padding: 32,
    paddingTop: 24,
    '&:last-child': {
      paddingBottom: 32,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 24,
        paddingRight: 24
      },
      [theme.breakpoints.down('xs')]: {
        paddingTop: 24,
        paddingBottom: 24,
        paddingLeft: 16,
        paddingRight: 16
      }
    }
  },
  heading: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: 16,
    '& > a': {
      color: 'inherit',
      flex: 1,
      marginRight: 24,
      textDecoration: 'none',
      '& h2': {
        lineHeight: 1.4
      },
      '& > h2 > span:first-child': {
        marginRight: 12
      }
    }
  },
  year: {
    display: 'inline-block',
    fontSize: '0.8em'
  },
  imageWrap: {
    position: 'relative',
    '& > a': {
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center'
    }
  },
  image: {
    width: 'auto',
    height: 160,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'auto'
    }
  },
  priceWrap: {
    textAlign: 'right',
    marginTop: -3,
    marginBottom: -19,
    alignItems: 'baseline',
    maxWidth: '45%',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 2,
      '& > div:first-child': {
        fontSize: '1.5em'
      }
    },
    '& > div:first-child, & > span:first-child > div': {
      maxWidth: 150,
      fontWeight: 'bold',
      whiteSpace: 'nowrap'
    }
  },
  hasDiscount: {
    marginTop: 5
  },
  editablePrice: {
    cursor: 'pointer'
  },
  priceField: {
    color: 'inherit',
    fontSize: '1.5em',
    marginBottom: 4,
    '& input': {
      textAlign: 'right'
    }
  },
  details: {
    position: 'relative',
    marginTop: 16,
    paddingLeft: 40,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 32
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 24,
      paddingLeft: 0
    },
    '& > div >div:first-child': {
      paddingRight: 8
    }
  },
  highlightItem: {
    fontSize: '1em',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.9em'
    }
  },
  button: {
    position: 'absolute',
    right: 0,
    bottom: 1,
    '& svg': {
      marginLeft: 8
    },
    [theme.breakpoints.down('md')]: {
      '& svg': {
        marginLeft: 0
      }
    },
    [theme.breakpoints.down('sm')]: {
      position: 'static',
      width: '100%',
      marginTop: 16,
      '& svg': {
        marginLeft: 8
      }
    }
  }
}));

export const CarCard = ({ car, fancy, price }: Props) => {
  const classes = useStyles();

  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const params = useParams<{ category?: string }>();

  const { pushSnack } = useSnackbar();
  const { filter } = useCarsFilter();

  const [editPrice, setEditPrice] = useState(false);
  const [currentPrice, setCurrentPrice] = useState(price);

  const [editCar, { loading, error }] = useMutation<EditCarMutation, EditCarMutationVariables>(
    editCarMutation
  );

  if (error) {
    console.error(error);
  }

  const handlePriceClick = useCallback(() => {
    setEditPrice(true);
  }, []);

  const handlePriceChange = useCallback((e) => {
    setCurrentPrice(e.target.value);
  }, []);

  const handlePriceBlur = (e: FocusEvent) => {
    const newPrice = parseFloat((e.target as HTMLInputElement).value) || 0;

    if (newPrice && car.price !== newPrice) {
      editCar({
        variables: {
          id: car.id,
          data: { price: newPrice }
        }
      })
        .then(() => {
          pushSnack({
            message: 'Cena bola úspešne aktualizovaná',
            type: 'success'
          });
          setEditPrice(false);
        })
        .catch((err) => {
          console.log(err);
          pushSnack({
            message: 'Cenu sa nepodarilo aktualizovať',
            type: 'error'
          });
        });
    } else {
      setEditPrice(false);
    }
  };

  const category = params.category || null;

  const to = {
    pathname: `/vozidla-na-predaj/${car.mainCategory.slug}/${car.slug}`,
    state: {
      from: {
        category,
        filter
      }
    }
  };

  return (
    <UserContext.Consumer>
      {(user) => (
        <Card
          className={fancy ? classnames(classes.root, classes.fancy) : classes.root}
          raised={fancy}
        >
          <CardContent className={classes.content}>
            <div className={classes.heading}>
              <Link to={to}>
                <Typography variant="h6" component="h2">
                  <span>{car.fullTitle}</span>

                  <CarYear year={car.year} month={car.month} classes={{ root: classes.year }} />
                </Typography>
              </Link>

              <div
                className={classnames(classes.priceWrap, {
                  [classes.editablePrice]: user,
                  [classes.hasDiscount]: car.discount > 0
                })}
              >
                {editPrice && user ? (
                  <Input
                    value={currentPrice}
                    onChange={handlePriceChange}
                    onBlur={handlePriceBlur}
                    margin="none"
                    autoFocus={true}
                    disabled={loading}
                    endAdornment={
                      <InputAdornment position="end" disableTypography={true}>
                        €
                      </InputAdornment>
                    }
                    classes={{
                      root: classes.priceField
                    }}
                    fullWidth
                  />
                ) : (
                  <DiscountBadge discount={car.discount} price={car.price}>
                    <Typography
                      variant="h4"
                      component="div"
                      color="inherit"
                      onClick={user ? handlePriceClick : undefined}
                    >
                      <Price currency="€" decimals={0}>
                        {car.price - car.discount}
                      </Price>
                    </Typography>
                  </DiscountBadge>
                )}

                {car.vatDeduction ? (
                  <Typography variant="caption" component="div" color="textSecondary">
                    možný odpočet DPH
                  </Typography>
                ) : null}
              </div>
            </div>

            <Grid container spacing={0}>
              <Grid item xs={12} md={4} className={classes.imageWrap}>
                <CardActionArea component={Link} to={to}>
                  <CardMedia
                    component="img"
                    src={`${global.cdnUrl}/fotografia/${car.thumbnail}`}
                    alt={car.fullTitle}
                    title={car.fullTitle}
                    className={classes.image}
                    width="600"
                    height="450"
                  />
                </CardActionArea>

                {user && (
                  <Box mt={1} mb={-3} color={fancy ? 'white' : undefined}>
                    <CarViews views={car.views || 0} />
                  </Box>
                )}

                <CarBadge car={car} small />

                {car.authenticityReport ? <VerifiedBadge /> : null}
              </Grid>

              <Grid item xs={12} md={8} className={classes.details}>
                <CarHighlights
                  car={car}
                  emptyRightBottomCorner={isMdUp}
                  variant={fancy ? 'white' : 'default'}
                  classes={{ item: classes.highlightItem }}
                />

                <Button
                  variant="contained"
                  component={Link}
                  to={to}
                  color="primary"
                  className={classes.button}
                >
                  Detail
                  <Hidden only="md"> vozidla</Hidden>
                  <ArrowForwardIosIcon fontSize="inherit" />
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
    </UserContext.Consumer>
  );
};
