// core
import React, { ReactNode } from 'react';

// components
import { Subheading } from 'components/Subheading';

// libraries
import classnames from 'classnames';

// material
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

export interface Props {
  label: ReactNode;
  details: ReactNode;
  classes?: {
    details?: string;
  };
}

const useStyles = makeStyles((theme) => ({
  details: {
    marginBottom: 8
  }
}));

export const CarDetailsContainer = ({ label, details, classes = {} }: Props) => {
  const css = useStyles();

  return details ? (
    <>
      <Subheading>{label}</Subheading>

      <Typography
        variant="body2"
        color="secondary"
        className={classnames(css.details, classes.details)}
      >
        {details}
      </Typography>
    </>
  ) : null;
};
