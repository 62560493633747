// core
import React, { useCallback } from 'react';

// material-ui
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';

// icons
import ClearIcon from '@material-ui/icons/Clear';

// components
import { GqlResponseHandler } from 'components/GqlResponseHandler';

// partials
import { SidebarHeading } from './SidebarHeading';
import { Sort } from './Sort';
import { CategoryCard } from './CategoryCard';
import { CarsFilter } from './CarsFilter';

// hooks
import { useCategories } from '../hooks/useCategories';
import { useSort } from '../hooks/useSort';
import { usePagination } from '../hooks/usePagination';
import { useCarsFilter } from '../hooks/useCarsFilter';

export interface Props {
  category: string | null;
  onClose?: () => void;
}

const useStyles = makeStyles((theme) => ({
  sidebarWrap: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(4),
    paddingRight: theme.spacing(8),
    marginLeft: -theme.spacing(2), // fix ovewflow x
    paddingLeft: theme.spacing(2), // fix ovewflow x
    position: 'sticky',
    top: 66,
    height: 'calc(100vh - 66px)',
    overflowX: 'visible',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    background: `
      linear-gradient(white 30%, rgba(255,255,255,0)),
      linear-gradient(rgba(255,255,255,0), white 70%) 0 100%,
      radial-gradient(farthest-side at 50% 0, rgba(0,0,0,.2), rgba(0,0,0,0)),
      radial-gradient(farthest-side at 50% 100%, rgba(0,0,0,.2), rgba(0,0,0,0)) 0 100%
    `,
    backgroundRepeat: 'no-repeat',
    backgroundColor: 'white',
    backgroundSize: '100% 40px, 100% 40px, 100% 14px, 100% 14px',
    backgroundAttachment: 'local, local, scroll, scroll',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      minWidth: 260,
      marginTop: 0,
      marginLeft: 0,
      marginRight: 0,
      paddingRight: theme.spacing(2),
      position: 'static',
      maxWidth: '100%'
    }
  },
  categories: {
    marginBottom: theme.spacing(2),
    '& > div': {
      [theme.breakpoints.down('xs')]: {
        padding: '4px !important'
      }
    }
  },
  filterClearButton: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: 4,
      marginBottom: 1
    }
  },
  filterSubmitButton: {
    marginTop: 32,
    width: '100%'
  }
}));

export const CarsSidebar = ({ category, onClose }: Props) => {
  const css = useStyles();

  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const { categories, loading, error } = useCategories();
  const { setSort } = useSort();
  const { setOffset } = usePagination();
  const { hasFilter, filter, setFilter, setFilterCarsLength, setAllFilterResults } =
    useCarsFilter();

  const handleFilterChange = useCallback(
    (filter: any, manufacturerSelect?: any, modelSelect?: any, res?: any) => {
      setOffset(0);
      setFilter(filter);
      setFilterCarsLength(null);
      setAllFilterResults(false);

      window.scrollTo(0, 0);
    },
    [setFilter, setOffset, setFilterCarsLength, setAllFilterResults]
  );

  const handleFilterReset = useCallback(() => {
    handleFilterChange({});
    setSort('titleOrder_ASC');
    setOffset(0);
  }, [handleFilterChange, setSort, setOffset]);

  return (
    <aside className={css.sidebarWrap}>
      <Grid container spacing={2} className={css.categories}>
        <GqlResponseHandler loading={loading} error={error}>
          <>
            {categories.map((cat) =>
              cat.slug === 'v-zaruke' ? null : (
                <Grid item xs={4} key={cat.slug}>
                  <CategoryCard
                    category={cat}
                    selected={category}
                    fancy={cat.slug === 'zlava-tyzdna'}
                  />
                </Grid>
              )
            )}
          </>
        </GqlResponseHandler>
      </Grid>

      <Hidden mdUp>
        <SidebarHeading>Zoradiť podľa</SidebarHeading>

        <Sort />
      </Hidden>

      <SidebarHeading
        buttons={
          hasFilter
            ? [
                {
                  label: (
                    <div className={css.filterClearButton}>
                      <ClearIcon fontSize="small" />
                      Zrušiť filter
                    </div>
                  ),
                  onClick: handleFilterReset
                }
              ]
            : []
        }
      >
        Filter
      </SidebarHeading>

      <CarsFilter values={filter} onChange={handleFilterChange} />

      {isMdUp ? null : hasFilter ? (
        <Button
          variant="contained"
          color="primary"
          className={css.filterSubmitButton}
          onClick={onClose}
        >
          Zobraziť vyfiltrovaný zoznam
        </Button>
      ) : (
        <Button
          variant="contained"
          color="default"
          className={css.filterSubmitButton}
          onClick={onClose}
        >
          Zatvoriť
        </Button>
      )}
    </aside>
  );
};
