// core
import React, { useEffect, useCallback, useState } from 'react';

// libraries
import { Redirect, useParams } from 'react-router-dom';

// context
import { UserContext } from 'AppRouter';

// material
import { makeStyles } from '@material-ui/core/styles';
import NoSsr from '@material-ui/core/NoSsr';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';

// components
import { Loading } from 'components/Loading';
import { ContentWrap } from 'components/ContentWrap';
import { GqlResponseHandler } from 'components/GqlResponseHandler';

// partials
import { CarsSidebar } from './components/CarsSidebar';
import { CarsContent } from './components/CarsContent';
import { Advantages } from '../Home/components/Advantages';

// contexts
import { CarsFilterContextProvider } from './contexts/CarsFilterContext';
import { SortContextProvider } from './contexts/SortContext';
import { PaginationContextProvider } from './contexts/PaginationContext';

// hooks
import { useCategories } from './hooks/useCategories';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > div': {
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
        paddingRight: 0
      }
    }
  },
  filterDrawer: {
    overflowX: 'hidden',
    maxWidth: '90%'
  },
  listWrap: {
    backgroundColor: theme.backgroundColor
  },
  advantages: {
    marginTop: 96,
    marginBottom: 96,
    [theme.breakpoints.down('sm')]: {
      marginTop: 64,
      marginBottom: 64
    }
  }
}));

export const Cars = () => {
  const classes = useStyles();

  const params = useParams<{ category?: string }>();

  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);

  const { categories, loading, error } = useCategories();

  const handleDrawerToggle = useCallback(() => {
    setSidebarOpen((open) => !open);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const category = params.category || null;
  const categoryObject = categories.find((c) => c.slug === category);

  if (loading) {
    return <Loading fullHeight />;
  }

  if (category && !categoryObject) {
    return <Redirect to="/404" />;
  }

  return (
    <UserContext.Consumer>
      {(user) => (
        <SortContextProvider>
          <PaginationContextProvider>
            <CarsFilterContextProvider>
              <ContentWrap wider className={classes.root}>
                <GqlResponseHandler error={error}>
                  <Grid container spacing={0}>
                    <NoSsr>
                      <Grid item xs={12} md={4}>
                        <Hidden smDown>
                          <CarsSidebar category={category} />
                        </Hidden>

                        <Hidden mdUp>
                          <Drawer
                            variant="temporary"
                            anchor="left"
                            open={sidebarOpen}
                            onClose={handleDrawerToggle}
                            classes={{ paper: classes.filterDrawer }}
                          >
                            <CarsSidebar category={category} onClose={handleDrawerToggle} />
                          </Drawer>
                        </Hidden>
                      </Grid>
                    </NoSsr>

                    <Grid item xs={12} md={8} className={classes.listWrap}>
                      <CarsContent
                        category={categoryObject || null}
                        onDrawerTool={handleDrawerToggle}
                      />
                    </Grid>
                  </Grid>
                </GqlResponseHandler>
              </ContentWrap>

              <ContentWrap>
                <Advantages classes={{ root: classes.advantages }} />
              </ContentWrap>
            </CarsFilterContextProvider>
          </PaginationContextProvider>
        </SortContextProvider>
      )}
    </UserContext.Consumer>
  );
};
