// core
import React, { memo, useMemo } from 'react';

// formik
import { FastField as Field } from 'formik';
import { TextField } from 'formik-material-ui';

// components
import { LoadSelectOptions } from 'components/LoadSelectOptions';

export interface Props {
  hasValue: boolean;
  isMotorbike: boolean;
}

export const FuelField = memo(function FuelField({ hasValue, isMotorbike }: Props) {
  const inputProps = useMemo(
    () => ({
      shrink: hasValue
    }),
    [hasValue]
  );

  return (
    <LoadSelectOptions id="fuel" filter={{ variant: isMotorbike ? 'motorbike' : 'car' }}>
      <Field
        name="fuel"
        select
        label="Palivo"
        margin="dense"
        component={TextField}
        InputLabelProps={inputProps}
        fullWidth
      />
    </LoadSelectOptions>
  );
});
