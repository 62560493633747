// core
import React, { useCallback, ChangeEvent } from 'react';

// material
import MuiPagination, { PaginationProps } from '@material-ui/lab/Pagination';

export interface Props extends Omit<PaginationProps, 'onChange'> {
  limit: number;
  offset: number;
  total: number;
  onChange: (e: ChangeEvent, newOffset: number) => void;
  className?: string;
}

export const Pagination = ({
  limit = 10,
  offset = 0,
  total = 0,
  onChange,
  className,
  ...passingProps
}: Props) => {
  const count = Math.ceil(total / limit);
  const page = Math.floor(offset / limit);

  const handleChange = useCallback(
    (event: ChangeEvent<any>, page: number) => {
      onChange(event, (page - 1) * limit);
    },
    [limit, onChange]
  );

  return (
    <MuiPagination
      color="primary"
      shape="rounded"
      count={count}
      page={page + 1}
      siblingCount={1}
      onChange={handleChange}
      className={className}
      {...passingProps}
    />
  );
};
