// core
import React from 'react';

// libraries
import { Link } from 'react-router-dom';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';

// icons
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

// components
import { ContentWrap } from 'components/ContentWrap';
import { Breadcrumbs, renderBreadcrumbs, BreadcrumbsItem } from 'components/Breadcrumbs';

export interface Props {
  breadcrumbs: BreadcrumbsItem[];
  category?: string;
  hasFilter: boolean;
}

const useStyles = makeStyles((theme) => ({
  breadcrumbsWrap: {
    '& > div': {
      height: 36,
      display: 'flex',
      overflow: 'hidden'
    }
  },
  breadcrumbsButtonsWrap: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    '& a': {
      marginRight: -8
    },
    '& a svg': {
      marginRight: 8
    }
  }
}));

export const CarBreadcrumbs = ({ breadcrumbs, category, hasFilter }: Props) => {
  const css = useStyles();

  return (
    <Breadcrumbs items={breadcrumbs}>
      {(items, c) => (
        <ContentWrap className={css.breadcrumbsWrap}>
          {renderBreadcrumbs(items, c)}

          <Hidden xsDown>
            <div className={css.breadcrumbsButtonsWrap}>
              <Button
                variant="text"
                component={Link}
                to={
                  category && category !== 'all'
                    ? `/vozidla-na-predaj/${category}`
                    : '/vozidla-na-predaj'
                }
                color="default"
              >
                <ArrowBackIcon fontSize="inherit" />
                {hasFilter
                  ? 'Späť na vyfiltrované vozidlá'
                  : category
                  ? category === 'lacne'
                    ? 'Späť na lacné vozidlá'
                    : category === 'v-zaruke'
                    ? 'Späť na vozidlá v záruke'
                    : category === 'mestske'
                    ? 'Späť na mestské vozidlá'
                    : category === 'rodinne'
                    ? 'Späť na rodinné vozidlá'
                    : category === 'manazerske'
                    ? 'Späť na manažérske vozidlá'
                    : category === 'uzitkove'
                    ? 'Späť na úžitkové vozidlá'
                    : 'Späť na zoznam vozidiel'
                  : 'Vozidlá na predaj'}
              </Button>
            </div>
          </Hidden>
        </ContentWrap>
      )}
    </Breadcrumbs>
  );
};
