// core
import React from 'react';

// libraries
import classnames from 'classnames';

// material
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// material icons
import DateRangeIcon from '@material-ui/icons/DateRange';

export interface Props {
  year: number;
  month: number | null;
  classes?: {
    root?: string;
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    whiteSpace: 'nowrap',
    fontSize: 'inherit',
    '& > svg': {
      marginRight: 4,
      marginTop: -4,
      verticalAlign: 'middle'
    }
  }
}));

export const getYearString = (year: number, month: number | null) => {
  return `${year}${month ? `/${month}` : ''}`;
};

export const CarYear = ({ year, month, classes = {} }: Props) => {
  const css = useStyles();

  return (
    <Typography
      variant="subtitle1"
      component="span"
      color="textSecondary"
      className={classnames(css.root, classes.root)}
    >
      <DateRangeIcon fontSize="inherit" />
      {getYearString(year, month)}
    </Typography>
  );
};
