// core
import React, { useEffect, useState } from 'react';

// libraries
import { Helmet } from 'react-helmet';

// material
import { makeStyles } from '@material-ui/core/styles';

// components
import { Breadcrumbs, renderBreadcrumbs } from 'components/Breadcrumbs';
import { ContentWrap } from 'components/ContentWrap';
import { PageHeading } from 'components/PageHeading';
import { MarkdownParser } from 'components/MarkdownParser';
import { Loading } from 'components/Loading';

export interface Props {
  page: 'Cookies';
}

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 64,
    paddingBottom: 64,
    '& > h2': {}
  }
}));

const config = {
  cookies: {
    breadcrumbs: [
      {
        label: 'Súbory cookie'
      }
    ],
    heading: 'Súbory cookie'
  }
};

export const StaticPages = ({ page }: Props) => {
  const css = useStyles();

  const [source, setSource] = useState<null | string>(null);

  useEffect(
    () => {
      window.scrollTo(0, 0);

      const path = require(`./pages/${page}.md`).default;

      fetch(path)
        .then((response) => response.text())
        .then((text) => {
          setSource(text);
        })
        .catch((e) => console.log(e));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const key = page.toLowerCase() as 'cookies';
  const breadcrumbs = config[key] && config[key].breadcrumbs ? config[key].breadcrumbs : [];
  const heading = config[key] && config[key].heading ? config[key].heading : null;

  return (
    <>
      <Helmet>
        <title>{heading ? `${heading} - EDENbazar` : 'EDENbazar'}</title>
      </Helmet>

      <Breadcrumbs items={breadcrumbs}>
        {(items, c) => <ContentWrap>{renderBreadcrumbs(items, c)}</ContentWrap>}
      </Breadcrumbs>

      <ContentWrap className={css.root}>
        <PageHeading>{heading}</PageHeading>

        {source ? <MarkdownParser source={source} /> : <Loading />}
      </ContentWrap>
    </>
  );
};
