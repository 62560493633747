// core
import React, { ReactNode } from 'react';

// material
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

export interface Props {
  items: {
    icon: ReactNode;
    title: string;
    subtitle?: string;
  }[];
}

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: 32,
    '& > li > div:first-child': {
      color: theme.palette.primary.main,
      marginRight: theme.spacing(3),
      minWidth: 'auto'
    },
    '& li:last-child $iconInCircle:before': {
      display: 'none'
    }
  },
  iconInCircle: {
    padding: 12,
    borderStyle: 'solid',
    borderWidth: 2,
    borderColor: theme.borderColor,
    borderRadius: '50%',
    position: 'relative',
    backgroundColor: '#fff',
    '&:before': {
      content: '""',
      width: 2,
      height: 65,
      position: 'absolute',
      top: '100%',
      left: 23,
      backgroundColor: theme.borderColor,
      zIndex: -1
    },
    '&:after': {
      content: '""',
      width: 60,
      height: 60,
      borderStyle: 'solid',
      borderWidth: 8,
      borderColor: '#fff',
      borderRadius: '50%',
      position: 'absolute',
      top: -6,
      left: -6,
      zIndex: -1
    }
  }
}));

export const Timeline = ({ items }: Props) => {
  const classes = useStyles();

  return (
    <List component="ul" className={classes.root}>
      {items.map(({ icon, title, subtitle }, key) => (
        <ListItem key={key}>
          <ListItemIcon className={classes.iconInCircle}>{icon}</ListItemIcon>
          <ListItemText primary={title} secondary={subtitle} />
        </ListItem>
      ))}
    </List>
  );
};
