// core
import React, { ReactNode, useMemo } from 'react';

// apollo
import { useQuery } from '@apollo/client';
import loadSelectOptionsQuery from '../../../components/LoadSelectOptions/graphql/LoadSelectOptionsQuery.graphql';
import { LoadSelectOptionsQuery } from 'components/LoadSelectOptions/graphql/LoadSelectOptionsQuery';
import { SelectOptionFragment } from 'components/LoadSelectOptions/graphql/SelectOptionFragment';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// components
import { LoadingDiv } from 'components/LoadingDiv';

// partials
import { AccessoriesField } from './AccessoriesField';

export interface Props {
  name: string;
  appendFields?: (options: SelectOptionFragment) => ReactNode;
  isMotorbike: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 16
  }
}));

// must rerender all time because of highlights
export const AccessoriesFields = ({ name, appendFields, isMotorbike }: Props) => {
  const classes = useStyles();

  const { data, error, loading } = useQuery<LoadSelectOptionsQuery>(loadSelectOptionsQuery, {
    variables: { id: name, filter: JSON.stringify({ variant: isMotorbike ? 'motorbike' : 'car' }) }
  });

  const cols = useMemo(() => {
    const handleAppendFields = (field: SelectOptionFragment) => () =>
      typeof appendFields === 'function' ? appendFields(field) : null;

    const items =
      data?.options.map((field) => (
        <AccessoriesField
          key={field.value}
          name={name}
          value={field.value}
          label={field.label}
          appendFields={handleAppendFields(field)}
        />
      )) || [];

    const length = items.length;
    const itemsInCol = Math.ceil(length / 4);

    const cols = [];
    for (let c = 0; c < length; c += itemsInCol) {
      cols.push(
        <Grid item xs={12} sm={6} md={3} key={c}>
          {items.splice(0, itemsInCol)}
        </Grid>
      );
    }

    return cols;
  }, [name, data, appendFields]);

  if (loading) {
    return <LoadingDiv />;
  }

  if (error) {
    console.error(error);
    global.bugsnagClient.notify(error);

    return (
      <MenuItem>
        <Typography variant="caption" color="error">
          Chyba v spojení so serverom. Skúste to prosím znova.
        </Typography>
      </MenuItem>
    );
  }

  return (
    <Grid container spacing={0} className={classes.root}>
      {cols}
    </Grid>
  );
};
