// core
import React, { useEffect } from 'react';

// apollo
import { CarCardFragment } from '../graphql/CarCardFragment';

// partials
import { CarList } from './CarList';

// hooks
import { useCarsFilter } from '../hooks/useCarsFilter';

export interface Props {
  cars: readonly CarCardFragment[];
  separateNew: boolean;
}

export const CarListAll = ({ cars, separateNew }: Props) => {
  const { setFilterCarsLength, setAllFilterResults } = useCarsFilter();

  useEffect(
    () => {
      setFilterCarsLength(cars.length);
      if (cars.length < 10) {
        setAllFilterResults(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cars.length]
  );

  if (separateNew) {
    const newCars = cars.filter((car) => !!car.new || !!car.discount);
    const otherCars = cars.filter((car) => !car.new && !car.discount);

    return (
      <>
        {newCars ? <CarList cars={newCars} /> : null}

        <CarList cars={otherCars} />
      </>
    );
  } else return <CarList cars={cars} />;
};
