// core
import React, { useState, useCallback } from 'react';

// libraries
import { Link } from 'react-router-dom';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

// icons
import SoonIcon from '@material-ui/icons/WatchLater';
import NotSoonIcon from '@material-ui/icons/WatchLaterOutlined';
import NewIcon from '@material-ui/icons/FiberNew';
import NotNewIcon from '@material-ui/icons/FiberNewOutlined';
import DiscountIcon from '@material-ui/icons/Whatshot';
import NotDiscountIcon from '@material-ui/icons/WhatshotOutlined';
import ReservedIcon from '@material-ui/icons/Lock';
import NotReservedIcon from '@material-ui/icons/LockOpen';
import SoldIcon from '@material-ui/icons/MonetizationOn';
import NotSoldIcon from '@material-ui/icons/AttachMoney';
import EditIcon from '@material-ui/icons/EditOutlined';
import RemoveIcon from '@material-ui/icons/DeleteOutlined';
import ConfirmRemoveIcon from '@material-ui/icons/DeleteForeverOutlined';
import PrintIcon from '@material-ui/icons/PrintOutlined';

// components

export type ActionButtonType = 'soon' | 'new' | 'discountOfWeek' | 'reserved' | 'sold' | 'remove';

export interface Props {
  id: string;
  soon: boolean;
  newOne: boolean;
  discountOfWeek: boolean;
  reserved: boolean;
  sold: boolean;
  hasDiscount: boolean;
  loadingOnButton: null | ActionButtonType;
  onPrint: () => void;
  onSoonChange: () => void;
  onNewChange: () => void;
  onDiscountOfWeekChange: () => void;
  onReservedChange: () => void;
  onSoldChange: () => void;
  onRemove: () => void;
}

const useStyles = makeStyles((theme) => ({
  soonIcon: {
    color: '#673ab7'
  },
  newIcon: {
    color: '#009688'
  },
  discountIcon: {
    color: '#ff5722'
  },
  reservedIcon: {
    color: '#263238'
  },
  soldIcon: {
    color: '#4caf50'
  }
}));

export const ActionButtons = ({
  id,
  soon,
  newOne,
  discountOfWeek,
  reserved,
  sold,
  hasDiscount,
  loadingOnButton,
  onPrint,
  onSoonChange,
  onNewChange,
  onDiscountOfWeekChange,
  onReservedChange,
  onSoldChange,
  onRemove
}: Props) => {
  const css = useStyles();

  const [confirmRemove, setConfirmRemove] = useState<boolean>(false);

  const handleRemoveButtonClick = useCallback(() => {
    setConfirmRemove(true);
    setTimeout(() => setConfirmRemove(false), 1000);
  }, []);

  return (
    <>
      <Tooltip title="Vytlačiť" placement="top">
        <Button variant="text" color="secondary" size="small" onClick={onPrint}>
          <PrintIcon />
        </Button>
      </Tooltip>

      <Tooltip
        title={soon ? "Zrušiť označenie ako 'Čoskoro'" : "Označiť ako 'Čoskoro'"}
        placement="top"
      >
        <Button variant="text" color="secondary" size="small" onClick={onSoonChange}>
          {loadingOnButton === 'soon' ? (
            <CircularProgress size={24} />
          ) : soon ? (
            <SoonIcon className={css.soonIcon} />
          ) : (
            <NotSoonIcon />
          )}
        </Button>
      </Tooltip>

      <Tooltip
        title={newOne ? "Zrušiť označenie ako 'Novinka'" : "Označiť ako 'Novinka'"}
        placement="top"
      >
        <Button variant="text" color="secondary" size="small" onClick={onNewChange}>
          {loadingOnButton === 'new' ? (
            <CircularProgress size={24} />
          ) : newOne ? (
            <NewIcon className={css.newIcon} />
          ) : (
            <NotNewIcon />
          )}
        </Button>
      </Tooltip>

      <Tooltip
        title={
          discountOfWeek ? "Zrušiť označenie ako 'Zľava týždňa'" : "Označiť ako 'Zľava týždňa'"
        }
        placement="top"
      >
        <div>
          <Button
            variant="text"
            color="secondary"
            size="small"
            onClick={onDiscountOfWeekChange}
            disabled={!hasDiscount}
          >
            {loadingOnButton === 'discountOfWeek' ? (
              <CircularProgress size={24} />
            ) : discountOfWeek ? (
              <DiscountIcon className={css.discountIcon} />
            ) : (
              <NotDiscountIcon />
            )}
          </Button>
        </div>
      </Tooltip>

      <Tooltip
        title={reserved ? "Zrušiť označenie ako 'Rezervované'" : "Označiť ako 'Rezervované'"}
        placement="top"
      >
        <Button variant="text" color="secondary" size="small" onClick={onReservedChange}>
          {loadingOnButton === 'reserved' ? (
            <CircularProgress size={24} />
          ) : reserved ? (
            <ReservedIcon className={css.reservedIcon} />
          ) : (
            <NotReservedIcon />
          )}
        </Button>
      </Tooltip>

      <Tooltip
        title={sold ? "Zrušiť označenie ako 'Predané'" : "Označiť ako 'Predané'"}
        placement="top"
      >
        <Button variant="text" color="secondary" size="small" onClick={onSoldChange}>
          {loadingOnButton === 'sold' ? (
            <CircularProgress size={24} />
          ) : sold ? (
            <SoldIcon className={css.soldIcon} />
          ) : (
            <NotSoldIcon />
          )}
        </Button>
      </Tooltip>

      <Tooltip title="Upraviť" placement="top">
        <Button
          variant="text"
          component={Link}
          to={`/upravit-vozidlo/${id}`}
          color="primary"
          size="small"
        >
          <EditIcon />
        </Button>
      </Tooltip>

      {confirmRemove ? (
        <Tooltip title="Potvrdiť odstránenie" placement="top">
          <Button variant="text" color="primary" size="small" onClick={onRemove}>
            <ConfirmRemoveIcon />
          </Button>
        </Tooltip>
      ) : (
        <Tooltip title="Odstrániť" placement="top">
          <Button variant="text" color="secondary" size="small" onClick={handleRemoveButtonClick}>
            {loadingOnButton === 'remove' ? <CircularProgress size={24} /> : <RemoveIcon />}
          </Button>
        </Tooltip>
      )}

      {/* <Mutation
            mutation={
              car.facebookPostId
                ? unpublishFromSocialNetworkMutation
                : publishToSocialNetworkMutation
            }
            variables={{ network: 'facebook', car: id }}
          >
            {(mutationOnSocialNetwork, { loading, error }) => (
              <Tooltip
                title={
                  car.facebookPostId
                    ? 'Vozidlo je publikované na Facebooku'
                    : 'Publikovať na Facebook'
                }
                placement="top"
              >
                <Button
                  variant="text"
                  color="secondary"
                  size="small"
                  onClick={mutationOnSocialNetwork}
                >
                  {loading ? (
                    <CircularProgress size={24} />
                  ) : car.facebookPostId ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      viewBox="0 0 192 192"
                      width="24px"
                      height="24px"
                    >
                      <g>
                        <path d="M0,192v-192h192v192z" fill="#3b5998" />
                        <g fill="#fff">
                          <path d="M40,24c-8.824,0 -16,7.176 -16,16v112c0,8.824 7.176,16 16,16h52.96875h22.34374h36.6875c8.824,0 16,-7.176 16,-16v-112c0,-8.824 -7.176,-16 -16,-16zM40,40h112l0.01562,112h-36.70313v-31.71875h18.92188l2.8125,-21.79687h-21.60937v-13.89062c-0.00001,-6.304 1.75687,-10.5625 10.79687,-10.5625h11.42187v-19.45312c-5.56,-0.568 -11.14238,-0.84412 -16.73438,-0.82812c-16.592,0 -27.95312,10.12675 -27.95312,28.71875v16.04687h-18.75v21.79687h18.75v31.6875h-52.96875z" />
                        </g>
                      </g>
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      viewBox="0 0 192 192"
                      width="24px"
                      height="24px"
                    >
                      <g>
                        <path d="M0,192v-192h192v192z" fill="none" />
                        <g fill="#53555F">
                          <path d="M40,24c-8.824,0 -16,7.176 -16,16v112c0,8.824 7.176,16 16,16h52.96875h22.34374h36.6875c8.824,0 16,-7.176 16,-16v-112c0,-8.824 -7.176,-16 -16,-16zM40,40h112l0.01562,112h-36.70313v-31.71875h18.92188l2.8125,-21.79687h-21.60937v-13.89062c-0.00001,-6.304 1.75687,-10.5625 10.79687,-10.5625h11.42187v-19.45312c-5.56,-0.568 -11.14238,-0.84412 -16.73438,-0.82812c-16.592,0 -27.95312,10.12675 -27.95312,28.71875v16.04687h-18.75v21.79687h18.75v31.6875h-52.96875z" />
                        </g>
                      </g>
                    </svg>
                  )}
                </Button>
              </Tooltip>
            )}
          </Mutation> */}
    </>
  );
};
