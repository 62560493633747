// core
import React, { useCallback, ChangeEvent } from 'react';

// libraries
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

// material
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

// icons
import SortIcon from '@material-ui/icons/Sort';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

// hooks
import { useSort } from '../hooks/useSort';
import { usePagination } from '../hooks/usePagination';

const useStyles = makeStyles((theme) => ({
  sortField: {
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  select: {
    width: 'calc(100% - 50px)',
    paddingLeft: 8,
    paddingTop: 8,
    paddingBottom: 8,
    paddingRight: 42,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 15,
      paddingBottom: 15
    }
  },
  selectIcon: {
    marginRight: 12,
    color: theme.palette.primary.main
  }
}));

export const Sort = () => {
  const css = useStyles();
  const sendDataToGTM = useGTMDispatch();

  const { sort, setSort } = useSort();
  const { setOffset } = usePagination();

  const handleSortChange = useCallback(
    (event: ChangeEvent<any>) => {
      const value = event.target.value;

      setSort(value);
      setOffset(0);

      window.scrollTo(0, 0);

      sendDataToGTM({
        event: 'event',
        eventCategory: 'Sort',
        eventAction: 'Changed',
        eventValue: undefined,
        eventLabel: value
      });
    },
    [setSort, setOffset, sendDataToGTM]
  );

  return (
    <TextField
      select
      name="sort"
      value={sort}
      onChange={handleSortChange}
      SelectProps={{
        IconComponent: KeyboardArrowDownIcon,
        native: true,
        classes: {
          select: css.select,
          icon: css.selectIcon
        },
        startAdornment: (
          <InputAdornment position="start">
            <SortIcon color="primary" />
          </InputAdornment>
        )
      }}
      variant="outlined"
      margin="dense"
      className={css.sortField}
    >
      <option value="titleOrder_ASC">Názvu</option>
      <option value="price_ASC">Najnižšej ceny</option>
      <option value="price_DESC">Najvyššej ceny</option>
    </TextField>
  );
};
