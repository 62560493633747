// core
import React, { useCallback } from 'react';

// formik
import { FastField as Field, useFormikContext } from 'formik';
import { TextField } from 'formik-material-ui';

// material
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import MuiCheckbox from '@material-ui/core/Checkbox';

// components
import { LoadSelectOptions } from 'components/LoadSelectOptions';

// partials
import { CarDetailsFormValues } from './CarDetailsForm';
import { AccessoriesFields } from './AccessoriesFields';
import { HighlightField } from './HighlightField';

const useStyles = makeStyles((theme) => ({
  miniField: {
    paddingTop: 4,
    paddingBottom: 4
  },
  additionalField: {
    marginLeft: 8
  }
}));

export interface Props {
  isMotorbike: boolean;
}

export const AccessoriesFieldsSafety = ({ isMotorbike }: Props) => {
  const classes = useStyles();

  const { values } = useFormikContext<CarDetailsFormValues>();

  const handleAppendFields = useCallback(
    (field) => {
      const ret = [];

      if (field.value === '3' && values.safetyEquipment.includes('3')) {
        ret.push(
          <LoadSelectOptions
            key="field"
            id="airbags"
            renderOption={(option) => (
              <MenuItem key={option.value} value={option.value}>
                <MuiCheckbox color="primary" checked={!!~values.airbags.indexOf(option.value)} />
                <ListItemText primary={option.label} />
              </MenuItem>
            )}
          >
            {(options) => (
              <Field
                name="airbags"
                select
                component={TextField}
                variant="filled"
                SelectProps={{
                  native: false
                }}
                InputProps={{
                  inputProps: {
                    multiple: true,
                    renderValue: (selected: string) =>
                      options
                        .filter((o) => ~selected.indexOf(o.value))
                        .map((o) => o.label)
                        .join(', '),
                    className: classes.miniField
                  }
                }}
                className={classes.additionalField}
              />
            )}
          </LoadSelectOptions>
        );
      } else if (field.value === '18' && values.safetyEquipment.includes('18')) {
        ret.push(
          <LoadSelectOptions key="field" id="parkingSensors">
            <Field
              name="parkingSensors"
              select
              component={TextField}
              variant="filled"
              InputProps={{
                inputProps: {
                  className: classes.miniField
                }
              }}
              className={classes.additionalField}
            />
          </LoadSelectOptions>
        );
      }

      ret.push(<HighlightField key="highlight" name="highlights" value={`s${field.value}`} />);

      return ret;
    },
    [classes, values.airbags, values.safetyEquipment]
  );

  return (
    <AccessoriesFields
      name="safetyEquipment"
      appendFields={handleAppendFields}
      isMotorbike={isMotorbike}
    />
  );
};
