// core
import React from 'react';

// libraries
import classnames from 'classnames';

// apollo
import { useQuery } from '@apollo/client';
import totalCarsQuery from '../graphql/TotalCarsQuery.graphql';
import { TotalCarsQuery, TotalCarsQueryVariables } from '../graphql/TotalCarsQuery';

// material
import { makeStyles } from '@material-ui/core/styles';

// components
import { PageHeading } from 'components/PageHeading';
import { Loading } from 'components/Loading';
import { GqlResponseHandler } from 'components/GqlResponseHandler';

// hooks
import { Category } from '../hooks/useCategories';

// partials
import { DiscountOfWeek } from './DiscountOfWeek';
import { CarsBreadcrumbs } from './CarsBreadcrumbs';
import { CarsMeta } from './CarsMeta';
import { CarListQuery } from './CarListQuery';
// import { CovidAlert } from './CovidAlert';

// hooks
import { useCarsFilter } from '../hooks/useCarsFilter';

export interface Props {
  category: Category | null;
  onDrawerTool: () => void;
}

const useStyles = makeStyles((theme) => ({
  list: {
    paddingTop: 96,
    paddingBottom: 64,
    [theme.breakpoints.down('md')]: {
      paddingTop: 64
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 48
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 32
    },
    '& > h1': {
      marginTop: 32
    }
  },
  sidePaddings: {
    paddingLeft: 64,
    paddingRight: 64,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 32,
      paddingRight: 32
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 16,
      paddingRight: 16
    }
  },
  heading: {
    paddingBottom: 0
  },
  filterCount: {
    color: theme.palette.primary.main
  }
}));

export const CarsContent = ({ category, onDrawerTool }: Props) => {
  const classes = useStyles();

  const { hasFilter, filterCarsLength, allFilterResults } = useCarsFilter();

  const isDiscountOfWeek = category?.slug === 'zlava-tyzdna';

  const { data, loading, error } = useQuery<TotalCarsQuery, TotalCarsQueryVariables>(
    totalCarsQuery,
    {
      variables: {
        category: isDiscountOfWeek ? undefined : category?.slug,
        discountOfWeek: isDiscountOfWeek
      }
    }
  );

  const totalCars = data?.totalCars || 0;

  return (
    <>
      <CarsMeta category={category} />

      <CarsBreadcrumbs category={category} className={classes.sidePaddings} />

      <div className={classnames(classes.list, classes.sidePaddings)} data-testid="carsList">
        {/* <CovidAlert /> */}

        <DiscountOfWeek category={(!isDiscountOfWeek && category) || null} />

        {loading ? (
          <Loading fullHeight />
        ) : (
          <GqlResponseHandler error={error}>
            <PageHeading
              buttons={
                category
                  ? [
                      {
                        label: 'Všetky vozidlá',
                        to: '/vozidla-na-predaj',
                        variant: 'text'
                      }
                    ]
                  : undefined
              }
              className={classes.heading}
              subheading={
                totalCars ? (
                  <small>
                    <span>(</span>
                    {hasFilter && filterCarsLength ? (
                      <>
                        <span className={classes.filterCount}>
                          <span>{filterCarsLength}</span>
                          {!allFilterResults ? <span>+</span> : null}
                        </span>
                        <span>/</span>
                      </>
                    ) : null}
                    <span>{totalCars}</span>
                    <span>)</span>
                  </small>
                ) : null
              }
            >
              {category?.name || 'Vozidlá na predaj'}
            </PageHeading>

            <CarListQuery
              category={category}
              totalCars={totalCars}
              isDiscountOfWeek={isDiscountOfWeek}
              onDrawerTool={onDrawerTool}
            />
          </GqlResponseHandler>
        )}
      </div>
    </>
  );
};
