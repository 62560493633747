// core
import React from 'react';

// apollo
import { CategoryCardFragment } from '../graphql/CategoryCardFragment';

// assets
import cityCarCategoryImage from '../../../images/city_car_category.png';
import suvCarCategoryImage from '../../../images/suv_car_category.png';
import inWarantyCarCategoryImage from '../../../images/in_waranty_car_category.png';
import utilityCarCategoryImage from '../../../images/utility_car_category.png';
import cheapCarCategoryImage from '../../../images/cheap_car_category.png';
import familyCarCategoryImage from '../../../images/family_car_category.png';

// libraries
import { useHistory, Link } from 'react-router-dom';
import classnames from 'classnames';

// material
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

export interface Props {
  category: Omit<CategoryCardFragment, '__typename'>;
  selected?: string | null;
  variant?: 'default' | 'big';
  fancy?: boolean;
  classes?: {
    root?: string;
    selected?: string;
    big?: string;
    fancy?: string;
    wrap?: string;
    image?: string;
    content?: string;
    title?: string;
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.secondary.main,
    backgroundColor: theme.backgroundColor
  },
  wrap: {
    display: 'flex',
    height: 'auto',
    flexDirection: 'column',
    paddingTop: 8,
    paddingBottom: 8
  },
  image: {
    width: 60,
    height: 'auto',
    marginBottom: theme.spacing(0.5)
  },
  content: {
    flex: 1,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    width: '100%',
    '& > h2': {
      width: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  },
  title: {
    marginBottom: 0,
    textAlign: 'center',
    lineHeight: '1.4em',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9em'
    }
  },
  selected: {
    borderStyle: 'solid',
    borderWidth: 2,
    borderColor: theme.palette.primary.main,
    margin: -2
  },
  big: {
    '& $wrap': {
      height: 'auto',
      flexDirection: 'column'
    },
    '& $image': {
      width: 150,
      maxWidth: '70%',
      marginTop: 24,
      marginBottom: 16,
      [theme.breakpoints.down('xs')]: {
        marginTop: 16
      }
    },
    '& $title': {
      fontSize: '1.3em',
      marginBottom: 24,
      [theme.breakpoints.down('xs')]: {
        marginBottom: 16
      }
    }
  },
  fancy: {
    backgroundColor: theme.palette.secondary.main,
    color: '#ffffff'
  }
}));

export const CategoryCard = ({
  category,
  selected,
  variant,
  fancy = false,
  classes = {}
}: Props) => {
  const css = useStyles();

  const history = useHistory();

  let image = null;
  switch (category.image) {
    case 'cheap_car':
      image = cheapCarCategoryImage;
      break;
    case 'in_waranty_car':
      image = inWarantyCarCategoryImage;
      break;
    case 'city_car':
      image = cityCarCategoryImage;
      break;
    case 'family_car':
      image = familyCarCategoryImage;
      break;
    case 'suv_car':
      image = suvCarCategoryImage;
      break;
    case 'utility_car':
      image = utilityCarCategoryImage;
      break;
    default:
      image = inWarantyCarCategoryImage;
  }

  const isSelected = category.slug === selected;

  return (
    <Card
      className={classnames(css.root, classes.root, {
        [css.selected]: isSelected,
        [classes.selected || '']: isSelected,
        [css.big]: variant === 'big',
        [classes.big || '']: variant === 'big',
        [css.fancy]: fancy,
        [classes.fancy || '']: fancy
      })}
      title={category.name}
    >
      <CardActionArea
        component={Link}
        to={
          isSelected
            ? `/vozidla-na-predaj${history.location.search}`
            : `/vozidla-na-predaj/${category.slug}${history.location.search}`
        }
        className={classnames(css.wrap, classes.wrap)}
      >
        <CardMedia
          component="img"
          alt={category.name}
          image={image}
          title={category.name}
          className={classnames(css.image, classes.image)}
          width="150"
          height="75"
        />

        <CardContent className={classnames(css.content, classes.content)}>
          <Typography
            gutterBottom
            variant="subtitle1"
            component="h2"
            className={classnames(css.title, classes.title)}
          >
            {category.name}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
