// core
import React, { ReactNode } from 'react';

// libraries
import classnames from 'classnames';

// material
import { makeStyles } from '@material-ui/core/styles';

export interface Props {
  wider?: boolean;
  paddingTop?: boolean;
  paddingBottom?: boolean;
  className?: string;
  children: ReactNode;
}

const useStyles = makeStyles((theme) => ({
  wrap: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3)
    },
    maxWidth: 1250 - 2 * 40,
    marginRight: 'auto',
    marginLeft: 'auto'
  },
  wider: {
    '& > div': {
      maxWidth: 1250
    }
  },
  paddingTop: {
    paddingTop: 96,
    [theme.breakpoints.down('md')]: {
      paddingTop: 64
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 48
    }
  },
  paddingBottom: {
    paddingBottom: 64
  }
}));

export const ContentWrap = ({ wider, children, paddingTop, paddingBottom, className }: Props) => {
  const classes = useStyles();

  return (
    <div
      className={classnames(
        wider && classes.wider,
        paddingTop && classes.paddingTop,
        paddingBottom && classes.paddingBottom,
        className
      )}
    >
      <div className={classes.wrap}>{children}</div>
    </div>
  );
};
