// core
import React, { useCallback } from 'react';

// libraries
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

// apollo
import { useQuery } from '@apollo/client';
import query from '../graphql/CarsQuery.graphql';
import { CarsQuery, CarsQueryVariables } from '../graphql/CarsQuery';

// material
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import Badge from '@material-ui/core/Badge';

// icons
import ClearIcon from '@material-ui/icons/Clear';
import FilterIcon from '@material-ui/icons/FilterList';
import EmptyMessageIcon from '@material-ui/icons/EmojiTransportationOutlined';

// components
import { Loading } from 'components/Loading';
import { EmptyMessage } from 'components/EmptyMessage';
import { GqlResponseHandler } from 'components/GqlResponseHandler';
import { Pagination } from 'components/Pagination';

// hooks
import { Category } from '../hooks/useCategories';

// partials
import { CarListAll } from './CarListAll';
import { Sort } from './Sort';
// import { CovidAlert } from './CovidAlert';

// hooks
import { useSort } from '../hooks/useSort';
import { usePagination } from '../hooks/usePagination';
import { useCarsFilter } from '../hooks/useCarsFilter';
import { Filter } from '../contexts/CarsFilterContext';

export interface Props {
  category: Category | null;
  totalCars: number;
  isDiscountOfWeek: boolean;
  onDrawerTool: () => void;
}

const useStyles = makeStyles((theme) => ({
  emptyMessage: {
    marginTop: theme.spacing(2)
  },
  emptyMessageButton: {
    marginTop: theme.spacing(2)
  },
  filterWrap: {
    display: 'flex',
    alignItems: 'center',
    height: 42,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(),
    justifyContent: 'space-between'
  },
  filterButton: {
    marginLeft: -theme.spacing(),
    marginRight: theme.spacing(),
    '& div': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    },
    '& svg': {
      marginRight: theme.spacing()
    }
  },
  filterButtonWithBadge: {
    '& svg': {
      marginRight: 12
    },
    '& svg + span': {
      right: 12
    }
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center'
    }
  },
  paginationTop: {
    marginTop: 0,
    [theme.breakpoints.down('xs')]: {
      maxWidth: '50%',
      overflow: 'hidden'
    }
  },
  filterClearButton: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: 4,
      marginBottom: 1
    }
  }
}));

export const CarListQuery = ({ category, totalCars, isDiscountOfWeek, onDrawerTool }: Props) => {
  const classes = useStyles();
  const sendDataToGTM = useGTMDispatch();

  const { sort, setSort } = useSort();
  const { offset, setOffset } = usePagination();
  const { hasFilter, filter, resetFilter, allFilterResults, setAllFilterResults } = useCarsFilter();

  const { data, loading, error, fetchMore } = useQuery<CarsQuery, CarsQueryVariables>(query, {
    variables: {
      ...filter,
      category: isDiscountOfWeek ? undefined : category?.slug,
      discountOfWeek: isDiscountOfWeek,
      limit: 10,
      offset: hasFilter ? 0 : offset,
      sort
    }
  });

  const cars = data?.cars || [];

  const handlePaginationClick = useCallback(
    (e, offset) => {
      setOffset(offset);

      window.scrollTo(0, 0);

      sendDataToGTM({
        event: 'event',
        eventCategory: 'Pagination',
        eventAction: 'Changed',
        eventValue: undefined,
        eventLabel: (offset / 10).toString()
      });
    },
    [setOffset, sendDataToGTM]
  );

  const handleFetchMore = useCallback(() => {
    fetchMore({
      variables: {
        offset: data?.cars?.length || 0
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }

        setAllFilterResults((fetchMoreResult.cars?.length || 0) < 10);

        return Object.assign({}, prev, {
          cars: [...(prev.cars || []), ...(fetchMoreResult.cars || [])]
        });
      }
    });
  }, [fetchMore, data, setAllFilterResults]);

  const handleListReset = useCallback(() => {
    setSort('titleOrder_ASC');
    setOffset(0);
    resetFilter();
  }, [setSort, setOffset, resetFilter]);

  return loading ? (
    <Loading fullHeight />
  ) : (
    <GqlResponseHandler error={error}>
      <div className={classes.filterWrap}>
        <Hidden smDown>
          <Sort />
        </Hidden>

        <Hidden mdUp>
          {(cars && cars.length > 0) || hasFilter ? (
            <Button
              color="primary"
              aria-label="Open drawer"
              onClick={onDrawerTool}
              className={classnames(
                classes.filterButton,
                hasFilter ? classes.filterButtonWithBadge : null
              )}
            >
              {hasFilter || sort !== 'titleOrder_ASC' ? (
                <Badge
                  badgeContent={
                    Object.keys(filter).filter((key) => !!filter[key as keyof Filter]).length +
                    (sort === 'titleOrder_ASC' ? 0 : 1)
                  }
                  color="primary"
                >
                  <FilterIcon />
                </Badge>
              ) : (
                <FilterIcon />
              )}
              <div>Filtrovať a zoradiť</div>
            </Button>
          ) : null}
        </Hidden>

        {hasFilter ? (
          <Button variant="text" color="primary" onClick={handleListReset}>
            <div className={classes.filterClearButton}>
              <ClearIcon fontSize="small" />
              Zrušiť filter
            </div>
          </Button>
        ) : null}

        {hasFilter || cars.length === 0 ? null : (
          <Pagination
            limit={10}
            offset={offset}
            total={totalCars}
            siblingCount={0}
            onChange={handlePaginationClick}
            className={classnames(classes.pagination, classes.paginationTop)}
          />
        )}
      </div>

      {!cars || cars.length === 0 ? (
        hasFilter ? (
          <EmptyMessage
            icon={<EmptyMessageIcon />}
            message="Pre zvolený filter sa nenašli žiadne vozidlá"
            className={classes.emptyMessage}
          />
        ) : category ? (
          <>
            <EmptyMessage
              icon={<EmptyMessageIcon />}
              message="V tejto kategórií nie sú na predaj žiadne vozidlá"
              className={classes.emptyMessage}
            />
            <Button
              variant="text"
              color="primary"
              component={Link}
              to="/vozidla-na-predaj"
              className={classes.emptyMessageButton}
            >
              Zobraziť všetky vozidlá
            </Button>
          </>
        ) : (
          <EmptyMessage
            icon={<EmptyMessageIcon />}
            message="Momentálne nie sú na predaj žiadne vozidlá"
            className={classes.emptyMessage}
          />
        )
      ) : (
        <>
          <CarListAll cars={cars} separateNew={!hasFilter && sort === 'titleOrder_ASC'} />

          {hasFilter ? (
            !allFilterResults && cars.length >= 10 ? (
              <Button fullWidth variant="contained" onClick={handleFetchMore}>
                Načítať viac výsledkov
              </Button>
            ) : null
          ) : (
            <Pagination
              limit={10}
              offset={offset}
              total={totalCars}
              onChange={handlePaginationClick}
              className={classes.pagination}
            />
          )}
        </>
      )}
    </GqlResponseHandler>
  );
};
