// core
import React from 'react';

// libraries
import { Helmet } from 'react-helmet';

// hooks
import { Category } from '../hooks/useCategories';

// assets
import ogImage from '../../../images/og-cars.jpg';

export interface Props {
  category: Category | null;
}

export const CarsMeta = ({ category }: Props) => {
  return (
    <Helmet>
      <title>
        {category
          ? `${category.name} - Vozidlá na predaj - EDENbazar`
          : 'Vozidlá na predaj - EDENbazar'}
      </title>
      <meta
        name="description"
        content="Pravidelne aktualizovaná ponuka našich vozidiel vo výbornom stave. Autá sú málo jazdené alebo úplne nové."
      />
      <link
        rel="canonical"
        href={
          category
            ? `${global.baseUrl}/vozidla-na-predaj/${category.slug}`
            : `${global.baseUrl}/vozidla-na-predaj`
        }
      />
      <meta property="og:url" content={`${global.baseUrl}/vozidla-na-predaj`} />
      <meta property="og:title" content="EDENbazar - Aktuálne vozidlá v našej ponuke" />
      <meta
        property="og:description"
        content="Pravidelne aktualizovaná ponuka našich vozidiel vo výbornom stave. Autá sú málo jazdené alebo úplne nové."
      />
      <meta property="og:image" content={`${global.baseUrl.replace('https', 'http')}${ogImage}`} />
      <meta property="og:image:secure_url" content={`${global.baseUrl}${ogImage}`} />
    </Helmet>
  );
};
