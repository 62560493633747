// core
import React, { useRef } from 'react';

// assets
import logo from '../../images/EDENbazar-logo.svg';

// libraries
import classnames from 'classnames';
import { Redirect } from 'react-router-dom';
import QRCode from 'qrcode.react';
import { Helmet } from 'react-helmet';

// apollo
import { useQuery } from '@apollo/client';
import query from './graphql/PrintCarQuery.graphql';
import { PrintCarQuery } from './graphql/PrintCarQuery';

// material
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

// material icons
import PhoneIcon from '@material-ui/icons/PhoneOutlined';
import EmailIcon from '@material-ui/icons/EmailOutlined';

// components
import { PageHeading } from 'components/PageHeading';
import { Price } from 'components/Price';
import { GqlResponseHandler } from 'components/GqlResponseHandler';
import { CarHighlights } from 'components/CarHighlights';

// partials
import { CarDetailsContainer } from '../Car/components/CarDetailsContainer';
import { DiscountBadge } from '../Car/components/DiscountBadge';
import { MileageNote } from '../Car/components/MileageNote';

export interface Props {
  match: {
    params: {
      slug: string;
    };
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100vh',
    position: 'relative',
    overflow: 'hidden'
  },
  heading: {
    marginTop: 16,
    paddingBottom: 32,
    '& h1': {
      paddingTop: 32,
      fontSize: '2.5em'
    },
    '& h1  small': {
      fontSize: '0.4em',
      marginTop: 8
    }
  },
  imageWrap: {
    width: '100%',
    height: 110,
    overflow: 'hidden',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  hightlights: {
    marginTop: 0,
    marginBottom: 32,
    paddingLeft: 48,
    paddingRight: 48
  },
  hightlightItem: {
    fontSize: '1.2em'
  },
  hightlightItemIcon: {
    fontSize: '1.4em',
    marginRight: 16
  },
  divider: {
    marginBottom: 32
  },
  detailsLabel: {
    fontSize: '1.05em'
  },
  detailsText: {
    fontSize: '0.90em'
  },
  qrcodeWrap: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 32,
    '& > canvas': {
      marginRight: 16
    }
  },
  priceWrap: {
    textAlign: 'right',
    position: 'absolute',
    bottom: 112,
    right: 0
  },
  price: {
    fontWeight: 'bold',
    fontSize: '4.5em'
  },
  hasDiscount: {
    marginTop: theme.spacing(4)
  },
  discountBadge: {
    fontSize: '2em',
    height: 40,
    borderRadius: 20,
    transform: 'scale(1) translate(0, -50%)',
    right: 10
  },
  originalPrice: {
    top: -11,
    right: '105%'
  },
  contactRowWithIcon: {
    display: 'flex',
    alignItems: 'center',
    '&:first-child': {
      marginTop: 4,
      marginBottom: 8
    },
    '& > svg': {
      marginRight: 8
    }
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    left: -16,
    right: -16,
    paddingLeft: 24,
    paddingRight: 24,
    backgroundColor: '#fff',
    '& > hr': {
      marginLeft: -8,
      marginRight: -8,
      marginBottom: 16
    }
  },
  logoWrap: {
    textAlign: 'right'
  },
  logo: {
    width: 'auto',
    height: 32,
    marginTop: 12
  },
  address: {
    lineHeight: '1.3em'
  }
}));

export const PrintCar = ({ match }: Props) => {
  const classes = useStyles();
  const title = useRef('');

  const slug = match.params.slug;

  const { data, loading, error } = useQuery<PrintCarQuery>(query, { variables: { slug } });

  const { car } = data || {};

  if (!loading && !error && !car) {
    return <Redirect to="/404" />;
  }

  return (
    <GqlResponseHandler loading={loading} error={error}>
      {car && (
        <div className={classes.root}>
          <Helmet>
            <title>{`${car.fullTitle} - ${car.mainCategory.name} - Vozidlá na predaj - EDENbazar`}</title>
          </Helmet>

          <PageHeading className={classes.heading}>
            {car.fullTitle}
            {car.vin ? (
              <Typography variant="caption" component="small" color="textSecondary">
                VIN: {car.vin}
              </Typography>
            ) : null}
          </PageHeading>

          <Grid container spacing={0}>
            <Grid item xs={3}>
              <div
                className={classes.imageWrap}
                style={{
                  backgroundImage:
                    (car.images &&
                      car.images[0] &&
                      `url('${global.cdnUrl}/fotografia/${car.images[0]}')`) ||
                    ''
                }}
              />
            </Grid>

            <Grid item xs={9}>
              <CarHighlights
                car={car}
                className={classes.hightlights}
                classes={{ item: classes.hightlightItem, icon: classes.hightlightItemIcon }}
              />
            </Grid>
          </Grid>

          <Divider className={classes.divider} />

          <Grid container spacing={1}>
            <Grid item xs={12}>
              <CarDetailsContainer
                label="Technické údaje"
                details={car.technicalDetails}
                classes={{ details: classes.detailsText }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <CarDetailsContainer
                label="Bezpečnost"
                details={car.safetyDetails}
                classes={{ details: classes.detailsText }}
              />

              <CarDetailsContainer
                label="Stav vozidla"
                details={car.stateDetails}
                classes={{ details: classes.detailsText }}
              />

              <CarDetailsContainer
                label="Ostatné"
                details={car.otherDetails}
                classes={{ details: classes.detailsText }}
              />

              <CarDetailsContainer
                label="Poznámky"
                details={car.notes}
                classes={{ details: classes.detailsText }}
              />

              <div className={classes.qrcodeWrap}>
                <QRCode
                  value={`${global.baseUrl}/vozidla-na-predaj/${car.mainCategory.slug}/${car.slug}`}
                  size={64}
                  fgColor="#53555F"
                />
                <Typography variant="h6" component="div" color="secondary">
                  Otvoriť v<br />
                  EDENbazar.sk
                </Typography>
              </div>
            </Grid>

            <Grid item xs={6}>
              <CarDetailsContainer
                label="Komfort"
                details={car.comfortDetails}
                classes={{ details: classes.detailsText }}
              />

              <MileageNote />
            </Grid>
          </Grid>

          <div className={classes.priceWrap}>
            <Typography variant="h5" component="div">
              Spolu s DPH
            </Typography>

            <DiscountBadge
              discount={car.discount}
              price={car.price}
              className={classnames({
                [classes.hasDiscount]: car.discount > 0
              })}
              classes={{
                badge: classes.discountBadge,
                originalPrice: classes.originalPrice
              }}
            >
              <Typography variant="h1" component="div" className={classes.price}>
                <Price currency="€" decimals={0}>
                  {car.price - car.discount}
                </Price>
              </Typography>
            </DiscountBadge>

            {car.vatDeduction ? (
              <Typography variant="subtitle1" component="div" color="textSecondary">
                možný odpočet DPH
              </Typography>
            ) : null}
          </div>

          <div className={classes.footer}>
            <Divider />

            <Grid container>
              <Grid item xs={3}>
                <Typography variant="subtitle1" component="div" className={classes.address}>
                  Areál VHS
                  <br />
                  Pestovateľská 6
                  <br />
                  Bratislava
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography
                  variant="subtitle2"
                  component="div"
                  className={classes.contactRowWithIcon}
                >
                  <PhoneIcon />
                  +421 940 509 689
                </Typography>

                <Typography
                  variant="subtitle2"
                  component="div"
                  className={classes.contactRowWithIcon}
                >
                  <EmailIcon />
                  info@edenbazar.sk
                </Typography>
              </Grid>

              <Grid item xs={5} className={classes.logoWrap}>
                <img
                  src={logo}
                  alt="EDENbazar logo"
                  className={classes.logo}
                  width="320"
                  height="41"
                />
              </Grid>
            </Grid>
          </div>
        </div>
      )}
    </GqlResponseHandler>
  );
};
