// core
import React, { useMemo } from 'react';

// hooks
import { Category } from '../hooks/useCategories';

// components
import { Breadcrumbs, BreadcrumbsItem } from 'components/Breadcrumbs';

export interface Props {
  category: Category | null;
  className?: string;
}

export const CarsBreadcrumbs = ({ category, className }: Props) => {
  const breadcrumbs = useMemo(() => {
    const breadcrumbs: BreadcrumbsItem[] = [];

    if (category) {
      breadcrumbs.push({
        label: 'Vozidlá na predaj',
        to: '/vozidla-na-predaj'
      });
      breadcrumbs.push({
        label: category.name
      });
    } else {
      breadcrumbs.push({
        label: 'Vozidlá na predaj'
      });
    }

    return breadcrumbs;
  }, [category]);

  return <Breadcrumbs items={breadcrumbs} className={className} />;
};
