// core
import React, { ReactNode } from 'react';

// material
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// material icons
import MuiExpandMoreIcon from '@material-ui/icons/ExpandMore';

// partials
import { FooterMenuItem, Props as FooterMenuItemProps } from './FooterMenuItem';

export interface Props {
  heading?: ReactNode;
  items?: Array<FooterMenuItemProps>;
  expandable?: boolean;
  children?: ReactNode;
}

// @ts-ignore
const useStyles = makeStyles((theme) => ({
  heading: {
    color: theme.palette.grey[50],
    fontSize: '1.1em',
    lineHeight: '1em', //  to be able to compute height
    marginTop: 10,
    marginBottom: 24,
    [theme.breakpoints.down('xs')]: {
      margin: 0
    }
  },
  text: {
    display: 'block',
    color: theme.palette.grey[500],
    fontSize: 'inherit'
  },
  expandMoreIcon: {
    color: theme.palette.grey[50]
  },
  expansionPanelDetails: {
    display: 'block',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.grey[900],
    '& > a': {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      lineHeight: '48px',
      margin: 0
    }
  },
  expanded: {},
  expansionPanelSummary: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3)
    },
    height: 64,
    '&$expanded': {
      minHeight: 'auto'
    },
    content: {
      '&$expanded': {
        margin: '12px 0'
      }
    }
  },
  expansionPanel: {
    background: 'none',
    border: 'none',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    }
  }
}));

export const FooterColumn = ({ heading, items, expandable, children }: Props) => {
  const classes: any = useStyles();

  const headingComponent = heading ? (
    <Typography variant="subtitle2" className={classes.heading}>
      {heading}
    </Typography>
  ) : null;

  const itemsComponent = items
    ? items.map((item, key) => (
        <FooterMenuItem
          key={key}
          label={item.label}
          to={item.to}
          classes={{ link: classes.text }}
        />
      ))
    : null;

  return expandable ? (
    <Accordion className={classes.expansionPanel}>
      <AccordionSummary
        className={classes.expansionPanelSummary}
        expandIcon={<MuiExpandMoreIcon className={classes.expandMoreIcon}></MuiExpandMoreIcon>}
      >
        {headingComponent}
      </AccordionSummary>
      <AccordionDetails className={classes.expansionPanelDetails}>
        {itemsComponent}
      </AccordionDetails>
    </Accordion>
  ) : (
    <>
      {headingComponent}

      {itemsComponent}

      <Typography variant="subtitle1" component="div" className={classes.text}>
        {children}
      </Typography>
    </>
  );
};
