// core
import React from 'react';

// apollo
import { useQuery } from '@apollo/client';
import carPaginationQuery from '../graphql/CarPaginationQuery.graphql';
import { CarPaginationQuery, CarPaginationQueryVariables } from '../graphql/CarPaginationQuery';

// components
import { PageHeading } from 'components/PageHeading';

// libraries
import { Link } from 'react-router-dom';

// material
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

// material icons
import PrevArrowIcon from '@material-ui/icons/ArrowBackIosOutlined';
import NextArrowIcon from '@material-ui/icons/ArrowForwardIosOutlined';

// components
import { GqlResponseHandler } from 'components/GqlResponseHandler';
import { CarHighlights } from 'components/CarHighlights';

export interface Props {
  cursor: string;
  state?: {
    from?: {
      category: string;
      filter: any;
    };
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 96,
    marginBottom: 32
  },
  card: {
    boxShadow: 'none',
    border: 'solid 1px',
    borderColor: theme.borderColor,
    [theme.breakpoints.down('sm')]: {
      marginTop: -16
    },
    '& > a': {
      display: 'flex',
      alignItems: 'flex-end',
      height: 100,
      [theme.breakpoints.down('xs')]: {
        height: 75
      }
    }
  },
  media: {
    width: 150,
    height: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: 100
    }
  },
  heading: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  content: {
    flex: 1,
    height: '100%',
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
    '& > div': {
      flex: 1,
      overflow: 'hidden'
    },
    '& > div:first-child': {
      marginLeft: 8,
      [theme.breakpoints.down('xs')]: {
        marginLeft: 0
      }
    },
    '& > svg:first-child': {
      marginRight: 16,
      [theme.breakpoints.down('xs')]: {
        marginRight: 8
      }
    }
  },
  highlights: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
}));

export const CarPagination = ({ cursor, state }: Props) => {
  const classes = useStyles();

  const { category, filter } = (state && state.from) || {};
  const hasFilter = !!(filter && Object.keys(filter).find((key) => !!filter[key]));

  const { data, loading, error } = useQuery<CarPaginationQuery, CarPaginationQueryVariables>(
    carPaginationQuery,
    {
      variables: {
        cursor,
        category: category === 'all' ? null : category,
        ...filter
      }
    }
  );

  const prevCar = (data?.prevCar && data?.prevCar[0]) || null;
  const nextCar = (data?.nextCar && data?.nextCar[0]) || null;

  return (
    <GqlResponseHandler loading={loading} error={error}>
      <div className={classes.root}>
        <PageHeading>
          {hasFilter
            ? 'Ďalšie vozidlá podľa zvoleného filtra'
            : category
            ? category === 'lacne'
              ? 'Ďalšie lacné vozidlá'
              : category === 'v-zaruke'
              ? 'Ďalšie vozidlá v záruke'
              : category === 'mestske'
              ? 'Ďalšie mestské vozidlá'
              : category === 'rodinne'
              ? 'Ďalšie rodinné vozidlá'
              : category === 'manazerske'
              ? 'Ďalšie manažérske vozidlá'
              : category === 'uzitkove'
              ? 'Ďalšie úžitkové vozidlá'
              : 'Ďalšie vozidlá na predaj'
            : 'Ďalšie vozidlá na predaj'}
        </PageHeading>

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Card className={classes.card}>
              <CardActionArea
                component={Link}
                disabled={!prevCar}
                to={{
                  pathname: prevCar
                    ? `/vozidla-na-predaj/${prevCar.mainCategory.slug}/${prevCar.slug}`
                    : '/vozidla-na-predaj',
                  state: state
                }}
              >
                <CardContent className={classes.content}>
                  <PrevArrowIcon
                    style={{ fontSize: 48 }}
                    color={prevCar ? 'inherit' : 'disabled'}
                  />

                  <div>
                    {prevCar ? (
                      <>
                        <Typography variant="h6" component="h2" className={classes.heading}>
                          {prevCar.fullTitle}
                        </Typography>

                        <Typography
                          variant="subtitle1"
                          component="div"
                          className={classes.highlights}
                        >
                          {prevCar.year}
                          {prevCar.month ? `/${prevCar.month}` : null}
                          {', '}

                          <CarHighlights car={prevCar} variant="text" />
                        </Typography>
                      </>
                    ) : null}
                  </div>
                </CardContent>

                {prevCar ? (
                  <CardMedia
                    component="img"
                    className={classes.media}
                    src={`${global.cdnUrl}/fotografia/${prevCar.thumbnail}`}
                    alt={prevCar.fullTitle}
                    title={prevCar.fullTitle}
                    width="600"
                    height="450"
                  />
                ) : null}
              </CardActionArea>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card className={classes.card}>
              <CardActionArea
                component={Link}
                disabled={!nextCar}
                to={{
                  pathname: nextCar
                    ? `/vozidla-na-predaj/${nextCar.mainCategory.slug}/${nextCar.slug}`
                    : '/vozidla-na-predaj',
                  state: state
                }}
              >
                {nextCar ? (
                  <CardMedia
                    component="img"
                    className={classes.media}
                    src={`${global.cdnUrl}/fotografia/${nextCar.thumbnail}`}
                    alt={nextCar.fullTitle}
                    title={nextCar.fullTitle}
                    width="600"
                    height="450"
                  />
                ) : null}

                <CardContent className={classes.content}>
                  <div>
                    {nextCar ? (
                      <>
                        <Typography variant="h6" component="h2" className={classes.heading}>
                          {nextCar.fullTitle}
                        </Typography>

                        <Typography
                          variant="subtitle1"
                          component="div"
                          className={classes.highlights}
                        >
                          {nextCar.year}
                          {nextCar.month ? `/${nextCar.month}` : null}
                          {', '}

                          <CarHighlights car={nextCar} variant="text" />
                        </Typography>
                      </>
                    ) : null}
                  </div>

                  <NextArrowIcon
                    style={{ fontSize: 48 }}
                    color={nextCar ? 'inherit' : 'disabled'}
                  />
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      </div>
    </GqlResponseHandler>
  );
};
