// core
import React, { memo, useMemo } from 'react';

// formik
import { Field } from 'formik';
import { CheckboxWithLabel } from 'formik-material-ui';

export interface Props {
  disabled?: boolean;
  checked: boolean;
}

export const VatDeductionField = memo(function VatDeductionField({ disabled, checked }: Props) {
  const labelProps = useMemo(
    () => ({
      label: 'odpočet DPH'
    }),
    []
  );

  return (
    <Field
      name="vatDeduction"
      component={CheckboxWithLabel}
      checked={checked}
      type="checkbox"
      color="primary"
      Label={labelProps}
      disabled={disabled}
    />
  );
});
