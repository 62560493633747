// core
import React, { useCallback, useState, useEffect } from 'react';

// apollo
import { useQuery, useMutation } from '@apollo/client';
import query from './graphql/CarDetailsFormQuery.graphql';
import carDetailsFormTemplateQuery from './graphql/CarDetailsFormTemplateQuery.graphql';
import removeTemplateMutation from './graphql/RemoveTemplateMutation.graphql';
import templatesQuery from './graphql/SelectTemplateOptionsQuery.graphql';
import { SelectTemplateOptionsQuery } from './graphql/SelectTemplateOptionsQuery';
import { CarDetailsFormQuery } from './graphql/CarDetailsFormQuery';
import { CarDetailsFormTemplateQuery } from './graphql/CarDetailsFormTemplateQuery';
import {
  RemoveTemplateMutation,
  RemoveTemplateMutationVariables
} from './graphql/RemoveTemplateMutation';

// components
import { ContentWrap } from 'components/ContentWrap';
import { PageHeading } from 'components/PageHeading';
import { useSnackbar } from 'components/Snackbar';
import { GqlResponseHandler } from 'components/GqlResponseHandler';

// context
import { UserContext } from 'AppRouter';

// libraries
import { Helmet } from 'react-helmet';

// material
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';

// material icons
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import DeleteForeverIcon from '@material-ui/icons/DeleteForeverOutlined';
import AssignmentIcon from '@material-ui/icons/Assignment';

// partials
import { TemplateSelect } from './components/TemplateSelect';
import { CarDetailsForm } from './components/CarDetailsForm';

export interface Props {
  match: {
    params: {
      id: string;
    };
  };
}

const useStyles = makeStyles((theme) => ({
  templateWrap: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    marginRight: 8,
    color: theme.palette.secondary.main
  },
  input: {
    flex: 1,
    maxWidth: 400
  },
  divider: {
    marginTop: 32,
    marginBottom: 32
  }
}));

export const Edit = ({ match }: Props) => {
  const css = useStyles();
  const { pushSnack } = useSnackbar();

  const id = match.params.id;

  const [template, setTemplate] = useState<null | string>(null);
  const [newTemplate, setNewTemplate] = useState<boolean>(false);
  const [confirmRemove, setConfirmRemove] = useState<boolean>(false);

  const handleTemplateChange = useCallback((name: string, isNew: boolean) => {
    setTemplate(name);
    setNewTemplate(isNew);
    setConfirmRemove(false);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const carQuery = useQuery<CarDetailsFormQuery>(query, {
    variables: { id },
    skip: !id
  });
  const templateQuery = useQuery<CarDetailsFormTemplateQuery>(carDetailsFormTemplateQuery, {
    variables: { id: template },
    skip: !template || newTemplate
  });
  const [removeTemplate, { loading: removeLoading, error: removeError }] = useMutation<
    RemoveTemplateMutation,
    RemoveTemplateMutationVariables
  >(removeTemplateMutation, {
    update: (cache, { data: newData }) => {
      const id = newData?.removeTemplate?.id || null;
      // all cars
      const data = cache.readQuery<SelectTemplateOptionsQuery>({
        query: templatesQuery
      });
      if (data) {
        cache.writeQuery({
          query: templatesQuery,
          data: {
            ...data,
            templates: data.templates?.filter((c) => c.id !== id)
          }
        });
      }
    }
  });

  if (removeError) {
    console.error(removeError);
  }

  const handleTemplateCreate = useCallback((template) => {
    setTemplate(template.id);
    setNewTemplate(false);
  }, []);

  const handleTemplateRemove = useCallback(() => {
    setConfirmRemove(true);
    setTimeout(() => setConfirmRemove(false), 1000);
  }, []);

  const handleTemplateRemoveConfirmed = useCallback(() => {
    if (template) {
      removeTemplate({ variables: { id: template } }).then(() => {
        pushSnack({
          message: 'Vzor bol úspešne odstránený',
          type: 'success'
        });
        setTemplate(null);
        setConfirmRemove(false);
      });
    }
  }, [removeTemplate, template, pushSnack]);

  return (
    <>
      <Helmet>
        <title>EDENbazar</title>
      </Helmet>

      <UserContext.Consumer>
        {(user) => {
          if (!user) {
            return null;
          }

          return (
            <ContentWrap paddingTop paddingBottom>
              <PageHeading>{id ? 'Upraviť vozidlo' : 'Pridať vozidlo'}</PageHeading>
              {!id ? (
                <>
                  <div className={css.templateWrap}>
                    <AssignmentIcon className={css.icon} />
                    <TemplateSelect onChange={handleTemplateChange} className={css.input} />

                    {template && !newTemplate ? (
                      <IconButton
                        onClick={
                          confirmRemove ? handleTemplateRemoveConfirmed : handleTemplateRemove
                        }
                        aria-label="Delete"
                      >
                        {removeLoading ? (
                          <CircularProgress size={24} />
                        ) : confirmRemove ? (
                          <DeleteForeverIcon color="primary" />
                        ) : (
                          <DeleteIcon />
                        )}
                      </IconButton>
                    ) : null}
                  </div>
                  <Divider className={css.divider} />
                </>
              ) : null}

              <GqlResponseHandler
                loading={carQuery.loading || templateQuery.loading}
                error={carQuery.error || templateQuery.error}
              >
                <CarDetailsForm
                  isTemplate={!id && !!template}
                  data={
                    id
                      ? carQuery.data?.car || null
                      : template && !newTemplate
                      ? templateQuery.data?.template || null
                      : null
                  }
                  templateName={
                    template && newTemplate ? template : templateQuery.data?.template?.name || null
                  }
                  onCreateTemplate={handleTemplateCreate}
                />
              </GqlResponseHandler>
            </ContentWrap>
          );
        }}
      </UserContext.Consumer>
    </>
  );
};
