// core
import React, { useRef, useState, useCallback } from 'react';

// formik
import { useField, useFormikContext } from 'formik';

// apollo
import { useMutation } from '@apollo/client';
import uploadImagesMutation from '../graphql/UploadImagesMutation.graphql';
import { CarDetailsFormFragment } from '../graphql/CarDetailsFormFragment';

// components
import { useSnackbar } from 'components/Snackbar';

// libraries
import arrayMove from 'array-move';

// material
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Hidden from '@material-ui/core/Hidden';
import MuiTextField from '@material-ui/core/TextField';

// material icons
import UploadIcon from '@material-ui/icons/CloudUploadOutlined';

// helpers
import { resizeImage } from 'helpers/imageHelper';

// partials
import { CarDetailsFormValues } from './CarDetailsForm';
import { CarImagesSortable, Image } from './CarImagesSortable';

export interface Props {
  car: CarDetailsFormFragment | null;
}

const useStyles = makeStyles((theme) => ({
  uploadWrap: {
    display: 'flex',
    alignItems: 'baseline',
    '& > div:first-child': {
      flex: 1
    },
    '& > button': {
      marginLeft: 16,
      whiteSpace: 'nowrap'
    }
  },
  buttonLabelIcon: {
    marginRight: 8
  }
}));

export const CarDetailsFormPhotos = ({ car }: Props) => {
  const classes = useStyles();

  const uploadInput = useRef<HTMLInputElement | null>(null);

  const { pushSnack } = useSnackbar();
  const { isSubmitting } = useFormikContext<CarDetailsFormValues>();
  const [field, meta, { setValue, setError }] = useField<CarDetailsFormValues>('images');
  const [uploadImages, { loading }] = useMutation(uploadImagesMutation);

  const [images, setImages] = useState<Image[]>(
    car?.images && car?.imageThumbs
      ? car.images.map((image, key) => ({
          id: image,
          thumb: (car?.imageThumbs && car.imageThumbs[key]) || image
        }))
      : []
  );
  const [uploading, setUploading] = useState<boolean>(false);

  const handleImageRemoveToogle = useCallback(
    (id) =>
      setValue(
        ~field.value.indexOf(id)
          ? field.value.filter((d: string) => d !== id)
          : images.map(({ id }) => id).filter((i) => i === id || field.value.includes(i))
      ),
    [field.value, setValue, images]
  );

  const handleSortEnd = useCallback(
    ({ oldIndex, newIndex }) => {
      setImages((prevImages) => arrayMove(prevImages, oldIndex, newIndex));
      setValue(arrayMove(field.value, oldIndex, newIndex));
    },
    [field.value, setValue]
  );

  const handleFileChange = useCallback(
    (event) => {
      setValue([...(field.value || []), ...(event.currentTarget.files || [])]);
    },
    [field.value, setValue]
  );

  const handleImageUpload = useCallback(() => {
    const files = (field.value || []).filter((file: string | File) => file instanceof File);

    if (!files || files.length === 0) {
      setError('Vyberte fotografie');
      pushSnack({
        message: 'Vyberte fotografie',
        type: 'error'
      });
      return;
    }

    setUploading(true);

    return Promise.all([...files].map((file) => resizeImage(file)))
      .then((files) =>
        uploadImages({ variables: { id: car?.id || null, images: files } }).then(({ data }) => {
          if (uploadInput.current) {
            uploadInput.current.value = '';
          }

          setValue([...field.value, ...data.uploadImages]);
          setImages((prevImages) => [
            ...prevImages,
            ...data.uploadImages.map((image: string) => ({
              id: image,
              thumb: image
            }))
          ]);
          setUploading(false);
        })
      )
      .catch((e) => {
        console.error(e);
        pushSnack({
          message: 'Chyba pri ukladaní. Skúste to prosím znova.',
          type: 'error'
        });
        setUploading(false);
      });
  }, [car, uploadImages, field.value, setValue, setError, pushSnack]);

  return (
    <>
      <div className={classes.uploadWrap}>
        <MuiTextField
          type="file"
          margin="dense"
          inputProps={{ ref: uploadInput, multiple: true }}
          onChange={handleFileChange}
          error={!!meta.error}
          helperText={meta.error}
        />

        <Button
          variant="contained"
          color="primary"
          size="small"
          disabled={uploading || loading}
          onClick={handleImageUpload}
        >
          {uploading || loading ? (
            <CircularProgress size={24} />
          ) : (
            <>
              <UploadIcon className={classes.buttonLabelIcon} />
              Nahrať
              <Hidden xsDown> fotografie</Hidden>
            </>
          )}
        </Button>
      </div>

      {images && (
        <CarImagesSortable
          images={images}
          idsToSave={field.value || []}
          title={car?.fullTitle || ''}
          disabled={isSubmitting}
          onImageRemoveToogle={handleImageRemoveToogle}
          onSortEnd={handleSortEnd}
        />
      )}
    </>
  );
};
