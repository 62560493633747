// core
import React from 'react';

// material
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 64,
      bottom: -32,
      left: -32,
      right: 32,
      backgroundColor: theme.palette.primary.main,
      zIndex: -1,
      borderRadius: 4
    }
  },
  card: {
    height: 540,
    '& iframe': {
      width: '100%',
      height: '100%',
      border: 0
    },
    [theme.breakpoints.down('sm')]: {
      height: 360
    }
  }
}));

export const MapCard = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <iframe
          title="Google Maps"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2660.7754312863035!2d17.18172125147497!3d48.17240925661093!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476c8fb07c0c3db5%3A0xabc5a3ab24d8104a!2sEDENbazar!5e0!3m2!1sen!2ssk!4v1556721446783!5m2!1sen!2ssk"
          allowFullScreen
        />
      </Card>
    </div>
  );
};
