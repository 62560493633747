// core
import React, { useState, useEffect, useCallback } from 'react';

// libraries
import { Link, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

// assets
import packageJson from '../../../package.json';
import logo from '../../images/EDENbazar-logo.svg';
import facebookLogo from '../../images/facebook-logo.svg';

// components
import { ContentWrap } from 'components/ContentWrap';

// material
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import UIMenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import UILink from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { makeStyles } from '@material-ui/core/styles';

// material icons
import HometIcon from '@material-ui/icons/HomeOutlined';
import PhonetIcon from '@material-ui/icons/PhoneOutlined';
import AccountIcon from '@material-ui/icons/AccountCircleOutlined';
import MenuIcon from '@material-ui/icons/Menu';
import CarIcon from '@material-ui/icons/DirectionsCarOutlined';
import SuperSocoIcon from '@material-ui/icons/MotorcycleOutlined';
import FundingIcon from '@material-ui/icons/EuroOutlined';
// import OnlineIcon from '@material-ui/icons/LaptopMacOutlined';
import ContactIcon from '@material-ui/icons/StoreOutlined';

// partials
import { MenuItem } from './components/MenuItem';

import { User } from '../../AppRouter';

export interface Props {
  user: User | null;
  logOut: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
    background: 'inherit',
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: theme.borderColor,
    backgroundColor: '#fff',
    paddingRight: '0 !important' // popup set it to 15px
  },
  toolbar: {
    height: 100,
    flex: 1,
    paddingLeft: 0,
    paddingRight: 0,
    transition: 'height .2s'
  },
  scrolled: {
    boxShadow:
      '0px 2px 4px -1px rgba(66,66,66,0.2), 0px 4px 5px 0px rgba(66,66,66,0.14), 0px 1px 10px 0px rgba(66,66,66,0.12)',
    '& $toolbar': {
      height: 50,
      transition: 'height .5s'
    },
    '& $phoneWrap > span': {
      display: 'none'
    }
  },
  logo: {
    width: 'auto',
    height: 22,
    display: 'block'
  },
  menuWrap: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  menu: {
    flex: 1,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      textAlign: 'right'
    }
  },
  phoneWrap: {
    textAlign: 'right',
    marginRight: 4
  },
  phoneWrapInDrawer: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  phone: {
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginRight: 4
    }
  },
  menuButton: {
    marginLeft: theme.spacing(2)
  },
  drawerPaper: {
    width: 240
  },
  drawerMenu: {
    flex: 1
  },
  userMenu: {
    height: 48,
    marginLeft: theme.spacing(2)
  },
  userMenuHeading: {
    '&:focus': {
      outline: 'none'
    }
  },
  map: {
    width: '100%',
    height: '40%',

    border: 0
  },
  mobileMenuItemText: {
    paddingLeft: 0
  },
  userName: {
    fontWeight: 'bold'
  },
  facebook: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    '& > img': {
      marginRight: theme.spacing(1)
    }
  }
}));

export const Header = ({ user, logOut }: Props) => {
  const classes = useStyles();
  const location = useLocation();
  const sendDataToGTM = useGTMDispatch();

  const [mobileOpen, setMobileOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);

  const open = Boolean(menuAnchorEl);
  const path = location.pathname;

  const handleDrawerToggle = useCallback(() => {
    setMobileOpen((open) => !open);
  }, []);

  const handleUserMenu = useCallback((event) => {
    setMenuAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setMenuAnchorEl(null);
  }, []);

  useEffect(
    () => {
      window.addEventListener('scroll', handleScroll, { passive: true });

      return () => window.removeEventListener('scroll', handleScroll);
    },
    // we can disable this lint rule, we only want it to run once..
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleScroll = (evt: any) => {
    setIsScrolled(window.scrollY > 150);
  };

  const handlePhoneLinkClick = useCallback(() => {
    sendDataToGTM({
      event: 'event',
      eventCategory: 'PhoneLink',
      eventAction: 'Click',
      eventValue: undefined,
      eventLabel: 'Header'
    });
  }, [sendDataToGTM]);

  const handleFacebookLinkClick = useCallback(() => {
    sendDataToGTM({
      event: 'event',
      eventCategory: 'FacebookLink',
      eventAction: 'Click',
      eventValue: undefined,
      eventLabel: 'Header'
    });
  }, [sendDataToGTM]);

  const menuItems = (
    <>
      <MenuItem to="/vozidla-na-predaj" selected={path.startsWith('/vozidla-na-predaj')}>
        Vozidlá na predaj
      </MenuItem>
      {/* <MenuItem to="/super-soco" selected={path.startsWith('/super-soco')}>
        Super SOCO
      </MenuItem> */}
      <MenuItem to="/financovanie" selected={path.startsWith('/financovanie')}>
        Financovanie
      </MenuItem>
      {/* <MenuItem
          to="/online-predaj-vozidiel"
          selected={path.startsWith('/online-predaj-vozidiel')}
        >
          Online predaj
        </MenuItem> */}
      <MenuItem to="/kontakt" selected={path.startsWith('/kontakt')}>
        Kontakt
      </MenuItem>
    </>
  );

  const userMenu = user
    ? [
        <ListItem key="heading" component="div" className={classes.userMenuHeading}>
          <ListItemText
            primary={user.displayName}
            secondary={user.email}
            classes={{ primary: classes.userName }}
          />
        </ListItem>,
        <Divider key="divider1" />,
        <UIMenuItem
          key="addCar"
          component={Link}
          to="/pridat-vozidlo"
          onClick={() => {
            handleClose();
            handleDrawerToggle();
          }}
        >
          Pridať vozidlo
        </UIMenuItem>,
        <UIMenuItem
          key="allCars"
          component={Link}
          to="/vozidla"
          onClick={() => {
            handleClose();
            handleDrawerToggle();
          }}
        >
          Všetky vozidlá
        </UIMenuItem>,
        <Divider key="divider2" />,
        <UIMenuItem
          key="logout"
          onClick={() => {
            logOut();
            handleClose();
            handleDrawerToggle();
          }}
        >
          Odhlásiť sa
        </UIMenuItem>,
        <Divider key="divider3" />,
        <ListItem key="version" dense>
          <Typography variant="caption" color="textSecondary">
            Verzia: {packageJson.version}
            {process.env.REACT_APP_BUILD_NUMBER && `+${process.env.REACT_APP_BUILD_NUMBER}`}
            {process.env.REACT_APP_BUILD_DATE && ` (${process.env.REACT_APP_BUILD_DATE})`}
          </Typography>
        </ListItem>
      ]
    : null;

  const phone = (
    <>
      <Typography variant="caption">Volajte od 8:00 do 17:00</Typography>

      <div className={classes.phone}>
        <PhonetIcon color="primary" />

        <UILink variant="h6" component="a" href="tel:+421940509689" onClick={handlePhoneLinkClick}>
          +421 940 509 689
        </UILink>
      </div>
    </>
  );

  return (
    <AppBar
      position="fixed"
      color="inherit"
      className={classnames(classes.root, isScrolled ? classes.scrolled : '')}
    >
      <ContentWrap wider>
        <Toolbar className={classes.toolbar}>
          <Link to="/">
            <img src={logo} alt="EDENbazar logo" className={classes.logo} width="320" height="41" />
          </Link>

          <div className={classes.menuWrap}>
            <Hidden smDown>
              <nav className={classes.menu}>{menuItems}</nav>
            </Hidden>

            <Hidden mdDown>
              <div className={classes.phoneWrap}>{phone}</div>
            </Hidden>
            <Hidden mdUp>
              <Hidden xsDown>
                <div className={classes.phoneWrap}>{phone}</div>
              </Hidden>
            </Hidden>

            <Hidden mdUp>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>

              <Drawer
                variant="temporary"
                anchor="right"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper
                }}
              >
                <div className={classes.phoneWrapInDrawer}>{phone}</div>

                <Divider />

                <nav className={classes.drawerMenu}>
                  <List>
                    <ListItem
                      button
                      component={Link}
                      to="/"
                      onClick={handleDrawerToggle}
                      selected={path === '/'}
                    >
                      <ListItemIcon>
                        <HometIcon />
                      </ListItemIcon>
                      <ListItemText primary="Úvod" className={classes.mobileMenuItemText} />
                    </ListItem>

                    <ListItem
                      button
                      component={Link}
                      to="/vozidla-na-predaj"
                      onClick={handleDrawerToggle}
                      selected={path.startsWith('/vozidla-na-predaj')}
                    >
                      <ListItemIcon>
                        <CarIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="Vozidlá na predaj"
                        className={classes.mobileMenuItemText}
                      />
                    </ListItem>

                    <ListItem
                      button
                      component={Link}
                      to="/super-soco"
                      onClick={handleDrawerToggle}
                      selected={path.startsWith('/super-soco')}
                    >
                      <ListItemIcon>
                        <SuperSocoIcon />
                      </ListItemIcon>
                      <ListItemText primary="Super SOCO" className={classes.mobileMenuItemText} />
                    </ListItem>

                    <ListItem
                      button
                      component={Link}
                      to="/financovanie"
                      onClick={handleDrawerToggle}
                      selected={path.startsWith('/financovanie')}
                    >
                      <ListItemIcon>
                        <FundingIcon />
                      </ListItemIcon>
                      <ListItemText primary="Financovanie" className={classes.mobileMenuItemText} />
                    </ListItem>

                    {/* <ListItem
                        button
                        component={Link}
                        to="/online-predaj-vozidiel"
                        onClick={handleDrawerToggle}
                        selected={path.startsWith('/online-predaj-vozidiel')}
                      >
                        <ListItemIcon>
                          <OnlineIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary="Online predaj"
                          className={classes.mobileMenuItemText}
                        />
                      </ListItem> */}

                    <ListItem
                      button
                      component={Link}
                      to="/kontakt"
                      onClick={handleDrawerToggle}
                      selected={path.startsWith('/kontakt')}
                    >
                      <ListItemIcon>
                        <ContactIcon />
                      </ListItemIcon>
                      <ListItemText primary="Kontakt" className={classes.mobileMenuItemText} />
                    </ListItem>
                  </List>
                </nav>

                <div className={classes.facebook}>
                  <img
                    src={facebookLogo}
                    alt="EDENbazar stránka na Facebooku"
                    width="24"
                    height="24"
                  />

                  <UILink
                    variant="subtitle1"
                    component="a"
                    href="https://www.facebook.com/edenbazar.sk"
                    target="_blank"
                    rel="noreferrer"
                    onClick={handleFacebookLinkClick}
                  >
                    @edenbazar.sk
                  </UILink>
                </div>

                {userMenu ? (
                  <>
                    <Divider />
                    {userMenu}
                  </>
                ) : (
                  <iframe
                    title="Google Maps"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2660.7754312863035!2d17.18172125147497!3d48.17240925661093!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476c8fb07c0c3db5%3A0xabc5a3ab24d8104a!2sEDENbazar!5e0!3m2!1sen!2ssk!4v1556721446783!5m2!1sen!2ssk"
                    allowFullScreen
                    className={classes.map}
                  />
                )}
              </Drawer>
            </Hidden>

            <Hidden smDown>
              {user ? (
                <>
                  <IconButton
                    aria-owns={open ? 'menu-user' : undefined}
                    aria-haspopup="true"
                    onClick={handleUserMenu}
                    color="inherit"
                    className={classes.userMenu}
                  >
                    <AccountIcon />
                  </IconButton>

                  <Menu
                    id="menu-user"
                    anchorEl={menuAnchorEl}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                    open={open}
                    onClose={handleClose}
                  >
                    {userMenu}
                  </Menu>
                </>
              ) : null}
            </Hidden>
          </div>
        </Toolbar>
      </ContentWrap>
    </AppBar>
  );
};
