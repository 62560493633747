// core
import React, { memo, useMemo } from 'react';

// formik
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';

// material-ui
import InputAdornment from '@material-ui/core/InputAdornment';

export interface Props {
  disabled?: boolean;
  className?: string;
}

export const DiscountField = memo(function DiscountField({ disabled, className }: Props) {
  const inputProps = useMemo(
    () => ({
      endAdornment: <InputAdornment position="end">€</InputAdornment>
    }),
    []
  );

  return (
    <Field
      fullWidth
      name="discount"
      type="number"
      label="Zľava"
      component={TextField}
      margin="dense"
      InputProps={inputProps}
      className={className}
      disabled={disabled}
    />
  );
});
