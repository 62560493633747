// core
import React, { useCallback } from 'react';

// formik
import { useField } from 'formik';

// material
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// components
import { Slider, Props as SliderProps } from 'components/Slider';

export interface Props extends Omit<SliderProps, 'from' | 'to' | 'onChange'> {
  name: string;
  min: number;
  max: number;
  formatBoundaries?: (value: number) => string;
}

const useStyles = makeStyles((theme) => ({
  sliderWrap: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 20
  },
  slider: {
    marginLeft: 16,
    marginRight: 16
  },
  boundary: {
    width: 50,
    whiteSpace: 'nowrap',
    flexShrink: 0,
    '&:last-child': {
      width: 60
    }
  }
}));

export const CarsFilterSlider = ({ name, min, max, formatBoundaries, ...passingProps }: Props) => {
  const css = useStyles();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [fieldFrom, metaFrom, helpersFrom] = useField(name + 'From');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [fieldTo, metaTo, helpersTo] = useField(name + 'To');

  const handleChange = useCallback(
    (event, { from, to }) => {
      helpersFrom.setValue(from);
      helpersTo.setValue(to);
    },
    [helpersFrom, helpersTo]
  );

  return (
    <div className={css.sliderWrap}>
      <Typography variant="body2" component="div" className={css.boundary}>
        {typeof formatBoundaries === 'function' ? formatBoundaries(min) : min}
      </Typography>

      <Slider
        min={min}
        max={max}
        className={css.slider}
        disabled={min === max}
        {...passingProps}
        from={parseInt(fieldFrom.value) || min}
        to={parseInt(fieldTo.value) || max}
        onChange={handleChange}
      />

      <Typography variant="body2" component="div" className={css.boundary}>
        {typeof formatBoundaries === 'function' ? formatBoundaries(max) : max}
      </Typography>
    </div>
  );
};
