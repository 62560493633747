// core
import React, { useCallback } from 'react';

// formik
import { Formik } from 'formik';
import * as yup from 'yup';

// partials
import { CarsFilterForm } from './CarsFilterForm';

const schema = yup
  .object()
  .noUnknown()
  .shape({
    manufacturer: yup.string().ensure(),
    models: yup.array().of(yup.string()).nullable(),
    yearFrom: yup.string().ensure(),
    yearTo: yup.string().ensure(),
    mileageFrom: yup.string().ensure(),
    mileageTo: yup.string().ensure(),
    priceFrom: yup.string().ensure(),
    priceTo: yup.string().ensure(),
    fuel: yup.array().of(yup.string()).nullable(),
    gearbox: yup.array().of(yup.string()).nullable(),
    drive: yup.array().of(yup.string()).nullable()
  })
  .defined();

export type CarsFilterValues = yup.InferType<typeof schema>;

export interface Props {
  values: CarsFilterValues | null;
  onChange: (values: CarsFilterValues) => void;
}

export const CarsFilter = ({ values, onChange }: Props) => {
  const handleSubmit = useCallback(
    (values) => {
      onChange({
        ...values,
        yearFrom: parseInt(values.yearFrom),
        yearTo: parseInt(values.yearTo),
        mileageFrom: parseInt(values.mileageFrom),
        mileageTo: parseInt(values.mileageTo),
        priceFrom: parseInt(values.priceFrom),
        priceTo: parseInt(values.priceTo)
      });
    },
    [onChange]
  );

  return (
    <Formik
      enableReinitialize
      validationSchema={schema}
      initialValues={schema.cast(values)}
      onSubmit={handleSubmit}
    >
      <CarsFilterForm />
    </Formik>
  );
};
