// core
import React, { useEffect, useCallback } from 'react';

// apollo
import carsFilterBoundariesQuery from '../graphql/CarsFilterBoundariesQuery.graphql';
import {
  CarsFilterBoundariesQuery,
  CarsFilterBoundariesQueryVariables
} from '../graphql/CarsFilterBoundariesQuery';

// formik
import { useFormikContext, Form } from 'formik';

// components
import { FieldQueryWrap } from 'components/FieldQueryWrap';
import { formatPrice } from 'components/Price';

// material
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// partials
import { CarsFilterValues } from './CarsFilter';
import { CarsFilterSlider } from './CarsFilterSlider';
import { CarsFilterCheckboxes } from './CarsFilterCheckboxes';
import { CarsFilterManufacturer } from './CarsFilterManufacturer';
import { CarsFilterModel } from './CarsFilterModel';
import { useCarsFilter } from '../hooks/useCarsFilter';

const useStyles = makeStyles((theme) => ({
  manufacturerField: {
    marginBottom: 8
  },
  field: {
    marginTop: 24
  }
}));

export const CarsFilterForm = () => {
  const classes = useStyles();

  const { values, initialValues, submitForm } = useFormikContext<CarsFilterValues>();
  const { setManufacturers, setModels, setDriveValues } = useCarsFilter();

  useEffect(() => {
    if (values !== initialValues) {
      submitForm();
    }
  }, [values, initialValues, submitForm]);

  const handleManufacturerLoad = useCallback(
    (data) => {
      setManufacturers(data?.options || []);
    },
    [setManufacturers]
  );

  const handleModelLoad = useCallback(
    (data) => {
      setModels(data?.options || []);
    },
    [setModels]
  );

  const handleSetDriveValues = useCallback(
    (data) => {
      setDriveValues(data?.options || []);
    },
    [setDriveValues]
  );

  return (
    <Form>
      <CarsFilterManufacturer
        onLoad={handleManufacturerLoad}
        className={classes.manufacturerField}
      />

      {values.manufacturer && <CarsFilterModel onLoad={handleModelLoad} />}

      <div className={classes.field}>
        <Typography variant="subtitle2" component="h2">
          Rod výroby od
        </Typography>

        <FieldQueryWrap<CarsFilterBoundariesQuery, CarsFilterBoundariesQueryVariables>
          query={carsFilterBoundariesQuery}
          variables={{ id: 'year' }}
        >
          {(data) => {
            const { min, max } = data?.carsFilterBoundaries || { min: 0, max: 0 };
            return <CarsFilterSlider min={min} max={max} step={1} name="year" />;
          }}
        </FieldQueryWrap>
      </div>

      <div className={classes.field}>
        <Typography variant="subtitle2" component="h2">
          Najazdené km do
        </Typography>

        <FieldQueryWrap<CarsFilterBoundariesQuery, CarsFilterBoundariesQueryVariables>
          query={carsFilterBoundariesQuery}
          variables={{ id: 'mileage' }}
        >
          {(data) => {
            const { min, max } = data?.carsFilterBoundaries || { min: 0, max: 0 };
            const minRounded = Math.floor(min / 10000) * 10000;
            const maxRounded = Math.ceil(max / 10000) * 10000;
            return (
              <CarsFilterSlider
                min={minRounded}
                max={maxRounded}
                step={10000}
                name="mileage"
                formatValue={(value) => formatPrice(value, 0)}
                formatBoundaries={(value) => formatPrice(value, 0)}
              />
            );
          }}
        </FieldQueryWrap>
      </div>

      <div className={classes.field}>
        <Typography variant="subtitle2" component="h2">
          Cena do
        </Typography>

        <FieldQueryWrap<CarsFilterBoundariesQuery, CarsFilterBoundariesQueryVariables>
          query={carsFilterBoundariesQuery}
          variables={{ id: 'price' }}
        >
          {(data) => {
            const { min, max } = data?.carsFilterBoundaries || { min: 0, max: 0 };
            const minRounded = Math.floor(min / 1000) * 1000;
            const maxRounded = Math.ceil(max / 1000) * 1000;
            return (
              <CarsFilterSlider
                min={minRounded}
                max={maxRounded}
                step={1000}
                name="price"
                formatValue={(value) => formatPrice(value, 0, '€')}
                formatBoundaries={(value) => formatPrice(value, 0, '€')}
              />
            );
          }}
        </FieldQueryWrap>
      </div>

      <CarsFilterCheckboxes label="Palivo" name="fuel" className={classes.field} />

      <CarsFilterCheckboxes label="Prevodovka" name="gearbox" className={classes.field} />

      <CarsFilterCheckboxes
        label="Pohon"
        name="drive"
        className={classes.field}
        queryRes={handleSetDriveValues}
      />
    </Form>
  );
};
