// core
import React, { useEffect } from 'react';

// apollo
import { CarFragment } from '../graphql/CarFragment';

// components
import { ContactForm } from '../../Contact/components/ContactForm';

// libraries
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

// material
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

// material icons
import CloseIcon from '@material-ui/icons/Close';

export interface Props {
  open: boolean;
  car?: CarFragment;
  handleClose: () => void;
  monthlyPayment?: number | null;
}

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1)
  },
  content: {
    paddingBottom: theme.spacing(2)
  }
}));

export const SendRequestDialog = ({ car, monthlyPayment, open, handleClose }: Props) => {
  const classes = useStyles();
  const sendDataToGTM = useGTMDispatch();

  useEffect(
    () => {
      if (open) {
        sendDataToGTM({
          event: 'modalview',
          modalName: `send-request${car ? `/${car.slug}` : ''}`
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [open]
  );

  return (
    <Dialog open={open} scroll="body" onClose={handleClose} aria-labelledby="simple-dialog-title">
      <DialogTitle disableTypography id="simple-dialog-title">
        <Typography variant="h6">Dopytový formulár</Typography>

        <IconButton aria-label="Close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent className={classes.content}>
        <ContactForm car={car} onSubmit={handleClose} monthlyPayment={monthlyPayment} />
      </DialogContent>
    </Dialog>
  );
};
