// core
import React, { ReactNode } from 'react';

// libaries
import ReactMarkdown from 'react-markdown';

// material
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

export interface Props {
  source: string;
}

export interface ParagraphProps {
  children: ReactNode;
}

const Paragraph = ({ children }: ParagraphProps) => {
  return (
    <Typography variant="body1" gutterBottom>
      {children}
    </Typography>
  );
};

export interface HeadingProps {
  children: ReactNode;
  level: 1 | 2;
}

const Heading = ({ children, level }: HeadingProps) => {
  const classes = useStyles();

  return (
    <Typography
      variant={`h${Math.min(level + 4, 6)}` as 'h5' | 'h6'}
      component={`h${level + 1}` as 'h2' | 'h3'}
      gutterBottom
      className={classes[`heading${level + 1}` as 'heading2' | 'heading3']}
    >
      {children}
    </Typography>
  );
};

const useStyles = makeStyles((theme) => ({
  heading2: {
    marginTop: 32
  },
  heading3: {
    marginTop: 16
  }
}));

export const MarkdownParser = ({ source }: Props) => {
  return (
    <ReactMarkdown
      source={source}
      renderers={{
        paragraph: Paragraph,
        heading: Heading
      }}
    />
  );
};
