// core
import React, { ReactNode } from 'react';

// libraries
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

// apollo
import { useMutation } from '@apollo/client';
import contactFormMutation from '../graphql/ContactFormMutation.graphql';
import { ContactFormMutation, ContactFormMutationVariables } from '../graphql/ContactFormMutation';

// formik
import { Formik, Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';

// material
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';

// material icons
import DirectionsCarIcon from '@material-ui/icons/DirectionsCarOutlined';

// components
import { useSnackbar } from 'components/Snackbar';
import { FormFieldsGrid } from 'components/FormFieldsGrid';

// types
import { CarFragment } from '../../Car/graphql/CarFragment';

export interface Props {
  car?: CarFragment;
  textPlaceholder?: ReactNode;
  onSubmit?: () => void;
  monthlyPayment?: number | null;
}

const useStyles = makeStyles((theme) => ({
  carTitle: {
    marginBottom: theme.spacing(2),
    '& svg': {
      color: theme.palette.primary.main + ' !important'
    }
  },
  buttonWrap: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      display: 'block'
    }
  },
  submitButton: {
    minWidth: 150,
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  recaptchaTerms: {
    display: 'inline-block',
    marginLeft: theme.spacing(4),
    '& > a': {
      marginLeft: theme.spacing(1)
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2),
      marginLeft: 0
    }
  }
}));

export const ContactForm = ({ car, textPlaceholder, onSubmit, monthlyPayment }: Props) => {
  const classes = useStyles();
  const { pushSnack } = useSnackbar();
  const sendDataToGTM = useGTMDispatch();

  const [sendContactForm] = useMutation<ContactFormMutation, ContactFormMutationVariables>(
    contactFormMutation
  );

  const defaults = {
    name: '',
    email: '',
    phone: '',
    text: ''
  };

  const { executeRecaptcha } = useGoogleReCaptcha();

  return (
    <>
      {car ? (
        <div className={classes.carTitle}>
          <Input
            disabled
            fullWidth
            startAdornment={
              <InputAdornment position="start">
                <DirectionsCarIcon />
              </InputAdornment>
            }
            value={car.fullTitle}
          />

          {monthlyPayment && (
            <Typography variant="caption" color="textSecondary">
              Vaše od: {monthlyPayment.toFixed(2)}&euro; mesačne
            </Typography>
          )}
        </div>
      ) : null}

      <Formik
        initialValues={defaults}
        validate={(values) => {
          const errors: { [key: string]: string } = {};

          if (!values.name) {
            errors.name = 'Zadajte Váše meno';
          }

          if (!values.email) {
            errors.email = 'Zadajte Vášu e-mailovú adresu';
          } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = 'Zadaná hodnota nie je e-mail';
          }

          if (!values.phone) {
            errors.phone = 'Zadajte Váš telefónny kontakt';
          } else if (!/^[0-9 +/]{9,15}$/i.test(values.phone)) {
            errors.phone = 'Zadaná hodnota nie je telefón';
          }

          if (!values.text) {
            errors.text = 'Zadajte text dopytu';
          }

          return errors;
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const data = Object.assign(
            {},
            values,
            car
              ? {
                  car: car.id,
                  monthlyPayment
                }
              : {}
          );

          // @ts-ignore
          (executeRecaptcha ? executeRecaptcha() : Promise.resolve(null)).then(
            (token: string | null) =>
              sendContactForm({
                variables: Object.assign(data, { recaptcha: token })
              })
                .then(({ data }) => {
                  setSubmitting(false);
                  resetForm();
                  pushSnack({
                    message: 'Dopyt bol úspešne odoslaný',
                    type: 'success'
                  });
                  sendDataToGTM({
                    event: 'event',
                    eventCategory: 'Form',
                    eventAction: 'Request sent',
                    eventValue: car ? Math.round(car.price * 0.25) : 0,
                    eventLabel: car ? car.slug : 'Contact'
                  });

                  if (typeof onSubmit === 'function') {
                    onSubmit();
                  }
                })
                .catch((e) => {
                  setSubmitting(false);
                  console.error(e);
                  pushSnack({
                    message: 'Dopyt sa nepodarilo odoslať. Skúste to prosím znova.',
                    type: 'error'
                  });
                })
          );
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <FormFieldsGrid>
              <Field
                name="name"
                type="text"
                label="Vaše meno"
                variant="filled"
                component={TextField}
                margin="dense"
                fullWidth
              />

              <Field
                name="email"
                type="email"
                label="Váš e-mail"
                variant="filled"
                component={TextField}
                margin="dense"
                fullWidth
              />

              <Field
                name="phone"
                type="phone"
                label="Váš telefón"
                variant="filled"
                component={TextField}
                margin="dense"
                fullWidth
              />
            </FormFieldsGrid>

            <Field
              name="text"
              type="text"
              margin="dense"
              variant="filled"
              component={TextField}
              rows={3}
              placeholder={textPlaceholder || 'Dopyt na vozidlo, o ktoré máte záujem ...'}
              multiline
              fullWidth
            />

            <div className={classes.buttonWrap}>
              <Button
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                onClick={submitForm}
                className={classes.submitButton}
              >
                {isSubmitting ? <CircularProgress size={24} /> : 'Odoslať dopyt'}
              </Button>

              <Typography
                variant="caption"
                color="textSecondary"
                className={classes.recaptchaTerms}
              >
                Tento formulár je chránený testom reCAPTCHA.
                <Link
                  href="https://policies.google.com/privacy?hl=sk"
                  target="_blank"
                  rel="noreferrer"
                >
                  Ochrana súkromia
                </Link>
                <Link
                  href="https://policies.google.com/terms?hl=sk"
                  target="_blank"
                  rel="noreferrer"
                >
                  Zmluvné podmienky
                </Link>
              </Typography>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
