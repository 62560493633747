// core
import React, { useCallback } from 'react';

// partials
import { AccessoriesFields } from './AccessoriesFields';
import { HighlightField } from './HighlightField';

export interface Props {
  isMotorbike: boolean;
}

export const AccessoriesFieldsOther = ({ isMotorbike }: Props) => {
  const handleAppendFields = useCallback((field) => {
    return [<HighlightField key="highlight" name="highlights" value={`o${field.value}`} />];
  }, []);

  return (
    <AccessoriesFields
      name="otherEquipment"
      appendFields={handleAppendFields}
      isMotorbike={isMotorbike}
    />
  );
};
