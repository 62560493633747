// core
import React, { memo, useMemo } from 'react';

// formik
import { FastField as Field } from 'formik';
import { TextField } from 'formik-material-ui';

// material-ui
import InputAdornment from '@material-ui/core/InputAdornment';

export interface Props {
  name: string;
  label: string;
}

export const ConsumptionField = memo(function ConsumptionField({ name, label }: Props) {
  const inputProps = useMemo(
    () => ({
      endAdornment: <InputAdornment position="end">l/100km</InputAdornment>,
      inputProps: {
        min: 0
      }
    }),
    []
  );

  return (
    <Field
      name={name}
      type="number"
      label={label}
      margin="dense"
      component={TextField}
      InputProps={inputProps}
      fullWidth
    />
  );
});
