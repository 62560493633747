// core
import React, { useEffect, ReactElement } from 'react';

// apollo
import { useQuery } from '@apollo/client';

// components
import { LoadingDiv } from 'components/LoadingDiv';

// libraries
import classnames from 'classnames';

// material
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

export interface Props<T, V> {
  query: any;
  variables?: V;
  children: (data: T | undefined) => ReactElement<any>;
  onLoad?: (data: T) => void;
  classes?: {
    loadingDiv?: string;
  };
}

const useStyles = makeStyles((theme) => ({
  loadingDiv: {} // can be overwritten in som cases
}));

export const FieldQueryWrap = <T, V>({
  query,
  variables,
  children,
  onLoad,
  classes = {}
}: Props<T, V>) => {
  const css = useStyles();

  const { data, error, loading } = useQuery<T, V>(query, { variables });

  useEffect(() => {
    if (data && typeof onLoad === 'function') {
      onLoad(data);
    }
  }, [data, onLoad]);

  if (error) {
    console.log(error);
    global.bugsnagClient.notify(error);
  }

  return loading ? (
    <LoadingDiv classes={{ root: classnames(css.loadingDiv, classes.loadingDiv) }} />
  ) : error ? (
    <Typography variant="caption" color="error">
      Chyba v spojení so serverom. Skúste to prosím znova.
    </Typography>
  ) : (
    children(data)
  );
};
