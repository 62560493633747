// core
import React, { useRef, useState, useCallback } from 'react';

// formik
import { useField, useFormikContext } from 'formik';

// apollo
import { CarDetailsFormFragment } from '../graphql/CarDetailsFormFragment';

// libraries
import arrayMove from 'array-move';

// material-ui
import MuiTextField from '@material-ui/core/TextField';

// partials
import { CarDetailsFormValues } from './CarDetailsForm';
import { CarAttachments, Attachment } from './CarAttachments';

export interface Props {
  car: CarDetailsFormFragment | null;
}

export const CarDetailsFormAttachments = ({ car }: Props) => {
  const uploadInput = useRef<HTMLInputElement | null>(null);

  const { isSubmitting } = useFormikContext<CarDetailsFormValues>();
  const [field, meta, { setValue }] = useField<CarDetailsFormValues>('attachments');

  const [attachments, setAttachments] = useState<Attachment[]>(
    car?.attachments
      ? car.attachments.map(({ id, name, url, mimetype }) => ({
          id,
          name,
          url,
          mimetype
        }))
      : []
  );

  const handleRemoveToogle = useCallback(
    (id) =>
      setValue(
        ~field.value.indexOf(id)
          ? field.value.filter((d: string) => d !== id)
          : attachments.map(({ id }) => id).filter((i) => i === id || field.value.includes(i))
      ),
    [field.value, setValue, attachments]
  );

  const handleSortEnd = useCallback(
    ({ oldIndex, newIndex }) => {
      setAttachments((prevImages) => arrayMove(prevImages, oldIndex, newIndex));
      setValue(arrayMove(field.value, oldIndex, newIndex));
    },
    [field.value, setValue]
  );

  const handleFileChange = useCallback(
    (event) => {
      setValue([...(field.value || []), ...(event.currentTarget.files || [])]);
    },
    [field.value, setValue]
  );

  return (
    <>
      <MuiTextField
        type="file"
        margin="dense"
        inputProps={{ ref: uploadInput, multiple: true }}
        onChange={handleFileChange}
        error={!!meta.error}
        helperText={meta.error}
      />

      {attachments && (
        <CarAttachments
          attachments={attachments}
          idsToSave={field.value || []}
          disabled={isSubmitting}
          onRemoveToogle={handleRemoveToogle}
          onSortEnd={handleSortEnd}
        />
      )}
    </>
  );
};
