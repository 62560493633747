// core
import React, { ReactNode } from 'react';

// material
import Alert, { AlertProps } from '@material-ui/lab/Alert';

export interface Props extends AlertProps {
  children: ReactNode;
}

export const ErrorMessage = ({ children, ...passingProps }: Props) => {
  return (
    <Alert severity="error" variant="outlined" {...passingProps}>
      {children}
    </Alert>
  );
};
