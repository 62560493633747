// core
import React, { useCallback } from 'react';

// libraries
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

// assets
import logo from '../../images/EDENbazar-logo-white.svg';
import facebookLogo from '../../images/facebook-logo.svg';

// components
import { ContentWrap } from 'components/ContentWrap';

// libraries
import { Link } from 'react-router-dom';

// material
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import UILink from '@material-ui/core/Link';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

// material icons
import PhoneIcon from '@material-ui/icons/PhoneOutlined';
import EmailIcon from '@material-ui/icons/EmailOutlined';

// partials
import { FooterColumn } from './components/FooterColumn';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#333',
    paddingTop: 64,
    paddingBottom: 32,
    fontSize: '0.9em',
    [theme.breakpoints.down('xs')]: {
      paddingTop: 32
    },
    '& > div > div': {
      position: 'relative'
    }
  },
  addressColumn: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: 16
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: 0
    }
  },
  logo: {
    display: 'block',
    width: 'auto',
    height: 24,
    marginBottom: 24,
    [theme.breakpoints.down('sm')]: {
      marginTop: 24,
      marginRight: 8,
      float: 'right'
    }
  },
  highlight: {
    color: theme.palette.grey[50],
    fontSize: '1.1em',
    fontWeight: 'bold'
  },
  address: {
    marginTop: 0,
    lineHeight: 1.2
  },
  menuColumn: {
    '& > div': {
      display: 'flex',
      // justifyContent: 'space-between',
      justifyContent: 'space-around',
      marginRight: 96
    },
    [theme.breakpoints.down('md')]: {
      '& > div': {
        marginRight: 64
      }
    },
    [theme.breakpoints.down('sm')]: {
      '& > div': {
        marginRight: 32
      }
    },
    [theme.breakpoints.down('xs')]: {
      '& > div': {
        display: 'block',
        marginLeft: -2 * theme.spacing(1),
        marginRight: -2 * theme.spacing(1),
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        borderBottomColor: theme.palette.grey[800]
      }
    }
  },
  noBorder: {
    border: 'none !important'
  },
  contactColumn: {
    [theme.breakpoints.down('xs')]: {
      marginTop: 24
    }
  },
  contactRowWithIcon: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    marginRight: 8
  },
  copyright: {
    display: 'inline-block',
    marginTop: 48,
    maxWidth: '60%',
    color: theme.palette.grey[500],
    '& > a': {
      marginLeft: 4
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2),
      maxWidth: '100%'
    }
  },
  facebook: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    bottom: 8,
    right: '5.4%',
    '& > img': {
      width: 32,
      height: 32,
      marginRight: 8
    },
    [theme.breakpoints.down('md')]: {
      right: '12.3%'
    },
    [theme.breakpoints.down('sm')]: {
      right: '15.6%'
    },
    [theme.breakpoints.down('xs')]: {
      position: 'static',
      '& > img': {
        width: 24,
        height: 24
      }
    }
  }
}));

export const Footer = () => {
  const sendDataToGTM = useGTMDispatch();

  const handlePhoneLinkClick = useCallback(() => {
    sendDataToGTM({
      event: 'event',
      eventCategory: 'PhoneLink',
      eventAction: 'Click',
      eventValue: undefined,
      eventLabel: 'Footer'
    });
  }, [sendDataToGTM]);

  const handleEmailLinkClick = useCallback(() => {
    sendDataToGTM({
      event: 'event',
      eventCategory: 'EmailLink',
      eventAction: 'Click',
      eventValue: undefined,
      eventLabel: 'Footer'
    });
  }, [sendDataToGTM]);

  const handleFacebookLinkClick = useCallback(() => {
    sendDataToGTM({
      event: 'event',
      eventCategory: 'FacebookLink',
      eventAction: 'Click',
      eventValue: undefined,
      eventLabel: 'Footer'
    });
  }, [sendDataToGTM]);

  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <div className={classes.root}>
      <ContentWrap>
        <Grid container spacing={0}>
          <Grid item xs={12} md={3} className={classes.addressColumn}>
            <FooterColumn>
              <Link to="/">
                <img
                  src={logo}
                  alt="EDENbazar logo"
                  className={classes.logo}
                  width="320"
                  height="41"
                />
              </Link>

              <p className={classes.address}>
                <UILink variant="subtitle1" component="span" className={classes.highlight}>
                  EDENbazar
                </UILink>
                <br />
                Areál VHS
                <br />
                Pestovateľská 6
                <br />
                821 04, Bratislava
              </p>
            </FooterColumn>
          </Grid>

          <Grid item xs={12} sm={8} md={6} lg={7} className={classes.menuColumn}>
            <div>
              <div>
                <FooterColumn
                  heading="Bazár"
                  items={[
                    {
                      label: 'Vozidlá na predaj',
                      to: '/vozidla-na-predaj'
                    },
                    // {
                    //   label: 'Super SOCO',
                    //   to: '/super-soco'
                    // },
                    {
                      label: 'Financovanie',
                      to: '/financovanie'
                    }
                    /* {
                        label: 'Online predaj',
                        to: '/online-predaj-vozidiel'
                      } */
                  ]}
                  expandable={!isDesktop}
                />
              </div>

              {/* <div>
                  <FooterColumn
                    heading="Dôležité odkazy"
                    items={[
                      {
                        label: 'Obchodné podmienky',
                        to: '/obchodne-podmienky'
                      },
                      {
                        label: 'Ochrana súkromia',
                        to: '/ochrana-sukromia'
                      }
                    ]}
                    expandable={!isDesktop}
                  />
                </div> */}

              <div>
                <FooterColumn
                  heading="EDENbazar"
                  items={[
                    {
                      label: 'O nás',
                      to: '/o-nas'
                    },
                    {
                      label: 'Kontakt',
                      to: '/kontakt'
                    }
                  ]}
                  expandable={!isDesktop}
                />
              </div>
            </div>
          </Grid>

          <Grid item xs={12} sm={4} md={3} lg={2} className={classes.contactColumn}>
            <FooterColumn heading={isDesktop ? 'Rýchly kontakt' : null}>
              <div>Po-Pi: 8:00 - 17:00</div>

              <div className={classes.contactRowWithIcon}>
                <PhoneIcon className={classes.icon} />

                <UILink
                  variant="subtitle1"
                  component="a"
                  href="tel:+421940509689"
                  className={classes.highlight}
                  onClick={handlePhoneLinkClick}
                >
                  +421 940 509 689
                </UILink>
              </div>

              <div className={classes.contactRowWithIcon}>
                <EmailIcon className={classes.icon} />

                <UILink
                  variant="subtitle1"
                  component="a"
                  href="mailto:info@edenbazar.sk"
                  className={classes.highlight}
                  onClick={handleEmailLinkClick}
                >
                  info@edenbazar.sk
                </UILink>
              </div>

              <div className={classes.facebook}>
                <img
                  src={facebookLogo}
                  alt="EDENbazar stránka na Facebooku"
                  width="32"
                  height="32"
                />

                <UILink
                  variant="subtitle1"
                  component="a"
                  href="https://www.facebook.com/edenbazar.sk"
                  className={classes.highlight}
                  target="_blank"
                  rel="noreferrer"
                  onClick={handleFacebookLinkClick}
                >
                  @edenbazar.sk
                </UILink>
              </div>
            </FooterColumn>
          </Grid>
        </Grid>

        <Typography variant="caption" className={classes.copyright}>
          Copyright &copy; {new Date().getFullYear()}
          <UILink href="https://www.edencars.sk" color="primary">
            EDENcars.sk
          </UILink>
          <br />
          Tento web používa súbory cookie pre poskytovanie základnej funkcionality a pre zvýšenie
          zabezpečenia stánky. Prehliadaním webu vyjadrujete súhlas s ich používaním.
          <UILink component={Link} to="/cookies" color="inherit">
            Viac informácií
          </UILink>
        </Typography>
      </ContentWrap>
    </div>
  );
};
