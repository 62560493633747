// core
import React, { ReactNode } from 'react';

// libraries
import { Link } from 'react-router-dom';

// material
import { makeStyles } from '@material-ui/core/styles';
import Button, { ButtonProps } from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

export interface Props {
  buttons?: (ButtonProps & { label: ReactNode; to?: string })[];
  children: ReactNode;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: theme.borderColor,
    paddingBottom: 8,
    marginTop: 32,
    marginBottom: 24,
    '&:first-child': {
      marginTop: 0
    }
  },
  heading: {
    flex: 1
  },
  button: {
    marginLeft: 16
  }
}));

export const SidebarHeading = ({ buttons, children }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h6" component="h1" className={classes.heading}>
        {children}
      </Typography>

      {buttons
        ? buttons.map(({ label, ...buttonProps }, key) => (
            <Button
              key={key}
              variant="text"
              component={buttonProps.to ? Link : 'button'}
              color="primary"
              className={classes.button}
              size="small"
              {...buttonProps}
            >
              {label}
            </Button>
          ))
        : null}
    </div>
  );
};
