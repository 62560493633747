// core
import React, { useEffect, useCallback } from 'react';

// libraries
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

// assets
import imageFacebook from '../../images/EDENbazar-facebook.png';
import image1 from '../../images/about_edenbazar_1.png';
import image2 from '../../images/about_edenbazar_2.png';

// components
import { Breadcrumbs, renderBreadcrumbs } from 'components/Breadcrumbs';
import { ContentWrap } from 'components/ContentWrap';
import { PageHeading } from 'components/PageHeading';
import { Timeline } from 'components/Timeline';

// libraries
import { Helmet } from 'react-helmet';

// material
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

// material icons
import CarIcon from '@material-ui/icons/DirectionsCarOutlined';
import OfferIcon from '@material-ui/icons/EuroOutlined';
import ConfirmIcon from '@material-ui/icons/DoneAllOutlined';
import NoRiskIcon from '@material-ui/icons/VerifiedUserOutlined';
import PhoneCallIcon from '@material-ui/icons/PhoneCallbackOutlined';
import PhoneIcon from '@material-ui/icons/PhoneOutlined';
import EmailIcon from '@material-ui/icons/EmailOutlined';
import ShippingIcon from '@material-ui/icons/LocalShippingOutlined';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 64,
    paddingBottom: 64
  },
  heading: {
    marginTop: 64,
    marginBottom: 24,
    [theme.breakpoints.down('sm')]: {
      marginTop: 48,
      fontSize: '1.6em'
    }
  },
  imageWrap: {
    position: 'relative',
    textAlign: 'right',
    marginTop: 32,
    marginBottom: 64
  },
  image1: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '70%',
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      position: 'static',
      marginRight: 32
    }
  },
  image2: {
    position: 'absolute',
    top: 120,
    left: 90,
    width: '53%',
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      top: '40%',
      left: 'auto',
      right: '45%'
    }
  },
  contactWrap: {
    marginTop: 64
  },
  contact: {
    marginTop: 32,
    '& > div': {
      whiteSpace: 'nowrap',
      marginBottom: 8,
      display: 'inline-flex',
      alignItems: 'center',
      '& > svg': {
        marginRight: 8
      }
    },
    '& > div:first-child': {
      marginRight: 32
    }
  }
}));

export const OnlineSale = () => {
  const sendDataToGTM = useGTMDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handlePhoneLinkClick = useCallback(() => {
    sendDataToGTM({
      event: 'event',
      eventCategory: 'PhoneLink',
      eventAction: 'Click',
      eventValue: undefined,
      eventLabel: 'OnlineSale'
    });
  }, [sendDataToGTM]);

  const handleEmailLinkClick = useCallback(() => {
    sendDataToGTM({
      event: 'event',
      eventCategory: 'EmailLink',
      eventAction: 'Click',
      eventValue: undefined,
      eventLabel: 'OnlineSale'
    });
  }, [sendDataToGTM]);

  const classes = useStyles();

  const breadcrumbs = [
    {
      label: 'Online predaj'
    }
  ];

  return (
    <>
      <Helmet>
        <title>Online predaj vozidiel - EDENbazar</title>
        <meta
          name="description"
          content="Všetky zmluvy vieme robiť online, bez stretnutia. Vozidlo Vám privezieme až domov."
        />
        <meta property="og:url" content={`${global.baseUrl}/financovanie`} />
        <meta property="og:title" content="EDENbazar - Online predaj vozidiel" />
        <meta
          property="og:description"
          content="Všetky zmluvy vieme robiť online, bez stretnutia. Vozidlo Vám privezieme až domov."
        />
        <meta
          property="og:image"
          content={`${global.baseUrl.replace('https', 'http')}${imageFacebook}`}
        />
        <meta property="og:image:secure_url" content={`${global.baseUrl}${imageFacebook}`} />
      </Helmet>

      <Breadcrumbs items={breadcrumbs}>
        {(items, c) => <ContentWrap>{renderBreadcrumbs(items, c)}</ContentWrap>}
      </Breadcrumbs>

      <ContentWrap className={classes.root}>
        <PageHeading>Online predaj vozidiel</PageHeading>

        <Grid container spacing={0}>
          <Grid item xs={12} md={6}>
            <Timeline
              items={[
                {
                  icon: <CarIcon />,
                  title: 'vyberiete si vozidlo',
                  subtitle: 'z našej ponuky'
                },
                {
                  icon: <ConfirmIcon />,
                  title: 'stlačte tlačidlo “Dopytovať vozidlo”',
                  subtitle: 'a pošlite nám nezáväznú rezerváciu'
                },
                {
                  icon: <PhoneCallIcon />,
                  title: 'následne Vás bude kontaktovať náš pracovník',
                  subtitle: 'a v prípade postreby Vám pošle podrobnejšie foto/video vozidla'
                },
                {
                  icon: <OfferIcon />,
                  title: 'zabezpečíme financovanie aj poistenie vozidla',
                  subtitle: 'podľa Vašich požiadaviek'
                },
                {
                  icon: <ShippingIcon />,
                  title: 'auto vám po kúpe privezieme až k vám domov',
                  subtitle: '(podľa dohody a dostupnosti)'
                },
                {
                  icon: <NoRiskIcon />,
                  title: 'nič neriskujete',
                  subtitle: 'máte možnosť vozidlo vrátiť'
                }
              ]}
            />
          </Grid>
          <Grid item xs={12} md={6} className={classes.imageWrap}>
            <img src={image1} alt="EDENbazar" className={classes.image1} width="500" height="350" />
            <img src={image2} alt="EDENbazar" className={classes.image2} width="290" height="187" />
          </Grid>
        </Grid>

        <Typography variant="h4" component="h2" className={classes.heading}>
          Ako to funguje?
        </Typography>

        <Typography variant="subtitle1" component="p">
          S nami opäť nič neriskujete. Netreba sa toho báť, je to ako v iných e-shopoch. Vyberiete
          si auto, zadáte dopyt, dohodneme sa na financovaní a poistení. Všetky zmluvy vieme robiť
          online, bez stretnutia. Máte 12 mesačnú garanciu na skryté vady na aute a ak sa vám nebude
          auto pozdávať, máte možnosť ho vrátiť.
        </Typography>

        <div className={classes.contactWrap}>
          <Typography variant="body2" component="p" gutterBottom>
            V prípade akýchkoľvek otázok nás kontaktujte.
          </Typography>

          <div className={classes.contact}>
            <Typography variant="body1" component="div">
              <PhoneIcon />
              <Link href="tel:+421940509689" color="textPrimary" onClick={handlePhoneLinkClick}>
                +421 940 509 689
              </Link>
            </Typography>

            <Typography variant="body1" component="div">
              <EmailIcon />
              <Link href="mailto:info@edenbazar.sk" onClick={handleEmailLinkClick}>
                info@edenbazar.sk
              </Link>
            </Typography>
          </div>
        </div>
      </ContentWrap>
    </>
  );
};
