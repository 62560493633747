// core
import React, { useCallback, CSSProperties } from 'react';

// libraries
import classnames from 'classnames';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

// material
import { makeStyles } from '@material-ui/core/styles';
import ImageList from '@material-ui/core/ImageList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import { yellow } from '@material-ui/core/colors';

// material icons
import FullStarIcon from '@material-ui/icons/Star';
import FullRemoveIcon from '@material-ui/icons/DeleteForeverOutlined';
import EmptyRemoveIcon from '@material-ui/icons/DeleteOutlined';

export type Image = {
  id: string;
  thumb: string;
};

export interface Props {
  images: Image[];
  idsToSave: string[];
  title: string;
  disabled: boolean;
  onImageRemoveToogle: (id: string) => void;
  onSortEnd: (data: { oldIndex: number; newIndex: number }) => void;
}

export type ItemClasses = {
  deleted: string;
  tile: string;
  highlightIcon: string;
  titleBar: string;
  titleBarIcons: string;
  image: string;
};

export interface ItemProps {
  index: number;
  value: string;
  url: string;
  isDeleted: boolean;
  title: string;
  disabled: boolean;
  onImageRemoveToogle: (value: string) => void;
  classes: ItemClasses;
  style?: CSSProperties;
}

export interface ContainerProps {
  items: Image[];
  idsToSave: string[];
  title: string;
  disabled: boolean;
  onImageRemoveToogle: (value: string) => void;
  classes: ItemClasses;
}

const useStyles = makeStyles((theme) => ({
  tile: {
    listStyle: 'none'
  },
  deleted: {
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(255, 255, 255, 0.8)'
    }
  },
  highlightIcon: {
    color: yellow[800]
  },
  titleBar: {
    background: 'rgba(0,0,0,0.5)',
    zIndex: 10,
    color: '#fff'
  },
  titleBarIcons: {
    display: 'flex',
    alignItems: 'center'
  },
  image: {
    width: '100%',
    height: 'auto'
  }
}));

const SortableItem = SortableElement(
  ({
    index,
    value,
    url,
    isDeleted,
    title,
    disabled,
    onImageRemoveToogle,
    classes,
    style
  }: ItemProps) => {
    const handleDeleteToogle = useCallback(() => {
      onImageRemoveToogle(value);
    }, [onImageRemoveToogle, value]);

    return (
      <GridListTile
        key={index}
        style={style}
        className={classnames(classes.tile, { [classes.deleted]: isDeleted })}
      >
        <img
          src={`${global.cdnUrl}/fotografia/${url}`}
          alt={title}
          title={title}
          width="600"
          height="450"
          className={classes.image}
        />
        <GridListTileBar
          titlePosition="top"
          actionIcon={
            <>
              {!isDeleted && index === 0 ? (
                <FullStarIcon className={classes.highlightIcon} />
              ) : null}

              <IconButton onClick={handleDeleteToogle} color="inherit" disabled={disabled}>
                {isDeleted ? <FullRemoveIcon /> : <EmptyRemoveIcon />}
              </IconButton>
            </>
          }
          actionPosition="right"
          className={classes.titleBar}
          classes={{
            actionIcon: classes.titleBarIcons
          }}
        />
      </GridListTile>
    );
  }
);

const SortableList = SortableContainer(
  ({ items, idsToSave, title, disabled, onImageRemoveToogle, classes }: ContainerProps) => {
    return (
      <ImageList cols={5}>
        {items.map((item, key) => {
          const isDeleted = !~idsToSave.indexOf(item.id);

          return (
            <SortableItem
              key={`item-${key}`}
              index={key}
              value={item.id}
              url={item.thumb}
              isDeleted={isDeleted}
              title={title}
              disabled={disabled}
              onImageRemoveToogle={onImageRemoveToogle}
              classes={classes}
            />
          );
        })}
      </ImageList>
    );
  }
);

export const CarImagesSortable = ({ images, onSortEnd, ...passingProps }: Props) => {
  const classes = useStyles();

  return (
    <SortableList
      axis="xy"
      classes={classes}
      items={images}
      shouldCancelStart={(event) => (event.target as HTMLElement).tagName.toLowerCase() !== 'img'}
      onSortEnd={onSortEnd}
      {...passingProps}
    />
  );
};
