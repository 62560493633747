// core
import React from 'react';

// components
import { formatNumber } from 'components/NumberString';

export interface Props {
  currency?: string;
  decimals?: number;
  children: number | string;
}

export const formatPrice = (
  price: number | string,
  decimals?: number,
  currency?: string
): string => {
  const formattedValue = formatNumber(price, decimals);

  return `${formattedValue}${currency ? ` ${currency}` : ''}`;
};

export const Price = ({ currency, decimals, children }: Props) => {
  return (
    <>
      {formatPrice(children, decimals)}
      {currency && (
        <>
          &nbsp;
          {currency}
        </>
      )}
    </>
  );
};
