// core
import React, { useCallback, forwardRef, Ref } from 'react';

// formik
import { useField, useFormikContext } from 'formik';

// apollo
import carsFilterOptionsQuery from '../graphql/CarsFilterOptionsQuery.graphql';
import { CarsFilterOptionsQuery } from '../graphql/CarsFilterOptionsQuery';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';

// icons
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

// components
import { useLoadSelectOptions, LoadSelectOptionsComponent } from 'components/LoadSelectOptions';

// partials
import { CarsFilterValues } from './CarsFilter';

export interface Props {
  onLoad: (data: CarsFilterOptionsQuery) => void;
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: 12,
    color: theme.palette.primary.main
  }
}));

export const CarsFilterModel = forwardRef(function CarsFilterModel(
  { onLoad, className }: Props,
  ref: Ref<HTMLInputElement>
) {
  const css = useStyles();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, { setValue }] = useField('models');
  const { values } = useFormikContext<CarsFilterValues>();
  const selectedValues = field.value || [];

  const { loading, error, options } = useLoadSelectOptions({
    id: 'model',
    filter: { parentCategory: values.manufacturer || '' },
    query: carsFilterOptionsQuery,
    onLoad,
    sort: (a, b) => a.label.localeCompare(b.label)
  });

  const nameMap: { [key: string]: string } = options.reduce(
    (map, { value, label }) => ({ ...map, [value]: label }),
    {}
  );

  const handleChange = useCallback(
    (event) => {
      const value = event.target.value;
      setValue(value && value.length > 0 ? [...value] : null);
    },
    [setValue]
  );

  return (
    <LoadSelectOptionsComponent loading={loading} error={!!error} hasLabel={true}>
      <FormControl fullWidth variant="outlined" margin="dense">
        <InputLabel
          variant="outlined"
          shrink={selectedValues.length > 0}
          id="cars-filter-models-label"
        >
          Model
        </InputLabel>

        <Select
          fullWidth
          multiple
          variant="outlined"
          label="Model"
          labelId="cars-filter-models-label"
          renderValue={(selected) =>
            (selected as string[])
              .map((value) => nameMap[value])
              .filter(Boolean)
              .join(', ')
          }
          IconComponent={KeyboardArrowDownIcon}
          inputProps={{
            ref
          }}
          classes={{
            icon: css.icon
          }}
          {...field}
          value={selectedValues}
          onChange={handleChange}
        >
          {options.map(({ value, label }) => (
            <MenuItem dense key={value} value={value}>
              <Checkbox color="primary" checked={selectedValues.indexOf(value) > -1} />
              <ListItemText primary={label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </LoadSelectOptionsComponent>
  );
});
