// core
import React from 'react';

// libraries
import { Link } from 'react-router-dom';

// material
import { makeStyles } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import Tooltip from '@material-ui/core/Tooltip';
import MuiLink from '@material-ui/core/Link';

// material icons
import VerifiedIcon from '@material-ui/icons/VerifiedUser';
import CheckIcon from '@material-ui/icons/DoneOutlined';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: 100,
    height: 100,
    textAlign: 'right',
    overflow: 'hidden',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1),
      display: 'block',
      boxShadow: '0 0 50px 40px rgba(255, 255, 255, 0.4)',
      zIndex: 1
    }
  },
  icon: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    color: green[700],
    borderRadius: '50%',
    fontSize: '22px',
    zIndex: 2,
    position: 'relative'
  },
  checkIcon: {
    marginRight: theme.spacing(1),
    fontSize: '22px'
  },
  moreInfoLink: {
    color: green[900],
    fontSize: '10px'
  },
  badgeTooltip: {
    backgroundColor: green[500],
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
    fontSize: '12px'
  },
  badgeArrow: {
    color: green[500]
  }
}));

export interface Props {
  /**
   * Pass className to root
   */
  className?: string;
}

export const VerifiedBadge = ({ className }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Tooltip
        arrow
        classes={{
          tooltip: classes.badgeTooltip,
          arrow: classes.badgeArrow
        }}
        title={
          <>
            <CheckIcon className={classes.checkIcon} />
            <div>
              Preverené nezávislou firmou
              <br />
              <MuiLink
                component={Link}
                to="/o-nas#nezavisla-kontrola-vozidiel"
                className={classes.moreInfoLink}
              >
                Viac informácií
              </MuiLink>
            </div>
          </>
        }
        placement="right"
        interactive
        leaveDelay={300}
      >
        <VerifiedIcon fontSize="small" className={classes.icon} />
      </Tooltip>
    </div>
  );
};
