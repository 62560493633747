// core
import React, { ReactNode, ElementType } from 'react';

// libraries
import classnames from 'classnames';
import { Link } from 'react-router-dom';

// material
import Button, { ButtonProps } from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

export interface ButtonType extends ButtonProps {
  label: string;
  to?: string;
}

export interface Props {
  component?: ElementType<any>;
  buttons?: ButtonType[];
  className?: string;
  classes?: { [key: string]: string };
  children: ReactNode;
  subheading?: ReactNode;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'baseline',
    paddingBottom: theme.spacing(8)
  },
  headingWrap: {
    flex: 1
  },
  heading: {
    padding: 0,
    display: 'inline-block',
    fontSize: '2em',
    lineHeight: '1.2em',
    fontWeight: 'bold',
    paddingTop: theme.spacing(2),
    position: 'relative',
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      width: 22,
      height: 4,
      backgroundColor: theme.palette.primary.main
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.8em'
    }
  },
  subheading: {
    marginLeft: theme.spacing(1),
    fontSize: '2em',
    lineHeight: '1.2em',
    fontWeight: 'bold'
  },
  button: {
    marginLeft: 16
  }
}));

export const PageHeading = ({
  buttons,
  children,
  subheading,
  component,
  className,
  classes = {}
}: Props) => {
  const css = useStyles();

  return (
    <div className={classnames(css.root, classes.root, className)}>
      <div className={css.headingWrap}>
        <Typography
          variant="h1"
          component={component || 'h1'}
          className={classnames(css.heading, classes.heading)}
        >
          {children}
        </Typography>

        {subheading && (
          <Typography variant="h1" component="span" className={css.subheading}>
            {subheading}
          </Typography>
        )}
      </div>

      {buttons
        ? buttons.map(({ label, ...buttonProps }, key) => (
            <Button
              key={key}
              variant="contained"
              component={buttonProps.to ? Link : 'button'}
              color="primary"
              className={classnames(css.button, classes.button)}
              {...buttonProps}
            >
              {label}
            </Button>
          ))
        : null}
    </div>
  );
};
