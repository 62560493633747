// core
import React from 'react';

// apollo
import { Query } from '@apollo/client/react/components';
import categoriesFieldQuery from '../graphql/CategoriesFieldQuery.graphql';
import { CategoriesFieldQuery } from '../graphql/CategoriesFieldQuery';

// components
import { LoadingDiv } from 'components/LoadingDiv';

// formik
import { FastField as Field, useField } from 'formik';
import { TextField } from 'formik-material-ui';

// material
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import MuiCheckbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  loadingDiv: {
    height: 29,
    marginTop: 24,
    marginBottom: 0
  }
}));

export const CategoriesField = () => {
  const classes = useStyles();

  const [field] = useField('categories');

  return (
    <Query<CategoriesFieldQuery> query={categoriesFieldQuery}>
      {({ data, loading, error }) => {
        if (loading) {
          return <LoadingDiv classes={{ root: classes.loadingDiv }} />;
        }

        if (error) {
          console.error(error);
          global.bugsnagClient.notify(error);
          return (
            <MenuItem>
              <Typography variant="caption" color="error">
                Chyba v spojení so serverom. Skúste to prosím znova.
              </Typography>
            </MenuItem>
          );
        }

        return (
          <Field
            select
            label="Kategórie"
            margin="dense"
            component={TextField}
            SelectProps={{
              native: false
            }}
            InputProps={{
              inputProps: {
                multiple: true,
                shrink: field.value ? 'true' : 'false',
                renderValue: function CategoriesFieldValue(selected: string[]) {
                  return (
                    <ListItemText>
                      {(data?.categories || [])
                        .filter((c) => ~selected.indexOf(c.slug))
                        .map((c) => c.name)
                        .join(', ')}
                    </ListItemText>
                  );
                }
              }
            }}
            fullWidth
            {...field}
          >
            {(data?.categories || []).map((category) => (
              <MenuItem key={category.slug} value={category.slug}>
                <MuiCheckbox color="primary" checked={!!~field.value.indexOf(category.slug)} />
                <ListItemText primary={category.name} />
              </MenuItem>
            ))}
          </Field>
        );
      }}
    </Query>
  );
};
