// core
import React, { ReactNode } from 'react';

// apollo
import { ApolloError } from '@apollo/client';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// components
import { Loading } from 'components/Loading';

export interface Props {
  loading?: boolean;
  error?: ApolloError | undefined;
  children?: ReactNode;
}

const useStyles = makeStyles((theme) => ({
  loading: {
    paddingTop: 64,
    paddingBottom: 64
  },
  error: {
    textAlign: 'center',
    paddingTop: 64,
    paddingBottom: 64
  }
}));

export const GqlResponseHandler = ({ loading, error, children = null }: Props) => {
  const css = useStyles();

  if (loading) {
    return (
      <div className={css.loading}>
        <Loading />
      </div>
    );
  }

  if (error) {
    console.error(error);

    return (
      <div className={css.error}>
        <Typography variant="h2" component="h1" gutterBottom>
          Uuups...
        </Typography>

        <Typography variant="subtitle1" component="div">
          Nastala chyba v spojení so serverom.
          <br />
          Skúste to prosím znova.
        </Typography>
      </div>
    );
  }

  return <>{children}</>;
};
