// core
import React from 'react';

// libraries
import { Link } from 'react-router-dom';

// material
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import Badge from '@material-ui/core/Badge';

// apollo
import { useQuery } from '@apollo/client';
import discountOfWeekQuery from '../graphql/DiscountOfWeekQuery.graphql';
import { DiscountOfWeekQuery, DiscountOfWeekQueryVariables } from '../graphql/DiscountOfWeekQuery';

// hooks
import { Category } from '../hooks/useCategories';

// components
import { PageHeading } from 'components/PageHeading';
import { Price } from 'components/Price';
import { GqlResponseHandler } from 'components/GqlResponseHandler';

export interface Props {
  category: Category | null;
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(6)
  },
  heading: {
    paddingBottom: theme.spacing(2)
  },
  cardsWrap: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      overflowX: 'auto'
    }
  },
  card: {
    maxWidth: '33%',
    flex: 1,
    backgroundColor: theme.palette.secondary.main,
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      '&:last-child': {
        display: 'none'
      }
    },
    [theme.breakpoints.down('xs')]: {
      flex: 'unset',
      flexShrink: 0,
      '&:last-child': {
        display: 'block'
      }
    }
  },
  cardMedia: {
    width: '100%',
    height: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: 130,
      height: 100
    }
  },
  badge: {
    top: theme.spacing(0.5),
    left: theme.spacing(0.5),
    right: 'auto',
    transform: 'unset',
    fontWeight: 'bold'
  },
  price: {
    position: 'absolute',
    bottom: theme.spacing(0.5),
    right: theme.spacing(1),
    color: '#ffffff',
    textShadow: '0 0 2px ' + theme.palette.secondary.main
  }
}));

export const DiscountOfWeek = ({ category }: Props) => {
  const classes = useStyles();

  const { data, loading, error } = useQuery<DiscountOfWeekQuery, DiscountOfWeekQueryVariables>(
    discountOfWeekQuery,
    {
      variables: {
        category: category?.slug || null
      }
    }
  );

  const cars = data?.cars || [];

  return (
    <GqlResponseHandler loading={loading} error={error}>
      {cars?.length > 0 && (
        <div className={classes.root}>
          <PageHeading
            buttons={[
              {
                label: 'Zobraziť viac',
                to: '/vozidla-na-predaj/zlava-tyzdna',
                variant: 'text'
              }
            ]}
            className={classes.heading}
          >
            {category ? `Zľava týždňa v kategórií ${category.name}` : 'Zľava týždňa'}
          </PageHeading>

          <div className={classes.cardsWrap}>
            {cars.map((car) => (
              <Card key={car.id} className={classes.card}>
                <Badge
                  color="primary"
                  badgeContent={<>-{car.discount}&nbsp;&euro;</>}
                  classes={{ badge: classes.badge }}
                >
                  <CardActionArea
                    component={Link}
                    to={`/vozidla-na-predaj/${car.mainCategory && car.mainCategory.slug}/${
                      car.slug
                    }`}
                  >
                    <CardMedia
                      component="img"
                      src={`${global.cdnUrl}/fotografia/${car.thumbnail}`}
                      alt={car.fullTitle}
                      title={car.fullTitle}
                      className={classes.cardMedia}
                      width="600"
                      height="450"
                    />

                    <div className={classes.price}>
                      <Price currency="€" decimals={0}>
                        {car.price - car.discount}
                      </Price>
                    </div>
                  </CardActionArea>
                </Badge>
              </Card>
            ))}
          </div>
        </div>
      )}
    </GqlResponseHandler>
  );
};
