import React, { createContext, useState, useEffect, useCallback, useRef } from 'react';
import ExecutionEnvironment from 'exenv';

// libraries
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

import firebase from 'firebase/app';
import 'firebase/auth';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

// components
import { Header } from 'components/Header';
import { Footer } from 'components/Footer';
import { Popup } from 'components/Popup';
import { Snackbar } from 'components/Snackbar';

import { Home } from './pages/Home';
import { Cars } from './pages/Cars';
import { AllCars } from './pages/AllCars';
import { Car } from './pages/Car';
import { About } from './pages/About';
import { Funding } from './pages/Funding';
import { OnlineSale } from './pages/OnlineSale';
import { Contact } from './pages/Contact';
// import { SuperSoco } from './pages/SuperSoco';
import { Edit } from './pages/Edit';
import { Login } from './pages/Login';

import { StaticPages } from './pages/StaticPages';

import { Error404 } from './pages/Errors/404';

import reportWebVitals from './reportWebVitals';

export type User = firebase.User;

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 101
  }
}));

export const UserContext = createContext<User | null>(null);

export const AppRouter = () => {
  const classes = useStyles();
  const history = useHistory();
  const sendDataToGTM = useGTMDispatch();
  const initialUserId = useRef<string | null>(null);

  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    // assign handler to ser user from firebase
    if (ExecutionEnvironment.canUseDOM) {
      firebase.auth().onAuthStateChanged((user) => {
        setUser(user);
        initialUserId.current = user?.uid || null;
      });
    }
  }, []);

  // send initial page load event
  useEffect(() => {
    sendDataToGTM({ event: 'pageview', userId: initialUserId.current });
  }, [sendDataToGTM, initialUserId]);

  // send every history change as virtual pageview
  useEffect(() => {
    history.listen((location) => {
      sendDataToGTM({ event: 'pageview', userId: initialUserId.current });
    });
  }, [history, sendDataToGTM, initialUserId]);

  // report web vitals
  useEffect(() => {
    reportWebVitals(({ id, name, value }) => {
      sendDataToGTM({
        event: 'webVitals',
        eventCategory: 'Web Vitals',
        eventAction: name,
        eventValue: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
        eventLabel: id, // id unique to current page load
        userId: initialUserId.current
      });
    });
  }, [sendDataToGTM, initialUserId]);

  const handleLogout = useCallback(() => {
    firebase.auth().signOut();
  }, []);

  return (
    <>
      <CssBaseline />

      <UserContext.Provider value={user}>
        <Header user={user} logOut={handleLogout} />

        <div className={classes.root}>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/vozidla-na-predaj/:category/:slug" component={Car} />
            <Route path="/vozidla-na-predaj/:category?" component={Cars} />
            <Route path="/vozidla" component={AllCars} />
            <Route path="/pridat-vozidlo" component={Edit} />
            <Route path="/upravit-vozidlo/:id" component={Edit} />
            <Route path="/o-nas" component={About} />
            <Route path="/financovanie" component={Funding} />
            <Route path="/online-predaj-vozidiel" component={OnlineSale} />
            <Route path="/kontakt" component={Contact} />
            {/* <Route path="/super-soco" component={SuperSoco} /> */}
            <Route path="/login" component={Login} />
            <Route path="/cookies" render={(props) => <StaticPages page="Cookies" {...props} />} />
            <Route path="/404" component={Error404} />
            <Redirect to="/404" />
          </Switch>
        </div>

        <Footer />

        <Snackbar />
        <Popup />
      </UserContext.Provider>
    </>
  );
};
