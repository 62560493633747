// core
import React, { memo, useMemo } from 'react';

// formik
import { FastField as Field } from 'formik';
import { TextField } from 'formik-material-ui';

// components
import { LoadSelectOptions } from 'components/LoadSelectOptions';

export interface Props {
  disabled: boolean;
  hasValue: boolean;
  isMotorbike: boolean;
}

export const GearboxField = memo(function GearboxField({ hasValue, disabled, isMotorbike }: Props) {
  const inputProps = useMemo(
    () => ({
      shrink: hasValue
    }),
    [hasValue]
  );

  return (
    <LoadSelectOptions id="gearbox" filter={{ variant: isMotorbike ? 'motorbike' : 'car' }}>
      <Field
        name="gearbox"
        select
        label="Prevodovka"
        margin="dense"
        component={TextField}
        InputLabelProps={inputProps}
        disabled={disabled} // elecromobil
        fullWidth
      />
    </LoadSelectOptions>
  );
});
