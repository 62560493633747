// core
import React from 'react';

// libraries
import { Link } from 'react-router-dom';

// apollo
import { CarCardFragment } from './graphql/CarCardFragment';

// material
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// material icons
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

// components
import { Price } from 'components/Price';
import { CarHighlights } from 'components/CarHighlights';

export interface Props {
  /**
   * Car details
   */
  car: CarCardFragment;
  /**
   * Baseline price. If provided then there will be difference in prices displayed
   */
  baselinePrice?: number;
  /**
   * styles
   */
  classes?: any;
}

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
    border: 'solid 1px',
    borderColor: theme.borderColor,
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      height: 190
    },
    [theme.breakpoints.down('xs')]: {
      height: 'auto'
    },
    '& > a': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        flexDirection: 'column'
      },
      [theme.breakpoints.down('sm')]: {
        alignItems: 'flex-end',
        flexDirection: 'row'
      },
      [theme.breakpoints.down('xs')]: {
        display: 'block'
      }
    }
  },
  media: {
    width: '100%',
    height: 'auto',
    minHeight: 200, // for initial loading
    [theme.breakpoints.down('sm')]: {
      width: '40%',
      minWidth: 260
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      height: '100%'
    },
    [theme.breakpoints.down('xs')]: {
      height: 'auto'
    }
  },
  cardTitleWrap: {
    display: 'flex',
    alignItems: 'flex-start',
    flex: 1
  },
  titleWrap: {
    flex: 1,
    maxHeight: 55,
    overflowY: 'hidden',
    '& > *': {
      display: 'inline'
    },
    '& > h2': {
      marginRight: 8,
      lineHeight: '1.3em'
    }
  },
  priceWrap: {
    marginLeft: 16,
    textAlign: 'right',
    whiteSpace: 'nowrap'
  },
  price: {
    fontWeight: 'bold'
  },
  highlights: {
    marginTop: 16
  },
  highlightItem: {
    fontSize: '0.9em',
    marginBottom: 5
  }
}));

export const CarCard = ({ car, baselinePrice }: Props) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardActionArea
        component={Link}
        to={`/vozidla-na-predaj/${car.mainCategory.slug}/${car.slug}`}
      >
        <CardMedia
          component="img"
          className={classes.media}
          src={`${global.cdnUrl}/fotografia/${car.thumbnail}`}
          alt={car.fullTitle}
          title={car.fullTitle}
          width="600"
          height="450"
        />

        <CardContent className={classes.content}>
          <div className={classes.cardTitleWrap}>
            <div className={classes.titleWrap}>
              <Typography variant="h6" component="h2">
                {car.fullTitle}
              </Typography>

              <Typography variant="subtitle1" component="span" color="textSecondary">
                {car.year}
                {car.month ? `/${car.month}` : null}
              </Typography>
            </div>

            <div className={classes.priceWrap}>
              {typeof baselinePrice === 'number' ? (
                <>
                  <Typography variant="h5" component="div" className={classes.price}>
                    {'+ '}
                    <Price currency="€" decimals={0}>
                      {car.price - car.discount - baselinePrice}
                    </Price>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    component="div"
                    color="textSecondary"
                    className={classes.price}
                  >
                    <Price currency="€" decimals={0}>
                      {car.price - car.discount}
                    </Price>
                  </Typography>
                </>
              ) : (
                <Typography variant="h5" component="div" className={classes.price}>
                  <Price currency="€" decimals={0}>
                    {car.price - car.discount}
                  </Price>
                </Typography>
              )}
            </div>
          </div>

          <CarHighlights
            car={car}
            classes={{
              root: classes.highlights,
              item: classes.highlightItem
            }}
          />
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
