// core
import React, {
  useEffect,
  useState,
  createContext,
  ReactNode,
  Dispatch,
  SetStateAction
} from 'react';

export type SortTypes = 'titleOrder_ASC' | 'price_ASC' | 'price_DESC';

export interface Props {
  children: ReactNode;
}

// keep sort also after unmount
// eslint-disable-next-line no-var
var persisted: SortTypes = 'titleOrder_ASC';

export const sortContext = createContext<{
  sort: SortTypes;
  setSort: Dispatch<SetStateAction<SortTypes>>;
}>({ sort: 'titleOrder_ASC', setSort: () => {} });

export const SortContextProvider = ({ children }: Props) => {
  const [sort, setSort] = useState<SortTypes>(persisted || 'titleOrder_ASC');

  useEffect(
    () => {
      return () => {
        // Remember sort for the next mount
        persisted = sort;
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const Provider = sortContext.Provider;

  return <Provider value={{ sort, setSort }}>{children}</Provider>;
};
