// core
import React, { ReactElement } from 'react';

// libraries
import classnames from 'classnames';

// material
import { makeStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import Typography from '@material-ui/core/Typography';

// components
import { Price } from 'components/Price';

export interface Props {
  discount?: number;
  price?: number;
  children: ReactElement<any>;
  className?: string;
  classes?: {
    badge?: string;
    originalPrice?: string;
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative'
  },
  badge: {
    right: theme.spacing(),
    [theme.breakpoints.down('md')]: {
      right: theme.spacing(2)
    }
  },
  originalPrice: {
    position: 'absolute',
    top: -6,
    right: '100%',
    marginRight: theme.spacing(),
    whiteSpace: 'nowrap',
    textDecoration: 'line-through',
    fontSize: 'inherit'
  }
}));

export const DiscountBadge = ({ discount, price, children, className, classes = {} }: Props) => {
  const css = useStyles();

  return discount ? (
    <Badge
      color="primary"
      badgeContent={
        <>
          {price ? (
            <Typography
              color="textSecondary"
              variant="body2"
              className={classnames(css.originalPrice, classes.originalPrice)}
            >
              <Price currency="€" decimals={0}>
                {price}
              </Price>
            </Typography>
          ) : null}
          -{discount}&nbsp;&euro;
        </>
      }
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      className={classnames(css.root, className)}
      classes={{ badge: classnames(css.badge, classes.badge) }}
    >
      {children}
    </Badge>
  ) : (
    children
  );
};
