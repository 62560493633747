// core
import React, { ReactNode } from 'react';

// libraries
import { Link } from 'react-router-dom';

// material
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

export interface Props {
  to: string;
  selected?: boolean;
  children: ReactNode;
}

const useStyles = makeStyles((theme) => ({
  root: {
    textTransform: 'none',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    '&:hover, &:active,  &:focus': {
      background: 'none',
      textDecoration: 'underline'
    },
    '&:last-child': {
      paddingRight: 0
    }
  }
}));

export const MenuItem = ({ to, children, selected }: Props) => {
  const classes = useStyles();

  return (
    <Button
      component={Link}
      to={to}
      variant="text"
      size="medium"
      disableRipple
      className={classes.root}
      color={selected ? 'primary' : 'default'}
    >
      {children}
    </Button>
  );
};
