// core
import React, { memo, useMemo } from 'react';

// formik
import { Field } from 'formik';
import { CheckboxWithLabel } from 'formik-material-ui';

export interface Props {
  checked: boolean;
  disabled: boolean;
}

export const AutomaticDiscountField = memo(function AutomaticDiscountField({
  checked,
  disabled
}: Props) {
  const labelProps = useMemo(
    () => ({
      label: 'Automatická úprava zliav'
    }),
    []
  );

  return (
    <Field
      name="automaticDiscount"
      component={CheckboxWithLabel}
      type="checkbox"
      checked={checked}
      color="primary"
      disabled={disabled}
      Label={labelProps}
    />
  );
});
