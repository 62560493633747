// core
import React, { useEffect, useCallback } from 'react';

// libraries
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

// assets
import imageFacebook from '../../images/EDENbazar-facebook.png';
import facebookLogo from '../../images/facebook-logo.svg';
import logoSquare from '../../images/EDENbazar-logo-square.png';

// components
import { Breadcrumbs, renderBreadcrumbs } from 'components/Breadcrumbs';
import { ContentWrap } from 'components/ContentWrap';
import { PageHeading } from 'components/PageHeading';

// libraries
import { Helmet } from 'react-helmet';

// material
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';

// partials
import { MapCard } from './components/MapCard';
import { ContactForm } from './components/ContactForm';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 64,
    paddingBottom: 64
  },
  row: {
    marginTop: 32,
    marginBottom: 96,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      marginBottom: 64
    }
  },
  mapWrap: {
    marginBottom: 48
  },
  contactDetails: {
    '& > div': {
      marginLeft: 64,
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0
      }
    },
    '& > div > h2': {
      marginTop: 32
    }
  },
  address: {
    marginTop: 32,
    marginBottom: 8,
    fontWeight: 'bold',
    '& td': {
      padding: 0,
      display: 'table-cell'
    },
    '& td:first-child': {
      width: 70
    }
  },
  contacts: {
    marginBottom: 24,
    '& a': {
      fontWeight: 'bold'
    }
  },
  moreContacts: {
    marginTop: 24,
    '& a': {
      fontWeight: 'bold'
    },
    [theme.breakpoints.down('xs')]: {
      '&>div:first-child': {
        marginBottom: 24
      }
    }
  },
  moreContactsItem: {
    marginTop: 4
  },
  invoiceDetails: {
    '& > div': {
      height: '100%'
    },
    '& h2': {
      marginBottom: 16
    },
    '& table': {
      marginBottom: 16
    },
    '& td': {
      padding: 0,
      display: 'table-cell'
    },
    '& td:first-child': {
      width: 90
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 16,
      paddingRight: 16
    }
  },
  contactForm: {
    backgroundColor: theme.backgroundColor,
    padding: 32,
    borderRadius: 4,
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 16,
      paddingRight: 16
    }
  },
  contactFormHeading: {
    paddingBottom: theme.spacing(2)
  },
  facebook: {
    marginLeft: -8,
    display: 'flex',
    alignItems: 'center',
    '& > img': {
      width: 48,
      height: 48,
      marginRight: 4
    },
    '& > div': {
      fontWeight: 'bold'
    }
  }
}));

export const Contact = () => {
  const sendDataToGTM = useGTMDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const classes = useStyles();

  const handlePhoneLinkClick = useCallback(() => {
    sendDataToGTM({
      event: 'event',
      eventCategory: 'PhoneLink',
      eventAction: 'Click',
      eventValue: undefined,
      eventLabel: 'Contact'
    });
  }, [sendDataToGTM]);

  const handleEmailLinkClick = useCallback(() => {
    sendDataToGTM({
      event: 'event',
      eventCategory: 'EmailLink',
      eventAction: 'Click',
      eventValue: undefined,
      eventLabel: 'Contact'
    });
  }, [sendDataToGTM]);

  const handleFacebookLinkClick = useCallback(() => {
    sendDataToGTM({
      event: 'event',
      eventCategory: 'FacebookLink',
      eventAction: 'Click',
      eventValue: undefined,
      eventLabel: 'Contact'
    });
  }, [sendDataToGTM]);

  const breadcrumbs = [
    {
      label: 'Kontakt'
    }
  ];

  return (
    <>
      <Helmet>
        <title>Kontakt - EDENbazar, Pestovateľská 6, Bratislava</title>
        <meta
          name="description"
          content="Autobazár v Bratislave, ktorý ponúka málo jazdené alebo úplne nové vozidlá vo vynikajúcom stave."
        />
        <meta property="og:url" content={`${global.baseUrl}/kontakt`} />
        <meta property="og:title" content="EDENbazar - Autá so zárukou" />
        <meta
          property="og:description"
          content="Autobazár v Bratislave, ktorý ponúka málo jazdené alebo úplne nové vozidlá vo vynikajúcom stave."
        />
        <meta
          property="og:image"
          content={`${global.baseUrl.replace('https', 'http')}${imageFacebook}`}
        />
        <meta property="og:image:secure_url" content={`${global.baseUrl}${imageFacebook}`} />
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org/',
            '@type': 'LocalBusiness',
            '@id': 'https://www.edenbazar.sk',
            name: 'EDENbazar',
            address: {
              '@type': 'PostalAddress',
              streetAddress: 'Pestovateľská 6',
              addressLocality: 'Bratislava',
              addressRegion: 'BA',
              postalCode: '821 04',
              addressCountry: 'SK'
            },
            image: `${global.baseUrl}${logoSquare}`,
            telephone: '+421 940 509 689',
            url: 'https://www.edenbazar.sk'
          })}
        </script>
      </Helmet>

      <Breadcrumbs items={breadcrumbs}>
        {(items, c) => <ContentWrap>{renderBreadcrumbs(items, c)}</ContentWrap>}
      </Breadcrumbs>

      <ContentWrap className={classes.root}>
        <PageHeading className={classes.contactFormHeading}>Kontakt</PageHeading>

        <Grid container spacing={0} className={classes.row}>
          <Grid item xs={12} md={4} className={classes.mapWrap}>
            <MapCard />
          </Grid>

          <Grid item xs={12} md={8} className={classes.contactDetails}>
            <div>
              <Typography variant="h4" component="h2" gutterBottom>
                Prevádzka
              </Typography>

              <Typography variant="body1">
                Naše vozidlá sú zaparkované v areáli <strong>VHS Bratislava</strong>.
              </Typography>
              <Typography variant="body1">
                Obhliadka vozidla, alebo skúšobná jazda je možná len vo vopred{' '}
                <strong>dohodnutom termíne</strong>.
              </Typography>

              <Grid container className={classes.address}>
                <Grid item xs={6} lg={6}>
                  <Typography variant="body1" component="span">
                    <strong>
                      Pestovateľská 6<br />
                      821 04 Bratislava
                    </strong>
                  </Typography>
                </Grid>

                <Grid item xs={6} lg={6}>
                  <table cellSpacing={0}>
                    <tbody>
                      <tr>
                        <Typography variant="body1" component="td">
                          Po - Pi
                        </Typography>

                        <Typography variant="body1" component="td">
                          <strong>8:00 - 17:00</strong>
                        </Typography>
                      </tr>
                      <tr>
                        <Typography variant="body1" component="td">
                          Víkend
                        </Typography>

                        <Typography variant="body1" component="td">
                          <strong>Zatvorené</strong>
                        </Typography>
                      </tr>
                    </tbody>
                  </table>
                </Grid>
              </Grid>

              <Grid container className={classes.contacts}>
                <Grid item xs={6} sm={6}>
                  <Typography variant="body2" component="div">
                    E-mail
                    <br />
                    <Link href="mailto:info@edenbazar.sk" onClick={handleEmailLinkClick}>
                      info@edenbazar.sk
                    </Link>
                  </Typography>
                </Grid>

                <Grid item xs={6} sm={6}>
                  <Link
                    variant="subtitle1"
                    component="a"
                    href="https://www.facebook.com/edenbazar.sk"
                    target="_blank"
                    rel="noreferrer"
                    className={classes.facebook}
                    onClick={handleFacebookLinkClick}
                  >
                    <img
                      src={facebookLogo}
                      alt="EDENbazar stránka na Facebooku"
                      width="24"
                      height="24"
                    />

                    <Typography variant="subtitle1" component="div">
                      @edenbazar.sk
                    </Typography>
                  </Link>
                </Grid>
              </Grid>

              <Divider />

              <Grid container className={classes.moreContacts}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h6" component="h3" gutterBottom>
                    Predaj vozidiel
                  </Typography>

                  <Typography variant="subtitle2" component="strong" gutterBottom>
                    Peter Singhoffer
                  </Typography>

                  <Typography variant="body2" component="div" className={classes.moreContactsItem}>
                    Telefón
                    <br />
                    <Link
                      href="tel:+421940509689"
                      color="textPrimary"
                      onClick={handlePhoneLinkClick}
                    >
                      +421 940 509 689
                    </Link>
                  </Typography>

                  <Typography variant="body2" component="div" className={classes.moreContactsItem}>
                    E-mail
                    <br />
                    <Link
                      href="mailto:peter.singhoffer@edenbazar.sk"
                      onClick={handleEmailLinkClick}
                    >
                      peter.singhoffer@edenbazar.sk
                    </Link>
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant="h6" component="h3" gutterBottom>
                    Poistenie, lízing, úver
                  </Typography>

                  <Typography variant="subtitle2" component="strong" gutterBottom>
                    Jana Petrášová
                  </Typography>

                  <Typography variant="body2" component="div" className={classes.moreContactsItem}>
                    Telefón
                    <br />
                    <Link
                      href="tel:+421948961480"
                      color="textPrimary"
                      onClick={handlePhoneLinkClick}
                    >
                      +421 948 961 480
                    </Link>
                  </Typography>

                  <Typography variant="body2" component="div" className={classes.moreContactsItem}>
                    E-mail
                    <br />
                    <Link href="mailto:jana.petrasova@edenbazar.sk" onClick={handleEmailLinkClick}>
                      jana.petrasova@edenbazar.sk
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs={12} md={4} className={classes.invoiceDetails}>
            <div className={classes.contactForm}>
              <Typography variant="h6" component="h2">
                Fakturačná adresa
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={12}>
                  <Typography variant="body1" component="div">
                    <strong>EDENcars, s.r.o.</strong>
                    <br />
                    Ivánska cesta 83
                    <br />
                    821 04 Bratislava
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={12}>
                  <table cellSpacing={0}>
                    <tbody>
                      <tr>
                        <Typography variant="body1" component="td">
                          IČO:
                        </Typography>

                        <Typography variant="body1" component="td">
                          <strong>44923848</strong>
                        </Typography>
                      </tr>
                      <tr>
                        <Typography variant="body1" component="td">
                          DIČ:
                        </Typography>

                        <Typography variant="body1" component="td">
                          <strong>2022877604</strong>
                        </Typography>
                      </tr>
                      <tr>
                        <Typography variant="body1" component="td">
                          IČ DPH:
                        </Typography>

                        <Typography variant="body1" component="td">
                          <strong>SK2022877604</strong>
                        </Typography>
                      </tr>
                    </tbody>
                  </table>
                </Grid>
              </Grid>

              <Typography variant="body1" component="div">
                <strong>bankové spojenie:</strong>
                <br />
                ČSOB a.s.
                <br />
                SK55 7500 0000 0040 2698 8879
                <br />
                SWIFT: CEKO SK BX
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} md={8}>
            <div className={classes.contactForm}>
              <PageHeading>Kontaktný formulár</PageHeading>

              <ContactForm textPlaceholder="Text Vášho dopytu ..." />
            </div>
          </Grid>
        </Grid>
      </ContentWrap>
    </>
  );
};
