// core
import React, { ReactElement, useCallback, useEffect } from 'react';

// libraries
import classnames from 'classnames';

// material
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MuiSlider, { SliderProps, ValueLabelProps } from '@material-ui/core/Slider';

// utils
import { usePrevious } from 'utils/functions';

export interface Props extends Omit<SliderProps, 'value' | 'onChange'> {
  from: number;
  to?: number | null;
  min: number;
  max: number;
  step: number;
  onChange: (event: Event, value: { from: number | null; to: number | null }) => void;
  formatValue?: (value: number) => string;
  reverse?: boolean;
  reverseLabel?: boolean;
  classes?: {
    slider?: string;
    disabled?: string;
    thumbValue?: string;
    thumbValueReverse?: string;
  };
}

const useStyles = makeStyles((theme) => ({
  thumbValue: {
    position: 'absolute',
    top: -24,
    left: 10,
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    paddingLeft: 4,
    paddingRight: 4,
    borderRadius: 4,
    borderBottomLeftRadius: 0,
    whiteSpace: 'nowrap'
  },
  thumbValueReverse: {
    left: 'auto',
    right: 10,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 0
  }
}));

export interface SliderLabelProps extends ValueLabelProps {
  children: ReactElement<any>;
  open: boolean;
}

const ValueLabel = ({ children, open, value, className, ...rest }: SliderLabelProps) => {
  return React.cloneElement(children, {
    children: (
      <>
        {children.props.children}
        {open ? (
          <Typography variant="caption" className={className}>
            {value}
          </Typography>
        ) : null}
      </>
    )
  });
};

export const Slider = ({
  min,
  max,
  from,
  to,
  defaultValue,
  formatValue,
  reverse,
  reverseLabel,
  onChange,
  classes = {},
  onBlur,
  ...passingProps
}: Props) => {
  const value = typeof to === 'number' ? [from, to] : [from];
  const [currentValue, setCurrentValue] = React.useState<number[]>(value);
  const prevValue = usePrevious<number[]>(value);

  useEffect(
    () => {
      if (prevValue && (prevValue[0] !== from || prevValue[1] !== to)) {
        setCurrentValue(typeof to === 'number' ? [from, to] : [from]);
      }
    },
    // we dont want to run on each dep update, only on "value" prop change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [from, to]
  );

  const handleChange = useCallback((_, newValue) => {
    setCurrentValue(Array.isArray(newValue) ? newValue : [newValue]);
  }, []);

  const handleDragEnd = useCallback(
    (event) => {
      onChange(event, {
        from: currentValue[0] === min ? null : currentValue[0],
        to: currentValue[1] === max ? null : currentValue[1]
      });
    },
    [onChange, currentValue, min, max]
  );

  const css = useStyles();

  return (
    <MuiSlider
      min={min}
      max={max}
      track={reverse ? 'inverted' : 'normal'}
      value={currentValue.length > 1 ? currentValue : currentValue[0]}
      className={classes.slider}
      classes={{
        disabled: classes.disabled,
        valueLabel:
          reverse || reverseLabel
            ? classnames(
                css.thumbValue,
                classes.thumbValue,
                css.thumbValueReverse,
                classes.thumbValueReverse
              )
            : classnames(css.thumbValue, classes.thumbValue)
      }}
      valueLabelDisplay={currentValue[0] !== min || currentValue[1] !== max ? 'on' : 'off'}
      ValueLabelComponent={ValueLabel}
      valueLabelFormat={(value, index) =>
        (typeof to === 'number' ? (index ? 'do ' : 'od ') : '') +
        (typeof formatValue === 'function' ? formatValue(value) : value)
      }
      onChange={handleChange}
      onChangeCommitted={handleDragEnd}
      {...passingProps}
    />
  );
};
