// core
import React from 'react';

// apollo
import { useQuery } from '@apollo/client';
import { NewestCarsQuery, NewestCarsQueryVariables } from '../graphql/NewestCarsQuery';
import query from '../graphql/NewestCarsQuery.graphql';

// material
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

// components
import { GqlResponseHandler } from 'components/GqlResponseHandler';
import { CarCard } from 'components/CarCard';

const useStyles = makeStyles((theme) => ({
  cardsWrap: {
    '& > div': {
      paddingTop: '0 !important'
    }
  }
}));

export const NewestCars = () => {
  const classes = useStyles();

  const { data, loading, error } = useQuery<NewestCarsQuery, NewestCarsQueryVariables>(query, {
    variables: {
      limit: 3,
      sort: 'createdAt_DESC'
    }
  });

  const cars = data?.cars || [];

  return (
    <GqlResponseHandler loading={loading} error={error}>
      {cars && (
        <Grid container spacing={4} className={classes.cardsWrap}>
          {cars.map((car) => (
            <Grid key={car.id} item xs={12} md={4}>
              <CarCard car={car} />
            </Grid>
          ))}
        </Grid>
      )}
    </GqlResponseHandler>
  );
};
