// core
import React, { memo, useMemo } from 'react';

// formik
import { FastField as Field } from 'formik';
import { TextField } from 'formik-material-ui';

export interface Props {
  name: string;
  label: string;
}

export const TechnicalControlField = memo(function TechnicalControlField({ name, label }: Props) {
  const inputProps = useMemo(
    () => ({
      shrink: true
    }),
    []
  );

  return (
    <Field
      name={name}
      type="month"
      label={label}
      margin="dense"
      component={TextField}
      InputLabelProps={inputProps}
      fullWidth
    />
  );
});
