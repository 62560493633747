// core
import React, { ReactNode } from 'react';

// apollo
import { useQuery } from '@apollo/client';
import carsFilterOptionsQuery from '../graphql/CarsFilterOptionsQuery.graphql';
import {
  CarsFilterOptionsQuery,
  CarsFilterOptionsQueryVariables
} from '../graphql/CarsFilterOptionsQuery';

// components
import { LoadingDiv } from 'components/LoadingDiv';

// material
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

// partials
import { CarFilterCheckbox } from './CarFilterCheckbox';

export interface Props {
  name: string;
  label: ReactNode;
  className: string;
  queryRes?: (q: any) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 8
  },
  label: {
    marginLeft: -8
  },
  checkbox: {
    padding: 4,
    marginRight: 8
  }
}));

export const CarsFilterCheckboxes = ({ label, name, className, queryRes }: Props) => {
  const classes = useStyles();

  const { data, error, loading } = useQuery<
    CarsFilterOptionsQuery,
    CarsFilterOptionsQueryVariables
  >(carsFilterOptionsQuery, {
    variables: { id: name }
  });

  const getContent = () => {
    if (loading) {
      return <LoadingDiv />;
    }

    if (data && queryRes) {
      queryRes(data);
    }

    if (error) {
      console.log(error);
      global.bugsnagClient.notify(error);
      return (
        <Typography variant="caption" color="error">
          Chyba v spojení so serverom. Skúste to prosím znova.
        </Typography>
      );
    }

    const items = (data?.options || []).map((field) => (
      <React.Fragment key={field.value}>
        <CarFilterCheckbox name={name} label={field.label} value={field.value} />
        <br />
      </React.Fragment>
    ));
    const length = items.length;
    const itemsInCol = Math.ceil(length / 2);

    const cols = [];
    for (let c = 0; c < length; c += itemsInCol) {
      cols.push(
        <Grid item xs={6} key={c}>
          {items.splice(0, itemsInCol)}
        </Grid>
      );
    }

    return (
      <Grid container spacing={0} className={classes.root}>
        {cols}
      </Grid>
    );
  };

  return (
    <div className={className}>
      <Typography variant="subtitle2" component="h2">
        {label}
      </Typography>

      {getContent()}
    </div>
  );
};
