// core
import React, { ReactNode } from 'react';

// material
import Alert, { AlertProps } from '@material-ui/lab/Alert';

export interface Props extends AlertProps {
  message: ReactNode;
}

export const EmptyMessage = ({ message, ...passingProps }: Props) => {
  return (
    <Alert severity="warning" variant="outlined" {...passingProps}>
      {message}
    </Alert>
  );
};
