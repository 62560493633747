// core
import React, { ReactNode } from 'react';

// libraries
import { Link } from 'react-router-dom';

// material
import UILink from '@material-ui/core/Link';

export type Props = {
  label: ReactNode;
  to: string;
  classes?: any;
};

export const FooterMenuItem = ({ label, to, classes }: Props) => {
  return (
    <UILink variant="subtitle1" component={Link} to={to} gutterBottom className={classes.link}>
      {label}
    </UILink>
  );
};
