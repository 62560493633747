// core
import React, { memo, useMemo } from 'react';

// formik
import { FastField as Field } from 'formik';
import { TextField } from 'formik-material-ui';

// material-ui
import InputAdornment from '@material-ui/core/InputAdornment';

export const EnginePowerField = memo(function EnginePowerField() {
  const inputProps = useMemo(
    () => ({
      endAdornment: <InputAdornment position="end">cm³</InputAdornment>,
      inputProps: {
        min: 0
      }
    }),
    []
  );

  return (
    <Field
      name="enginePower"
      type="number"
      label="Výkon motora"
      margin="dense"
      component={TextField}
      InputProps={inputProps}
      fullWidth
    />
  );
});
