// core
import React from 'react';

// apollo
import { CarsQuery_cars as CarsQueryCars } from '../graphql/CarsQuery';

// partials
import { CarCard } from './CarCard';

export interface Props {
  cars: readonly CarsQueryCars[];
}

export const CarList = ({ cars }: Props) => {
  return cars ? (
    <div>
      {cars.map((car) => (
        <CarCard key={car.id} car={car} fancy={!!car.new || !!car.discount} />
      ))}
    </div>
  ) : null;
};
