// core
import React from 'react';

// apollo
import { CarHighlightsFragment } from './graphql/CarHighlightsFragment';

// libraries
import classnames from 'classnames';

// material
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

// material icons
import DirectionsCarIcon from '@material-ui/icons/DirectionsCarOutlined';
import LocalGasStationIcon from '@material-ui/icons/LocalGasStationOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import AvTimerIcon from '@material-ui/icons/AvTimerOutlined';
import CheckIcon from '@material-ui/icons/CheckOutlined';
import MotorbikeIcon from '@material-ui/icons/MotorcycleOutlined';

export interface Props {
  car: CarHighlightsFragment;
  emptyRightBottomCorner?: boolean;
  variant?: 'default' | 'white' | 'text';
  className?: string;
  classes?: any;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  item: {
    marginBottom: 10,
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    '& > span': {
      display: 'block',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  },
  icon: {
    marginRight: 12
  },
  white: {
    '& $item': {
      color: '#fafafa'
    }
  }
}));

export const CarHighlights = ({
  car,
  emptyRightBottomCorner,
  variant,
  className,
  classes = {}
}: Props) => {
  const css = useStyles();

  const { bodyName, fuelName, gearboxName, mileage, highlightsName, manufacturer } = car;
  const isMotorbike = manufacturer === '192107';

  const highlights = highlightsName
    ? highlightsName.filter((name) => name !== 'Automat').slice(0, 2)
    : [];

  if (variant === 'text') {
    return <>{[bodyName, fuelName, gearboxName, mileage].concat(highlights).join(', ')}</>;
  }

  const mileageComponent = mileage ? (
    <Typography
      variant="body1"
      component="div"
      color="secondary"
      className={classnames(css.item, classes.item)}
    >
      <AvTimerIcon className={classnames(css.icon, classes.icon)} />
      <span>{`${mileage.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} km`}</span>
    </Typography>
  ) : null;

  return (
    <Grid
      container
      spacing={0}
      className={
        variant === 'white'
          ? classnames(css.root, css.white, classes.root, classes.white, className)
          : classnames(css.root, classes.root, className)
      }
    >
      <Grid item xs={6}>
        <Typography
          variant="body1"
          component="div"
          color="secondary"
          className={classnames(css.item, classes.item)}
        >
          {isMotorbike ? (
            <MotorbikeIcon className={classnames(css.icon, classes.icon)} />
          ) : (
            <DirectionsCarIcon className={classnames(css.icon, classes.icon)} />
          )}
          <span>{bodyName}</span>
        </Typography>

        <Typography
          variant="body1"
          component="div"
          color="secondary"
          className={classnames(css.item, classes.item)}
        >
          <LocalGasStationIcon className={classnames(css.icon, classes.icon)} />
          <span>{fuelName}</span>
        </Typography>

        <Typography
          variant="body1"
          component="div"
          color="secondary"
          className={classnames(css.item, classes.item)}
        >
          <SettingsIcon className={classnames(css.icon, classes.icon)} />
          <span>{gearboxName}</span>
        </Typography>

        {emptyRightBottomCorner ? mileageComponent : null}
      </Grid>

      <Grid item xs={6}>
        {!emptyRightBottomCorner ? mileageComponent : null}

        {highlights[0] ? (
          <Typography
            variant="body1"
            component="div"
            color="secondary"
            className={classnames(css.item, classes.item)}
          >
            <CheckIcon className={classnames(css.icon, classes.icon)} />
            <span>{highlights[0]}</span>
          </Typography>
        ) : null}

        {highlights[1] ? (
          <Typography
            variant="body1"
            component="div"
            color="secondary"
            className={classnames(css.item, classes.item)}
          >
            <CheckIcon className={classnames(css.icon, classes.icon)} />
            <span>{highlights[1]}</span>
          </Typography>
        ) : null}
      </Grid>
    </Grid>
  );
};
