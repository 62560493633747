// core
import React, { useState, useCallback, Ref } from 'react';

// apollo
import selectTemplateOptionsQuery from '../graphql/SelectTemplateOptionsQuery.graphql';
import { SelectTemplateOptionsQuery } from '../graphql/SelectTemplateOptionsQuery';
import { CarFragment } from '../../Car/graphql/CarFragment';

// components
import { FieldQueryWrap } from 'components/FieldQueryWrap';

// libraries
import {
  SelectComponentsConfig,
  InputProps,
  ControlProps,
  MenuProps,
  OptionProps,
  PlaceholderProps,
  SingleValueProps,
  ValueContainerProps
} from 'react-select';
import { NoticeProps } from 'react-select/src/components/Menu';
import CreatableSelect from 'react-select/creatable';

// material
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';

export interface OptionType {
  label: string;
  value: string;
}

export interface Props {
  car?: CarFragment;
  emptyRightBottomCorner?: boolean;
  className?: string;
  onChange: (value: any, isNew: boolean) => any;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  input: {
    display: 'flex',
    padding: 0,
    height: 36,
    color: theme.palette.text.primary,
    '& input': {
      font: 'inherit'
    }
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden'
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
  },
  singleValue: {
    fontSize: 16
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0
  },
  optionSelected: {
    fontWeight: 'bold'
  }
}));

function NoOptionsMessage(props: NoticeProps<OptionType, false>) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }: InputProps & { inputRef: Ref<any> }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props: ControlProps<OptionType, false>) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps
        }
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props: OptionProps<OptionType, false>) {
  const classes = useStyles();

  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      // @ts-ignore
      className={props.isSelected ? classes.optionSelected : ''}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props: PlaceholderProps<OptionType, false>) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props: SingleValueProps<OptionType>) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function ValueContainer(props: ValueContainerProps<OptionType, false>) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function Menu(props: MenuProps<OptionType, false>) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const components: SelectComponentsConfig<OptionType, false> = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer
};

export const TemplateSelect = ({ className, onChange }: Props) => {
  const [selected, setSelected] = useState(null);
  const classes = useStyles();

  const handleChange = useCallback(
    (item) => {
      if (Array.isArray(item)) {
        item = item[0];
      }

      setSelected(item);
      onChange(item ? item.value : null, item ? item.__isNew__ || false : false);
    },
    [onChange]
  );

  return (
    <FieldQueryWrap<SelectTemplateOptionsQuery, null> query={selectTemplateOptionsQuery}>
      {(data) => (
        <CreatableSelect<OptionType, false>
          className={className}
          classes={classes}
          options={
            (data?.templates &&
              data.templates.map((template) => ({ value: template.id, label: template.name }))) ||
            []
          }
          components={components}
          value={selected}
          onChange={handleChange}
          placeholder="Predvyplniť zo vzoru"
          isClearable
        />
      )}
    </FieldQueryWrap>
  );
};
