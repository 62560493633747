// core
import React from 'react';

// material
// import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

// material icons
import ViewIcon from '@material-ui/icons/VisibilityOutlined';

export interface Props {
  /**
   * Number of views
   */
  views: number;
}

// const useStyles = makeStyles((theme) => ({}));

export const CarViews = ({ views }: Props) => {
  return (
    <Box clone display="flex" alignItems="center">
      <Typography variant="body1" color="inherit" title="Počet zobrazení za posledných 30 dní">
        <Box clone mr={0.5}>
          <ViewIcon fontSize="small" />
        </Box>

        {views}
      </Typography>
    </Box>
  );
};
