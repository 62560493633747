// core
import React from 'react';

// libraries
import { Link } from 'react-router-dom';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Button from '@material-ui/core/Button';
import { green } from '@material-ui/core/colors';

// icons
import VerifiedIcon from '@material-ui/icons/VerifiedUserOutlined';
import ReportIcon from '@material-ui/icons/DescriptionOutlined';

export interface Props {
  authenticityReport: string;
}

const useStyles = makeStyles((theme) => ({
  authenticityReport: {
    marginTop: theme.spacing(2)
  },
  authenticityReportButtons: {
    marginTop: theme.spacing(2)
  },
  authenticityReportPdfButton: {
    marginRight: theme.spacing(1),
    boxShadow: 'none !important',
    color: '#ffffff',
    backgroundColor: green[600],
    '&:hover': {
      backgroundColor: green[700]
    }
  },
  authenticityReportInfoButton: {
    color: green[900]
  }
}));

export const AuthenticityReport = ({ authenticityReport }: Props) => {
  const css = useStyles();

  return (
    <Alert
      icon={<VerifiedIcon />}
      color="success"
      variant="outlined"
      className={css.authenticityReport}
    >
      <AlertTitle>Skontrolované nezávislou spoločnosťou</AlertTitle>
      Toto vozidlo bolo dôkladne skontrolované nezávislou spoločnosťou, ktorá garantuje, že vozidlo
      je v poriatku.
      <div className={css.authenticityReportButtons}>
        <Button
          size="small"
          color="inherit"
          variant="contained"
          startIcon={<ReportIcon />}
          className={css.authenticityReportPdfButton}
          href={`${global.cdnUrl}/subor/${authenticityReport}`}
          target="_blank"
        >
          Záverečná správa
        </Button>

        <Button
          size="small"
          color="inherit"
          className={css.authenticityReportInfoButton}
          component={Link}
          to="/o-nas#nezavisla-kontrola-vozidiel"
        >
          Viac informácií
        </Button>
      </div>
    </Alert>
  );
};
