// core
import React from 'react';

// apollo
import { useQuery } from '@apollo/client';
import query from './graphql/XmlOutputQuery.graphql';

// material
import Typography from '@material-ui/core/Typography';

export interface Props {
  type: 'SITEMAP' | 'AUTOBAZARSK';
}

export const XmlOutput = ({ type }: Props) => {
  const { data, error, loading } = useQuery(query, { variables: { type } });

  if (error) {
    console.log(error);
    global.bugsnagClient.notify(error);
  }

  return loading ? null : error ? (
    <Typography variant="caption" color="error">
      Chyba v spojení so serverom. Skúste to prosím znova.
    </Typography>
  ) : (
    data.xml
  );
};
