// core
import React, { useState, useEffect } from 'react';

// components
import { ContentWrap } from 'components/ContentWrap';
import { Loading } from 'components/Loading';

// libraries
import firebase from 'firebase/app';
import ExecutionEnvironment from 'exenv';
import { Helmet } from 'react-helmet';

// material
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 16,
    '& .firebaseui-container': {
      paddingTop: 64,
      paddingBottom: 64,
      width: 400,
      maxWidth: '100%',
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    '& .firebaseui-card-content': {
      marginBottom: 32
    },
    '& .firebaseui-idp-list': {
      display: 'flex',
      justifyContent: 'space-evenly',
      alignContent: 'center',
      alignItems: 'center',
      listStyle: 'none',
      paddingLeft: 0
    },
    '& .firebaseui-list-item': {},
    '& .firebaseui-idp-button, & .firebaseui-button': {
      paddingLeft: 24,
      paddingRight: 24,
      paddingTop: 16,
      paddingBottom: 16,
      color: '#fff',
      backgroundColor: theme.palette.secondary.main + ' !important',
      border: 'none',
      boxShadow: '0px 1px 0px 0px #ccc',
      borderRadius: 4,
      cursor: 'pointer',
      fontSize: 16
    },
    '& .firebaseui-idp-icon-wrapper': {
      height: 24
    },
    '& .firebaseui-idp-icon': {
      width: 32,
      height: 32,
      marginRight: 16,
      verticalAlign: 'middle'
    },
    '& .firebaseui-idp-text': {
      fontSize: 24,
      verticalAlign: 'middle'
    },
    '& .firebaseui-idp-text-long': {
      display: 'none'
    },
    '& .firebaseui-label': {
      display: 'block',
      marginBottom: 8
    },
    '& .firebaseui-card-actions': {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 32
    },
    '& .firebaseui-card-actions .firebaseui-button': {
      marginRight: 16
    },
    '& .firebaseui-card-actions .firebaseui-button:last-child': {
      marginRight: 0
    },
    '& .firebaseui-form-links': {
      flex: 1
    },
    '& .firebaseui-input, & .firebaseui-id-password': {
      display: 'block',
      height: 40,
      width: '100%',
      paddingLeft: 16,
      paddingRight: 16,
      border: 'solid 1px #ccc',
      borderRadius: 4
    },
    '& .firebaseui-card-footer': {
      textAlign: 'center'
    },
    '& .firebaseui-card-footer ul': {
      listStyle: 'none'
    },
    '& .firebaseui-card-footer li': {
      display: 'inline-block',
      marginRight: 16
    }
  }
}));

export const Login = () => {
  const [loading, setLoading] = useState(true);

  const classes = useStyles();

  useEffect(() => {
    if (ExecutionEnvironment.canUseDOM) {
      const firebaseui = require('firebaseui');
      const ui = new firebaseui.auth.AuthUI(firebase.auth());

      const uiConfig = {
        callbacks: {
          uiShown: function () {
            setLoading(false);
          }
        },
        signInFlow: 'popup',
        signInSuccessUrl: '/',
        signInOptions: [
          firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          firebase.auth.EmailAuthProvider.PROVIDER_ID
        ],
        // Terms of service url.
        tosUrl: '<your-tos-url>',
        // Privacy policy url.
        privacyPolicyUrl: '<your-privacy-policy-url>'
      };

      ui.start('#firebaseui-auth-container', uiConfig);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>EDENbazar</title>
      </Helmet>

      <ContentWrap>
        <Typography component="div">
          <div id="firebaseui-auth-container" className={classes.root} />

          {loading ? <Loading /> : null}
        </Typography>
      </ContentWrap>
    </>
  );
};
