// core
import React from 'react';

// libraries
import classnames from 'classnames';

// material
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// material icons
import BallotIcon from '@material-ui/icons/BallotOutlined';
import VerifiedIcon from '@material-ui/icons/VerifiedUserOutlined';
import PersonIcon from '@material-ui/icons/PersonOutlineOutlined';
import TimeIcon from '@material-ui/icons/AccessTimeOutlined';

export interface Props {
  classes?: {
    root?: string;
    subheading?: string;
    smallerIcon?: string;
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    '& > div': {
      textAlign: 'center',
      '& > svg': {
        fontSize: '5em',
        color: theme.palette.primary.main,
        marginBottom: 8,
        height: 80,
        [theme.breakpoints.down('xs')]: {
          fontSize: '4em',
          height: 64
        }
      },
      '&$smallerIcon > svg': {
        fontSize: '4.3em',
        [theme.breakpoints.down('xs')]: {
          fontSize: '3.5em'
        }
      },
      '& > p': {
        fontSize: '1.1em',
        [theme.breakpoints.down('xs')]: {
          fontSize: '1em'
        }
      }
    }
  },
  smallerIcon: {}
}));

export const Advantages = ({ classes = {} }: Props) => {
  const css = useStyles();

  return (
    <Grid container spacing={3} className={classnames(css.root, classes.root)}>
      <Grid item xs={6} sm={3}>
        <BallotIcon />
        <Typography variant="subtitle2" component="p" className={classes.subheading}>
          Vo fabrickej záruke
        </Typography>
      </Grid>

      <Grid item xs={6} sm={3} className={classnames(css.smallerIcon, classes.smallerIcon)}>
        <VerifiedIcon />
        <Typography variant="subtitle2" component="p" className={classes.subheading}>
          Nič neriskujete
        </Typography>
      </Grid>

      <Grid item xs={6} sm={3}>
        <PersonIcon />
        <Typography variant="subtitle2" component="p" className={classes.subheading}>
          Po prvom majiteľovi
        </Typography>
      </Grid>

      <Grid item xs={6} sm={3} className={classnames(css.smallerIcon, classes.smallerIcon)}>
        <TimeIcon />
        <Typography variant="subtitle2" component="p" className={classes.subheading}>
          Vybavenie do 1 hodiny
        </Typography>
      </Grid>
    </Grid>
  );
};
