// core
import React, { memo } from 'react';

// formik
import { FastField as Field, useField } from 'formik';
import { Checkbox } from 'formik-material-ui';

// material icons
import FullStarIcon from '@material-ui/icons/Star';
import EmptyStarIcon from '@material-ui/icons/StarBorder';

// material
import { makeStyles } from '@material-ui/core/styles';
import { yellow } from '@material-ui/core/colors';

export interface Props {
  name: string;
  value: string;
}

const useStyles = makeStyles((theme) => ({
  checkbox: {
    padding: theme.spacing(0.5)
  },
  icon: {
    color: yellow[800]
  }
}));

export const HighlightField = memo(function HighlightField({ name, value }: Props) {
  const classes = useStyles();

  const [field] = useField({ name, value, type: 'checkbox' });

  return (
    <Field
      indeterminate={false}
      component={Checkbox}
      type="checkbox"
      className={classes.checkbox}
      icon={<EmptyStarIcon fontSize="small" />}
      checkedIcon={<FullStarIcon fontSize="small" className={classes.icon} />}
      {...field}
    />
  );
});
