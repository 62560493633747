// core
import React, { useCallback } from 'react';

// formik
import { FastField as Field, useFormikContext } from 'formik';
import { TextField } from 'formik-material-ui';

// material
import { makeStyles } from '@material-ui/core/styles';

// components
import { LoadSelectOptions } from 'components/LoadSelectOptions';

// partials
import { CarDetailsFormValues } from './CarDetailsForm';
import { AccessoriesFields } from './AccessoriesFields';
import { HighlightField } from './HighlightField';

const useStyles = makeStyles((theme) => ({
  miniField: {
    paddingTop: 4,
    paddingBottom: 4
  },
  additionalField: {
    marginLeft: 8
  }
}));

export interface Props {
  isMotorbike: boolean;
}

export const AccessoriesFieldsComfort = ({ isMotorbike }: Props) => {
  const classes = useStyles();

  const { values } = useFormikContext<CarDetailsFormValues>();

  const handleAppendFields = useCallback(
    (field) => {
      const ret = [];

      if (field.value === '1' && values.comfortEquipment.includes('1')) {
        ret.push(
          <LoadSelectOptions key="field" id="carRadio">
            <Field
              name="carRadio"
              select
              component={TextField}
              variant="filled"
              InputProps={{
                inputProps: {
                  className: classes.miniField
                }
              }}
              className={classes.additionalField}
            />
          </LoadSelectOptions>
        );
      } else if (field.value === '9' && values.comfortEquipment.includes('9')) {
        ret.push(
          <LoadSelectOptions key="field" id="airConditioning">
            <Field
              name="airConditioning"
              select
              component={TextField}
              variant="filled"
              InputProps={{
                inputProps: {
                  className: classes.miniField
                }
              }}
              className={classes.additionalField}
            />
          </LoadSelectOptions>
        );
      } else if (field.value === '4' && values.comfortEquipment.includes('4')) {
        ret.push(
          <LoadSelectOptions key="field" id="electricalWindows">
            <Field
              name="electricalWindows"
              select
              component={TextField}
              variant="filled"
              InputProps={{
                inputProps: {
                  className: classes.miniField
                }
              }}
              className={classes.additionalField}
            />
          </LoadSelectOptions>
        );
      } else if (field.value === '22' && values.comfortEquipment.includes('22')) {
        ret.push(
          <LoadSelectOptions key="field" id="heatedSeats">
            <Field
              name="heatedSeats"
              select
              component={TextField}
              variant="filled"
              InputProps={{
                inputProps: {
                  className: classes.miniField
                }
              }}
              className={classes.additionalField}
            />
          </LoadSelectOptions>
        );
      }

      ret.push(<HighlightField key="highlight" name="highlights" value={`c${field.value}`} />);

      return ret;
    },
    [classes, values.comfortEquipment]
  );

  return (
    <AccessoriesFields
      name="comfortEquipment"
      appendFields={handleAppendFields}
      isMotorbike={isMotorbike}
    />
  );
};
