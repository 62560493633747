// core
import React, { useCallback } from 'react';

// libraries
import {
  FacebookShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookIcon,
  LinkedinIcon,
  WhatsappIcon,
  EmailIcon
} from 'react-share';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

export interface Props {
  url: string;
  shareTitle: string;
  shareDescription: string;
}

const useStyles = makeStyles((theme) => ({
  messengerButton: {
    height: 48,
    backgroundColor: '#00C6FF',
    '&:hover, &:active': {
      backgroundColor: '#00C6FF'
    },
    '& svg > path': {
      fill: '#fff'
    }
  }
}));

export const ShareIcons = ({ url, shareTitle, shareDescription }: Props) => {
  const css = useStyles();
  const sendDataToGTM = useGTMDispatch();

  const handleShareButtonClick = useCallback(
    (type) => () => {
      sendDataToGTM({
        event: 'event',
        eventCategory: 'ShareButton',
        eventAction: 'Click',
        eventValue: undefined,
        eventLabel: type
      });
    },
    [sendDataToGTM]
  );

  return (
    <>
      <Tooltip title="Zdieľať na Facebooku" placement="top">
        <div>
          <FacebookShareButton
            url={url}
            hashtag="#edenbazar"
            beforeOnClick={handleShareButtonClick('Facebook')}
          >
            <FacebookIcon size={48} round={true} />
          </FacebookShareButton>
        </div>
      </Tooltip>

      <Tooltip title="Zdieľať cez Messenger" placement="top">
        <IconButton
          component="div"
          role="button"
          className={css.messengerButton}
          disableRipple
          onClick={() => {
            handleShareButtonClick('Messenger');
            window.open(
              'fb-messenger://share?link=' +
                encodeURIComponent(url) +
                '&app_id=' +
                encodeURIComponent(process.env.REACT_APP_FACEBOOK_APP_ID || '')
            );
          }}
        >
          <svg
            width="24"
            height="24"
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            clipRule="evenodd"
          >
            <path d="M12 0c-6.627 0-12 4.975-12 11.111 0 3.497 1.745 6.616 4.472 8.652v4.237l4.086-2.242c1.09.301 2.246.464 3.442.464 6.627 0 12-4.974 12-11.111 0-6.136-5.373-11.111-12-11.111zm1.193 14.963l-3.056-3.259-5.963 3.259 6.559-6.963 3.13 3.259 5.889-3.259-6.559 6.963z" />
          </svg>
        </IconButton>
      </Tooltip>

      <Tooltip title="Zdieľať na LinkedIn" placement="top">
        <div>
          <LinkedinShareButton
            url={url}
            title={shareTitle}
            summary={shareDescription}
            beforeOnClick={handleShareButtonClick('LinkedIn')}
          >
            <LinkedinIcon size={48} round={true} />
          </LinkedinShareButton>
        </div>
      </Tooltip>

      <Tooltip title="Zdieľať cez WhatsApp" placement="top">
        <div>
          <WhatsappShareButton
            url={url}
            beforeOnClick={handleShareButtonClick('WhatsApp')}
            title={shareTitle}
            separator=" - "
          >
            <WhatsappIcon size={48} round={true} />
          </WhatsappShareButton>
        </div>
      </Tooltip>

      <Tooltip title="Poslať cez e-mail" placement="top">
        <div>
          <EmailShareButton
            url={url}
            subject={shareTitle}
            beforeOnClick={handleShareButtonClick('Email')}
          >
            <EmailIcon size={48} round={true} />
          </EmailShareButton>
        </div>
      </Tooltip>
    </>
  );
};
