// core
import React from 'react';

// components
import { Subheading } from 'components/Subheading';
import { FormFieldsGrid } from 'components/FormFieldsGrid';

// formik
import { useFormikContext, FastField as Field } from 'formik';
import { TextField } from 'formik-material-ui';

// libraries
import moment from 'moment';

// material
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

// colors
import { blue } from '@material-ui/core/colors';

// partials
import { CarDetailsFormValues, isMotorbike as isMotorbikeFn } from './CarDetailsForm';
import { CategoriesField } from './CategoriesField';
import { AccessoriesFieldsSafety } from './AccessoriesFieldsSafety';
import { AccessoriesFieldsComfort } from './AccessoriesFieldsComfort';
import { AccessoriesFieldsOther } from './AccessoriesFieldsOther';
import { AccessoriesFieldsAdditionalInfo } from './AccessoriesFieldsAdditionalInfo';
import { ManufacturerField } from './ManufacturerField';
import { ModelField } from './ModelField';
import { YearField } from './YearField';
import { MonthField } from './MonthField';
import { MileageField } from './MileageField';
import { StateField } from './StateField';
import { PriceField } from './PriceField';
import { DiscountField } from './DiscountField';
import { VatDeductionField } from './VatDeductionField';
import { DiscountOfWeekField } from './DiscountOfWeekField';
import { AutomaticDiscountField } from './AutomaticDiscountField';
import { BodyField } from './BodyField';
import { ColorField } from './ColorField';
import { MetallicField } from './MetallicField';
import { NumDoorsField } from './NumDoorsField';
import { NumSeatsField } from './NumSeatsField';
import { FuelField } from './FuelField';
import { EmissionClassField } from './EmissionClassField';
import { EngineVolumeField } from './EngineVolumeField';
import { EnginePowerField } from './EnginePowerField';
import { GearboxField } from './GearboxField';
import { DriveField } from './DriveField';
import { ConsumptionField } from './ConsumptionField';
import { TechnicalControlField } from './TechnicalControlField';
import { EnableField } from './EnableField';
import { AutoskladPriceField } from './AutoskladPriceField';
import { AutoskladValidUntilField } from './AutoskladValidUntilField';

export interface Props {
  isTemplate: boolean;
  isEdit: boolean;
}

const useStyles = makeStyles((theme) => ({
  subheading: {
    marginTop: 32,
    '&:before': {
      bottom: 6
    }
  },
  fieldWithCheckboxWrap: {
    display: 'flex'
  },
  fieldWithCheckbox: {
    marginRight: 24
  },
  fieldWithCheckboxCheckbox: {
    marginRight: 0
  },
  powerWrap: {
    display: 'flex',
    alignItems: 'baseline'
  },
  powerInHorses: {
    whiteSpace: 'nowrap',
    marginLeft: 12
  },
  optionsEdenbazar: {
    borderColor: theme.palette.primary.main
  },
  optionsAutosklad: {
    borderColor: blue[500]
  },
  optionsAutoskladHeading: {
    color: blue[500]
  }
}));

export const CarDetailsFormFields = ({ isTemplate, isEdit }: Props) => {
  const classes = useStyles();

  const { values, setFieldValue } = useFormikContext<CarDetailsFormValues>();
  const isMotorbike = isMotorbikeFn(values.manufacturer);

  return (
    <>
      {isTemplate ? (
        <Field
          name="name"
          type="text"
          label="Názov vzoru"
          component={TextField}
          margin="dense"
          fullWidth
        />
      ) : null}

      <FormFieldsGrid>
        <ManufacturerField hasValue={!!values.manufacturer} />

        {values.manufacturer ? (
          <ModelField hasValue={!!values.model} manufacturer={values.manufacturer} />
        ) : null}

        <Field
          name="title"
          type="text"
          label="Spresnenie modelu"
          component={TextField}
          margin="dense"
          fullWidth
        />
      </FormFieldsGrid>

      <FormFieldsGrid>
        <CategoriesField />
      </FormFieldsGrid>

      <FormFieldsGrid>
        <FormFieldsGrid>
          <YearField hasValue={!!values.year} />

          <MonthField hasValue={!!values.month} />
        </FormFieldsGrid>

        <MileageField />

        <StateField hasValue={!!values.state} />
      </FormFieldsGrid>

      <Subheading variant="h5" className={classes.subheading}>
        Zobrazenie a ceny
      </Subheading>

      <Box clone marginTop={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box clone padding={2}>
              <Paper variant="outlined" className={classes.optionsEdenbazar}>
                <EnableField
                  name="active"
                  label={
                    <Typography variant="subtitle2" color="primary">
                      EDENbazar
                    </Typography>
                  }
                  checked={values.active}
                />

                <div className={classes.fieldWithCheckboxWrap}>
                  <PriceField className={classes.fieldWithCheckbox} disabled={!values.active} />

                  <DiscountField className={classes.fieldWithCheckbox} disabled={!values.active} />

                  <VatDeductionField checked={!!values.vatDeduction} disabled={!values.active} />
                </div>

                {isEdit ? (
                  <Box marginTop={1}>
                    <DiscountOfWeekField
                      disabled={!values.discount || !values.active}
                      checked={!!values.discountOfWeek}
                    />

                    <AutomaticDiscountField
                      disabled={!values.discount || !values.active}
                      checked={!!values.automaticDiscount}
                    />

                    {values.automaticDiscountStartDate ? (
                      <>
                        Začiatok zliav:{' '}
                        {moment(values.automaticDiscountStartDate).format('DD.MM.YYYY')}{' '}
                        <Button
                          size="small"
                          onClick={() => setFieldValue('automaticDiscountStartDate', new Date())}
                        >
                          Resetovať
                        </Button>
                      </>
                    ) : null}
                  </Box>
                ) : null}
              </Paper>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box clone padding={2}>
              <Paper variant="outlined" className={classes.optionsAutosklad}>
                <EnableField
                  name="autoskladActive"
                  label={
                    <Typography variant="subtitle2" className={classes.optionsAutoskladHeading}>
                      Auto Sklad
                    </Typography>
                  }
                  checked={values.autoskladActive}
                />

                <FormFieldsGrid>
                  <AutoskladPriceField disabled={!values.autoskladActive} />

                  <AutoskladValidUntilField disabled={!values.autoskladActive} />
                </FormFieldsGrid>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Subheading variant="h5" className={classes.subheading}>
        Karoséria
      </Subheading>

      <FormFieldsGrid>
        {!isMotorbike && <BodyField hasValue={!!values.body} />}

        <Field name="vin" type="text" label="VIN" margin="dense" component={TextField} fullWidth />
      </FormFieldsGrid>

      <FormFieldsGrid>
        <div className={classes.fieldWithCheckboxWrap}>
          <ColorField hasValue={!!values.color} className={classes.fieldWithCheckbox} />

          <MetallicField
            checked={!!values.metallic}
            className={classes.fieldWithCheckboxCheckbox}
          />
        </div>

        {!isMotorbike && <NumDoorsField hasValue={!!values.numDoors} />}

        {!isMotorbike && <NumSeatsField hasValue={!!values.numSeats} />}
      </FormFieldsGrid>

      <Subheading variant="h5" className={classes.subheading}>
        Motor
      </Subheading>

      <FormFieldsGrid>
        <FuelField hasValue={!!values.fuel} isMotorbike={isMotorbike} />

        <EmissionClassField hasValue={!!values.emissionClass} />
      </FormFieldsGrid>

      <FormFieldsGrid>
        <EngineVolumeField
          disabled={values.fuel === '6'} // elecromobil
        />

        <div className={classes.powerWrap}>
          <EnginePowerField />

          <Typography variant="body1" color="textSecondary" className={classes.powerInHorses}>
            {`/ ${Math.round(values.enginePower * 1.341)} HP`}
          </Typography>
        </div>
      </FormFieldsGrid>

      <FormFieldsGrid>
        <GearboxField
          hasValue={!!values.gearbox}
          isMotorbike={isMotorbike}
          disabled={values.fuel === '6'} // elecromobil
        />

        {!isMotorbike && <DriveField hasValue={!!values.drive} isMotorbike={isMotorbike} />}
      </FormFieldsGrid>

      {!isMotorbike && (
        <FormFieldsGrid>
          <ConsumptionField name="consumptionCity" label="Spotreba v meste" />

          <ConsumptionField name="consumptionOutside" label="Spotreba mimo mesta" />

          <ConsumptionField name="consumptionCombined" label="Kombinovaná spotreba" />
        </FormFieldsGrid>
      )}

      <FormFieldsGrid>
        <TechnicalControlField name="technicalControl" label="STK platná do" />

        <TechnicalControlField name="emissionControl" label="EK platná do" />
      </FormFieldsGrid>

      <Subheading variant="h5" className={classes.subheading}>
        Bezpečnosť
      </Subheading>

      <AccessoriesFieldsSafety isMotorbike={isMotorbike} />

      <Subheading variant="h5" className={classes.subheading}>
        Komfort
      </Subheading>

      <AccessoriesFieldsComfort isMotorbike={isMotorbike} />

      <Subheading variant="h5" className={classes.subheading}>
        Ďalšia výbava
      </Subheading>

      <AccessoriesFieldsOther isMotorbike={isMotorbike} />

      <Subheading variant="h5" className={classes.subheading}>
        Doplňujúce údaje
      </Subheading>

      <AccessoriesFieldsAdditionalInfo isMotorbike={isMotorbike} />

      <Subheading variant="h5" className={classes.subheading}>
        Poznámky
      </Subheading>

      <Field
        name="text"
        type="text"
        margin="dense"
        component={TextField}
        rows={3}
        placeholder="doplňujúce informácie..."
        multiline
        fullWidth
      />
    </>
  );
};
