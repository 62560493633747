// core
import React from 'react';

// material-ui
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

export const MileageNote = () => {
  return (
    <Box clone fontStyle="italic">
      <Typography variant="caption" color="textSecondary">
        * Uvedený stav kilometrov je len informatívny a môže sa líšiť od reálneho stavu, nakoľko sa
        na vozidle uskutočňujú skúšobné jazdy.
      </Typography>
    </Box>
  );
};
