// core
import React from 'react';

// apollo
import { useQuery } from '@apollo/client';
import categoriesQuery from '../../Cars/graphql/CategoriesQuery.graphql';
import { CategoriesQuery } from '../../Cars/graphql/CategoriesQuery';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

// components
import { GqlResponseHandler } from 'components/GqlResponseHandler';

// partials
import { CategoryCard } from '../../Cars/components/CategoryCard';

const useStyles = makeStyles((theme) => ({
  categoryCard: {
    boxShadow: 'none',
    border: 'solid 1px',
    borderColor: theme.borderColor
  }
}));

export const Categories = () => {
  const css = useStyles();

  const { data, loading, error } = useQuery<CategoriesQuery>(categoriesQuery);

  return (
    <GqlResponseHandler loading={loading} error={error}>
      {data && (
        <Grid container spacing={2}>
          {[
            {
              slug: 'zlava-tyzdna',
              name: 'Zľava týždňa',
              image: ''
            },
            ...(data.categories || [])
          ].map((cat) =>
            cat.slug === 'v-zaruke' ? null : (
              <Grid item xs={6} lg={4} key={cat.slug}>
                <CategoryCard
                  category={cat}
                  variant="big"
                  fancy={cat.slug === 'zlava-tyzdna'}
                  classes={{ root: css.categoryCard }}
                />
              </Grid>
            )
          )}
        </Grid>
      )}
    </GqlResponseHandler>
  );
};
