// core
import React, {
  useEffect,
  useState,
  createContext,
  ReactNode,
  Dispatch,
  SetStateAction
} from 'react';

export interface Props {
  children: ReactNode;
}

// keep sort also after unmount
// eslint-disable-next-line no-var
var persisted: number = 0;

export const paginationContext = createContext<{
  offset: number;
  setOffset: Dispatch<SetStateAction<number>>;
}>({
  offset: 0,
  setOffset: () => {}
});

export const PaginationContextProvider = ({ children }: Props) => {
  const [offset, setOffset] = useState<number>(persisted || 0);

  useEffect(() => {
    return () => {
      // Remember sort for the next mount
      persisted = offset;
    };
  }, [offset]);

  const Provider = paginationContext.Provider;

  return <Provider value={{ offset, setOffset }}>{children}</Provider>;
};
