// core
import React from 'react';

// material
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// colors
import { blue } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    color: blue[500]
  }
}));

export const AutoskladIcon = () => {
  const classes = useStyles();

  return (
    <Typography variant="h6" title="Auto-Sklad.sk" className={classes.root}>
      A
    </Typography>
  );
};
