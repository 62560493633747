// core
import React from 'react';

// libraries
import { Helmet } from 'react-helmet';

// material
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    paddingTop: 64,
    paddingBottom: 64
  }
}));

export const Error404 = () => {
  const classes = useStyles();

  return (
    <>
      <Helmet>
        <title>404 - EDENbazar</title>
      </Helmet>

      <div className={classes.root}>
        <Typography variant="h2" component="h1" gutterBottom>
          404
        </Typography>
        <Typography variant="subtitle1" component="div">
          Stránka sa nenašla
        </Typography>
      </div>
    </>
  );
};
