// core
import React from 'react';

// apollo
import { useQuery } from '@apollo/client';
import { BetterCarsQuery, BetterCarsQueryVariables } from '../graphql/BetterCarsQuery';
import query from '../graphql/BetterCarsQuery.graphql';

// material
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// components
import { GqlResponseHandler } from 'components/GqlResponseHandler';
import { CarCard } from 'components/CarCard';

export interface Props {
  id: string;
  price: number;
  category: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 176,
    marginBottom: 32,
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      marginTop: 160
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 128,
      marginBottom: 0
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      top: -64,
      left: -96,
      width: '30%',
      height: '55%',
      backgroundColor: theme.palette.primary.main,
      borderRadius: 4,
      zIndex: -1,
      [theme.breakpoints.down('xs')]: {
        top: -32,
        left: -32,
        width: '45%',
        height: 300
      }
    }
  },
  heading: {
    fontWeight: 'bold',
    marginLeft: 64,
    marginBottom: 48,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 32,
      fontSize: '2em'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.8em'
    }
  },
  cardsWrap: {
    '& > div': {
      paddingTop: '0 !important'
    }
  }
}));

export const BetterCars = ({ id, price, category }: Props) => {
  const classes = useStyles();

  const { data, loading, error } = useQuery<BetterCarsQuery, BetterCarsQueryVariables>(query, {
    variables: { price, category }
  });

  let cars = data?.cars || [];
  cars = cars.filter((car) => car.id !== id).slice(0, 3);

  return (
    <GqlResponseHandler loading={loading} error={error}>
      {cars && (
        <div className={classes.root}>
          <Typography variant="h3" component="h1" className={classes.heading}>
            Doprajte si viac
          </Typography>

          <Grid container spacing={4} className={classes.cardsWrap}>
            {cars.map((car, key) => (
              <Grid key={key} item xs={12} md={4}>
                <CarCard car={car} baselinePrice={price} />
              </Grid>
            ))}
          </Grid>
        </div>
      )}
    </GqlResponseHandler>
  );
};
