// core
import React, { ReactNode } from 'react';

// formik
import { useField } from 'formik';

// material
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

export interface Props {
  name: string;
  label: ReactNode;
  value: string;
}

const useStyles = makeStyles((theme) => ({
  label: {
    marginLeft: -8
  },
  checkbox: {
    padding: 4,
    marginRight: 8
  }
}));

export const CarFilterCheckbox = ({ name, label, value }: Props) => {
  const classes = useStyles();

  const [field] = useField({ type: 'checkbox', name, value });

  return (
    <FormControlLabel
      label={label}
      control={<Checkbox color="primary" size="small" className={classes.checkbox} {...field} />}
      className={classes.label}
    />
  );
};
