// core
import React, { useEffect, useCallback } from 'react';

// libraries
import { Helmet } from 'react-helmet';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

// material
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

// material icons
import CarIcon from '@material-ui/icons/DirectionsCarOutlined';
import OfferIcon from '@material-ui/icons/EuroOutlined';
import ConfirmIcon from '@material-ui/icons/DoneAllOutlined';
import DocsIcon from '@material-ui/icons/FileCopyOutlined';
import CoffeeIcon from '@material-ui/icons/FreeBreakfastOutlined';
import CheckIcon from '@material-ui/icons/CheckCircleOutlined';
import PhoneIcon from '@material-ui/icons/PhoneOutlined';
import EmailIcon from '@material-ui/icons/EmailOutlined';

// components
import { Breadcrumbs, renderBreadcrumbs } from 'components/Breadcrumbs';
import { ContentWrap } from 'components/ContentWrap';
import { PageHeading } from 'components/PageHeading';
import { Timeline } from 'components/Timeline';

// assets
import imageFacebook from '../../images/EDENbazar-facebook.png';
import image1 from '../../images/about_edenbazar_1.png';
import image2 from '../../images/about_edenbazar_2.png';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 64,
    paddingBottom: 64
  },
  heading: {
    marginTop: 64,
    marginBottom: 24,
    [theme.breakpoints.down('sm')]: {
      marginTop: 48,
      fontSize: '1.6em'
    }
  },
  imageWrap: {
    position: 'relative',
    textAlign: 'right',
    marginTop: 32,
    marginBottom: 64
  },
  image1: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '70%',
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      position: 'static',
      marginRight: 32
    }
  },
  image2: {
    position: 'absolute',
    top: 120,
    left: 90,
    width: '53%',
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      top: '40%',
      left: 'auto',
      right: '45%'
    }
  },
  box: {
    marginTop: 64,
    backgroundColor: theme.backgroundColor,
    padding: 32,
    borderRadius: 4,
    '& $heading': {
      marginTop: 8
    },
    '& $list': {
      marginBottom: 0,
      padding: 0
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 16,
      paddingRight: 16
    }
  },
  list: {
    paddingBottom: 32,
    '& > li > div:first-child': {
      color: theme.palette.primary.main,
      marginRight: theme.spacing(3),
      minWidth: 'auto'
    }
  },
  simpleList: {
    listStyle: 'none',
    '& li::before': {
      content: "'\\2022'",
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      display: 'inline-block',
      fontSize: '1.8em',
      verticalAlign: 'top',
      lineHeight: '0.8em',
      width: '0.7em',
      marginLeft: '-0.7em'
    }
  },
  contactWrap: {
    marginTop: 64
  },
  contact: {
    marginTop: 32,
    '& > div': {
      whiteSpace: 'nowrap',
      marginBottom: 8,
      display: 'inline-flex',
      alignItems: 'center',
      '& > svg': {
        marginRight: 8
      }
    },
    '& > div:first-child': {
      marginRight: 32
    }
  }
}));

export const Funding = () => {
  const sendDataToGTM = useGTMDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handlePhoneLinkClick = useCallback(() => {
    sendDataToGTM({
      event: 'event',
      eventCategory: 'PhoneLink',
      eventAction: 'Click',
      eventValue: undefined,
      eventLabel: 'Funding'
    });
  }, [sendDataToGTM]);

  const handleEmailLinkClick = useCallback(() => {
    sendDataToGTM({
      event: 'event',
      eventCategory: 'EmailLink',
      eventAction: 'Click',
      eventValue: undefined,
      eventLabel: 'Funding'
    });
  }, [sendDataToGTM]);

  const classes = useStyles();

  const breadcrumbs = [
    {
      label: 'Financovanie'
    }
  ];

  return (
    <>
      <Helmet>
        <title>Financovanie vozidiel - EDENbazar</title>
        <meta
          name="description"
          content="Pre našich zákazníkov dokáže vybaviť aj zvýhodnené financovanie vozidiel. U nás je to jednoduché a rýchle."
        />
        <meta property="og:url" content={`${global.baseUrl}/financovanie`} />
        <meta property="og:title" content="EDENbazar - Financovanie vozidiel" />
        <meta
          property="og:description"
          content="Pre našich zákazníkov dokáže vybaviť aj zvýhodnené financovanie vozidiel. U nás je to jednoduché a rýchle."
        />
        <meta
          property="og:image"
          content={`${global.baseUrl.replace('https', 'http')}${imageFacebook}`}
        />
        <meta property="og:image:secure_url" content={`${global.baseUrl}${imageFacebook}`} />
      </Helmet>

      <Breadcrumbs items={breadcrumbs}>
        {(items, c) => <ContentWrap>{renderBreadcrumbs(items, c)}</ContentWrap>}
      </Breadcrumbs>

      <ContentWrap className={classes.root}>
        <PageHeading>Financovanie vozidiel</PageHeading>

        <Typography variant="subtitle1" component="p">
          Prefinancovať Vaše nové vozidlo je u nás jednoduché a rýchle.
          <br />
          Na predloženie ponuky nám stačí pár minút a financovanie máte schválené štandardne do
          dvoch hodín.
        </Typography>

        <Typography variant="h4" component="h2" className={classes.heading}>
          Z praxe je postup nasledovný
        </Typography>

        <Grid container spacing={0}>
          <Grid item xs={12} md={6}>
            <Timeline
              items={[
                {
                  icon: <CarIcon />,
                  title: 'vyberiete si auto',
                  subtitle: '20 minút vrátane skúšobnej jazdy'
                },
                {
                  icon: <OfferIcon />,
                  title: 'predložíme Vám ponuku financovania',
                  subtitle: '5 minút'
                },
                {
                  icon: <ConfirmIcon />,
                  title: 'odsúhlasíme si výšku akontácie a dĺžku splácania',
                  subtitle: '5 minút'
                },
                {
                  icon: <DocsIcon />,
                  title: 'dáme dokopy základné podklady k schvaľovaniu',
                  subtitle: '5 minút'
                },
                {
                  icon: <CoffeeIcon />,
                  title: 'a pritom všetkom si môžeme dať kávu :)'
                }
              ]}
            />
          </Grid>
          <Grid item xs={12} md={6} className={classes.imageWrap}>
            <img src={image1} alt="EDENbazar" className={classes.image1} width="500" height="350" />
            <img src={image2} alt="EDENbazar" className={classes.image2} width="290" height="187" />
          </Grid>
        </Grid>

        <Typography variant="subtitle1" component="p">
          A môžete odísť s príjemným pocitom, že Vás zbytočne nezdržiavame. Ďalšia komunikácia bude
          emailom a telefonom. Dáme vám vedieť o úspešnosti schvaľovania a dohodneme si termín kedy
          prídete k nám na podpis zmlúv a prevziať si vozidlo. Nič zložité, netreba sa báť že to
          bude trvať týždne.
        </Typography>

        <div className={classes.box}>
          <Typography variant="h4" component="h2" className={classes.heading}>
            Podmienky na získanie úveru / leasingu
            <br />
            <small>pre súkromné osoby a firmy</small>
          </Typography>

          <List component="ul" className={classes.list}>
            <ListItem>
              <ListItemIcon>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText primary="od 15% akontácie schválený úver/leasing takmer okamžite" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText primary="možná aj 0% akontácia na vozidlá do 10 000 eur, po splnení bonity" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText primary="dĺžka splácania až do 8 rokov" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText primary="maximálny vek žiadateľa 74 rokov" />
            </ListItem>
          </List>
        </div>

        <Typography variant="h4" component="h2" className={classes.heading}>
          Čo potrebujete doložiť
        </Typography>

        <Grid container spacing={0}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" component="h3" gutterBottom>
              Fyzické osoby:
            </Typography>

            <Typography variant="subtitle1" component="ul" className={classes.simpleList}>
              <li>občiansky a vodičský preukaz</li>
              <li>
                nie je potrebné potvrdenie o príjme v prípade, ak je príjem overiteľný v sociálnej
                poisťovni
              </li>
              <li>vypíšete iba žiadosť o úver</li>
              <li>výpis z účtu za posledné 3 mesiace</li>
            </Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="h6" component="h3" gutterBottom>
              Právnické osoby:
            </Typography>

            <Typography variant="subtitle1" component="ul" className={classes.simpleList}>
              <li>občiansky a vodičský preukaz konateľa spoločnosti / splnomocnenca</li>
              <li>číslo bankového účtu firmy</li>
              <li>
                nie sú potrebné finančné výkazy ani výpis z ORSR (iba ak žiadate o akontáciu pod
                15%)
              </li>
            </Typography>
          </Grid>
        </Grid>

        <div className={classes.contactWrap}>
          <Typography variant="body2" component="p" gutterBottom>
            Kontaktujte nás s vašimi požiadavkami a my Vám zašleme našu najlepšiu ponuku e-mailom.
          </Typography>

          <div className={classes.contact}>
            <Typography variant="body1" component="div">
              <PhoneIcon />
              <Link href="tel:+421940509689" color="textPrimary" onClick={handlePhoneLinkClick}>
                +421 940 509 689
              </Link>
            </Typography>

            <Typography variant="body1" component="div">
              <EmailIcon />
              <Link href="mailto:info@edenbazar.sk" onClick={handleEmailLinkClick}>
                info@edenbazar.sk
              </Link>
            </Typography>
          </div>
        </div>
      </ContentWrap>
    </>
  );
};
