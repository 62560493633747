// core
import React, { memo, useMemo } from 'react';

// formik
import { FastField as Field } from 'formik';
import { CheckboxWithLabel } from 'formik-material-ui';

export interface Props {
  checked: boolean;
  className?: string;
}

export const MetallicField = memo(function MetallicField({ checked, className }: Props) {
  const labelProps = useMemo(
    () => ({
      label: 'metalíza',
      className: className,
      style: { marginTop: 12 }
    }),
    [className]
  );

  return (
    <Field
      name="metallic"
      component={CheckboxWithLabel}
      type="checkbox"
      checked={checked}
      color="primary"
      Label={labelProps}
    />
  );
});
