// core
import React, { forwardRef, Ref } from 'react';

// formik
import { useField } from 'formik';

// apollo
import carsFilterOptionsQuery from '../graphql/CarsFilterOptionsQuery.graphql';
import { CarsFilterOptionsQuery } from '../graphql/CarsFilterOptionsQuery';

// material
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

// icons
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

// components
import { LoadSelectOptions } from 'components/LoadSelectOptions';

export interface Props {
  onLoad: (data: CarsFilterOptionsQuery) => void;
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: 12,
    color: theme.palette.primary.main
  }
}));

export const CarsFilterManufacturer = forwardRef(function CarsFilterManufacturer(
  { onLoad, className }: Props,
  ref: Ref<HTMLInputElement>
) {
  const css = useStyles();

  const [field] = useField('manufacturer');

  return (
    <LoadSelectOptions
      id="manufacturer"
      query={carsFilterOptionsQuery}
      sort={(a, b) => a.label.localeCompare(b.label)}
      onLoad={onLoad}
    >
      <TextField
        select
        label="Výrobca"
        InputLabelProps={{
          shrink: !!field.value
        }}
        SelectProps={{
          IconComponent: KeyboardArrowDownIcon,
          classes: {
            icon: css.icon
          }
        }}
        inputProps={{
          ref
        }}
        variant="outlined"
        margin="dense"
        className={className}
        fullWidth
        {...field}
      />
    </LoadSelectOptions>
  );
});
