// core
import React, { memo, ReactNode, useMemo } from 'react';

// formik
import { FastField as Field } from 'formik';
import { CheckboxWithLabel } from 'formik-material-ui';

// material
import { makeStyles } from '@material-ui/core/styles';

export interface Props {
  name: string;
  value: string;
  label: string;
  appendFields?: () => ReactNode;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  checkbox: { padding: 4 },
  label: { margin: 0 }
}));

export const AccessoriesField = memo(function AccessoriesField({
  name,
  value,
  label,
  appendFields
}: Props) {
  const classes = useStyles();

  const labelProps = useMemo(
    () => ({
      label: label,
      className: classes.label
    }),
    [label, classes.label]
  );

  return (
    <div className={classes.root}>
      <Field
        indeterminate={false}
        component={CheckboxWithLabel}
        type="checkbox"
        color="primary"
        Label={labelProps}
        className={classes.checkbox}
        name={name}
        value={value}
      />

      {typeof appendFields === 'function' ? appendFields() : null}
    </div>
  );
});
