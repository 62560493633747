import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';

declare module '@material-ui/core/styles/createTheme' {
  interface ThemeOptions {
    backgroundColor: string;
    borderColor: string;
  }

  interface Theme {
    backgroundColor: string;
    borderColor: string;
  }
}

const backgroundColor = '#f1f2f6';
const borderColor = '#e3e4e8';

export default responsiveFontSizes(
  createTheme({
    typography: {
      fontFamily: 'Montserrat, Arial, sans-serif;'
    },
    palette: {
      primary: { main: '#D82435' },
      secondary: { main: '#53555F' },
      background: {
        default: '#fff'
      },
      text: {
        primary: '#333'
      },
      action: {
        hoverOpacity: 0.05
      }
    },
    overrides: {
      MuiCard: {
        root: {
          boxShadow: '0px 1px 1px 0px #d2d5e0'
        }
      },
      MuiButton: {
        root: {
          paddingTop: 12,
          paddingBottom: 12,
          paddingLeft: 24,
          paddingRight: 24,
          '&$sizeLarge': {
            paddingTop: 16,
            paddingBottom: 16,
            paddingLeft: 32,
            paddingRight: 32
          },
          '&$contained': {
            boxShadow: '0px 1px 0px 0px #ccc'
          },
          '&$containedPrimary': {
            boxShadow: '0px 1px 0px 0px #b50014'
          }
        }
      },
      MuiTypography: {
        subtitle2: {
          fontWeight: 700
        },
        h6: {
          fontWeight: 700
        }
      },
      MuiAccordion: {
        root: {
          boxShadow: 'none',
          backgroundColor: backgroundColor,
          '&:before': {
            backgroundColor: borderColor
          }
        },
        rounded: {
          '&:first-child': {
            borderTopLeftRadius: 6,
            borderTopRightRadius: 6
          },
          '&:last-child': {
            borderBottomLeftRadius: 6,
            borderBottomRightRadius: 6
          }
        }
      },
      MuiAccordionSummary: {
        root: {
          paddingTop: 8,
          paddingBottom: 8,
          '&$expanded': {
            borderBottomStyle: 'solid',
            borderBottomWidth: 1,
            borderBottomColor: borderColor
          }
        }
      },
      MuiFilledInput: {
        root: {
          backgroundColor: 'white'
        }
      },
      MuiDialog: {
        paper: {
          backgroundColor: borderColor
        }
      }
    },
    props: {
      MuiWithWidth: {
        initialWidth: 'lg'
      }
    },
    backgroundColor,
    borderColor
  })
);
