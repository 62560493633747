// core
import React, { ReactNode } from 'react';

// libraries
import classnames from 'classnames';
import { Link } from 'react-router-dom';

// material
import UILink from '@material-ui/core/Link';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// material icons
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import HomeIcon from '@material-ui/icons/HomeOutlined';

export interface BreadcrumbsItem {
  label: ReactNode;
  to?: string;
}

export interface Props {
  items: Array<BreadcrumbsItem>;
  children?: (items: Array<BreadcrumbsItem>, classes: any) => ReactNode;
  className?: any;
  classes?: any;
}

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 16,
    paddingBottom: 16,
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: theme.borderColor,
    backgroundColor: theme.backgroundColor
  },
  itemsWrap: {
    display: 'flex',
    alignItems: 'center',
    height: 36,
    maxWidth: '100%',
    '& > span': {
      lineHeight: '100%'
    }
  },
  homeLink: {
    color: theme.palette.text.secondary,
    height: 24,
    lineHeight: '24px'
  },
  link: {
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  icon: {
    color: theme.palette.text.secondary,
    width: 16,
    height: 16,
    marginLeft: 12,
    marginRight: 12
  }
}));

export const renderBreadcrumbs = (items: Array<BreadcrumbsItem>, classes: any) => {
  return (
    <div className={classes.itemsWrap}>
      <UILink variant="caption" component={Link} to="/" className={classes.homeLink}>
        <Hidden xsDown>Úvod</Hidden>

        <Hidden smUp>
          <HomeIcon />
        </Hidden>
      </UILink>

      <ArrowForwardIosIcon className={classes.icon} />

      {items.map((item, key) => (
        <React.Fragment key={key}>
          {item.to ? (
            <UILink
              id={`breadcrumb${key}`} // add ids because when using google translate then links are not properly placed
              variant="caption"
              component={Link}
              to={item.to}
              className={classes.link}
            >
              {item.label}
            </UILink>
          ) : (
            <Typography variant="caption" className={classes.link}>
              {item.label}
            </Typography>
          )}

          {key < items.length - 1 ? <ArrowForwardIosIcon className={classes.icon} /> : null}
        </React.Fragment>
      ))}
    </div>
  );
};

export const Breadcrumbs = ({ items, children, className }: Props) => {
  const classes = useStyles();

  return (
    <div className={classnames(classes.root, className)}>
      {typeof children === 'function'
        ? children(items, classes)
        : renderBreadcrumbs(items, classes)}
    </div>
  );
};
