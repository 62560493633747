// core
import React from 'react';

// libraries
import classnames from 'classnames';

// material
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';

export interface Props {
  classes?: {
    root?: string;
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    height: theme.spacing(3),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

export const LoadingDiv = ({ classes = {} }: Props) => {
  const css = useStyles();

  return <Skeleton animation="wave" classes={{}} className={classnames(css.root, classes.root)} />;
};
