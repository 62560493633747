// core
import React, { useEffect, useCallback } from 'react';

// libraries
import { Helmet } from 'react-helmet';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

// assets
import ogImage from '../../images/og-about.jpg';
import image1 from '../../images/o-nas-foto1.jpg';
import image2 from '../../images/o-nas-foto2.jpg';

// components
import { Breadcrumbs, renderBreadcrumbs } from 'components/Breadcrumbs';
import { ContentWrap } from 'components/ContentWrap';
import { PageHeading } from 'components/PageHeading';

// material
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

// material icons
import CheckIcon from '@material-ui/icons/CheckCircleOutlined';
import PhoneIcon from '@material-ui/icons/PhoneOutlined';
import EmailIcon from '@material-ui/icons/EmailOutlined';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 64,
    paddingBottom: 64
  },
  introWrap: {
    marginBottom: 48
  },
  imageWrap: {
    position: 'relative',
    top: -50,
    [theme.breakpoints.down('sm')]: {
      top: 0,
      marginTop: 32,
      marginBottom: 128,
      textAlign: 'right'
    }
  },
  image1: {
    position: 'absolute',
    top: 140,
    left: 100,
    width: '53%',
    height: 'auto',
    borderRadius: 6,
    borderColor: '#c4bbb4',
    borderWidth: 1,
    borderStyle: 'solid',
    [theme.breakpoints.down('sm')]: {
      top: '65%',
      left: 'auto',
      right: '35%'
    }
  },
  image2: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '70%',
    height: 'auto',
    borderRadius: 6,
    [theme.breakpoints.down('sm')]: {
      position: 'static',
      marginRight: 32
    }
  },
  advantagesWrap: {
    marginTop: 64
  },
  heading: {
    marginBottom: 16
  },
  list: {
    marginBottom: 16,
    '& > li > div:first-child': {
      color: theme.palette.primary.main,
      marginRight: 0
    }
  },
  contactWrap: {
    marginTop: 32
  },
  contact: {
    marginTop: 32,
    '& > div': {
      whiteSpace: 'nowrap',
      marginBottom: 8,
      display: 'inline-flex',
      alignItems: 'center',
      '& > svg': {
        marginRight: 8
      }
    },
    '& > div:first-child': {
      marginRight: 32
    }
  }
}));

export const About = () => {
  const sendDataToGTM = useGTMDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const classes = useStyles();

  const handlePhoneLinkClick = useCallback(() => {
    sendDataToGTM({
      event: 'event',
      eventCategory: 'PhoneLink',
      eventAction: 'Click',
      eventValue: undefined,
      eventLabel: 'About'
    });
  }, [sendDataToGTM]);

  const handleEmailLinkClick = useCallback(() => {
    sendDataToGTM({
      event: 'event',
      eventCategory: 'EmailLink',
      eventAction: 'Click',
      eventValue: undefined,
      eventLabel: 'About'
    });
  }, [sendDataToGTM]);

  const breadcrumbs = [
    {
      label: 'O nás'
    }
  ];

  return (
    <>
      <Helmet>
        <title>O nás - EDENbazar</title>
        <meta
          name="description"
          content="Sme na trhu s jazdenými vozidlami už od roku 2009 a vieme čo požadujete a čo vám chýba. Preto autá v našej ponuke musia spĺňať prísne kritéria na stav, majú málo kilometrov a 100% overiteľnú históriu a pôvod."
        />
        <meta property="og:url" content={`${global.baseUrl}/o-nas`} />
        <meta property="og:title" content="EDENbazar - O nás" />
        <meta
          property="og:description"
          content="Sme na trhu s jazdenými vozidlami už od roku 2009 a vieme čo požadujete a čo vám chýba. Preto autá v našej ponuke musia spĺňať prísne kritéria na stav, majú málo kilometrov a 100% overiteľnú históriu a pôvod."
        />
        <meta
          property="og:image"
          content={`${global.baseUrl.replace('https', 'http')}${ogImage}`}
        />
        <meta property="og:image:secure_url" content={`${global.baseUrl}${ogImage}`} />
      </Helmet>

      <Breadcrumbs items={breadcrumbs}>
        {(items, c) => <ContentWrap>{renderBreadcrumbs(items, c)}</ContentWrap>}
      </Breadcrumbs>

      <ContentWrap className={classes.root}>
        <PageHeading>EDENbazar</PageHeading>

        <Grid container spacing={0} className={classes.introWrap}>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" component="p" gutterBottom>
              Zameriavame sa na riešenie komplexnej mobility pre firemnú klientelu formou
              operatívneho leasingu alebo dlhodobého nájmu. Autá{' '}
              <strong>kupujeme na Slovensku</strong> ako <strong>úplne nové</strong> od oficiálnych
              predajcov a prevažne <strong>po roku</strong> používania vozidlá posúvame ďalej na
              predaj.
            </Typography>

            <Typography variant="subtitle1" component="p" gutterBottom>
              Prvý rok klesá hodnota vozidla najviac, a preto naše autá majú veľmi{' '}
              <strong>priaznivé ceny</strong> a sú stále vo veľmi <strong>dobrom stave</strong>.
              Väčšina vozidiel je ešte <strong>vo fabrickej záruke</strong> a teda nič neriskujte
              ani Vy, ale ani my.
            </Typography>

            <Typography variant="subtitle1" component="p" gutterBottom>
              O našich vozidlách vieme úplne všetko. Preto ak bolo na vozidle niečo opravované,
              lakované alebo menené, vieme Vám presne povedať čo to bolo a prečo. Tieto servisné
              úkony máme aj takmer vždy fotograficky zdokumentované.
            </Typography>
          </Grid>

          <Grid item xs={12} md={6} className={classes.imageWrap}>
            <img src={image2} alt="EDENbazar" className={classes.image2} width="650" height="365" />
            <img src={image1} alt="EDENbazar" className={classes.image1} width="496" height="314" />
          </Grid>
        </Grid>

        <Typography variant="subtitle1" component="p" gutterBottom>
          Sme na trhu už <strong>od roku 2009</strong> a vieme čo požadujete a čo vám chýba. Preto v
          našej ponuke nájdete aj niekoľko vozidiel, ktoré vydražíme v aukciách nami overených
          partnerov. Tieto autá môžu byť aj trochu staršie ako u nás 1-2 roky, ale musia spĺňať naše{' '}
          <strong>prísne kritériá na kvalitu</strong>. Dbáme na to, aby mali{' '}
          <strong>málo najazdených kilometrov</strong> a <strong>100% overiteľnú históriu</strong> a
          pôvod. Všetky nami predávané vozidlá boli{' '}
          <strong>servisované v autorizovaných servisoch</strong>. Nevykupujeme jazdené vozidlá od
          neoverených dodávateľov, preto sa nemôže stať, že u nás skončí nejaké pofidérne auto.
        </Typography>

        <Grid container spacing={0} className={classes.advantagesWrap}>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" component="h2" className={classes.heading}>
              Predaj vozidiel
            </Typography>

            <List component="ul" className={classes.list}>
              <ListItem>
                <ListItemIcon>
                  <CheckIcon />
                </ListItemIcon>
                <ListItemText primary="málo jazdené vozidlá" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckIcon />
                </ListItemIcon>
                <ListItemText primary="vo fabrickej záruke, alebo úplne nové" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckIcon />
                </ListItemIcon>
                <ListItemText primary="s dokladovateľnou históriou servisu a pôvodu" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckIcon />
                </ListItemIcon>
                <ListItemText primary="po prvom majiteľovi" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckIcon />
                </ListItemIcon>
                <ListItemText primary="vybavenie do 1 hodiny" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckIcon />
                </ListItemIcon>
                <ListItemText primary="jasne zrozumiteľná zmluva" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckIcon />
                </ListItemIcon>
                <ListItemText primary="možnosť financovania a poistenia vozidla" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckIcon />
                </ListItemIcon>
                <ListItemText primary="nič neriskujete" />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" component="h2" className={classes.heading}>
              Pre firmy
            </Typography>

            <List component="ul" className={classes.list}>
              <ListItem>
                <ListItemIcon>
                  <CheckIcon />
                </ListItemIcon>
                <ListItemText primary="vieme zabezpečiť väčší počet rovnakých vozidiel" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckIcon />
                </ListItemIcon>
                <ListItemText primary="v záruke priamo od výrobcu" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckIcon />
                </ListItemIcon>
                <ListItemText primary="jazdené do dvoch rokov, ale aj nové" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckIcon />
                </ListItemIcon>
                <ListItemText primary="celú flotilu prefinancujeme a poistíme" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckIcon />
                </ListItemIcon>
                <ListItemText primary="dohodneme Vám vyhodné servisné zmluvy v autorizovaných servisoch" />
              </ListItem>
            </List>

            <Typography variant="subtitle1" component="p" gutterBottom>
              ... a veľa ďalších výhod, ktoré Vám poskytneme podľa Vašich potrieb.
            </Typography>

            <div className={classes.contactWrap}>
              <Typography variant="body2" component="p" gutterBottom>
                Kontaktujte nás s vašimi požiadavkami a my Vám zašleme našu najlepšiu ponuku
                e-mailom.
              </Typography>

              <div className={classes.contact}>
                <Typography variant="body1" component="div">
                  <PhoneIcon />
                  <Link href="tel:+421940509689" color="textPrimary" onClick={handlePhoneLinkClick}>
                    +421 940 509 689
                  </Link>
                </Typography>

                <Typography variant="body1" component="div">
                  <EmailIcon />
                  <Link href="mailto:info@edenbazar.sk" onClick={handleEmailLinkClick}>
                    info@edenbazar.sk
                  </Link>
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>
      </ContentWrap>
    </>
  );
};
