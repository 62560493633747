// core
import React, { useCallback } from 'react';

// apollo
import { useMutation } from '@apollo/client';
import createCarMutation from '../graphql/CreateCarMutation.graphql';
import editCarMutation from '../graphql/EditCarMutation.graphql';
import createTemplateMutation from '../graphql/CreateTemplateMutation.graphql';
import editTemplateMutation from '../graphql/EditTemplateMutation.graphql';
import templatesQuery from '../graphql/SelectTemplateOptionsQuery.graphql';
import { CreateCarMutation, CreateCarMutationVariables } from '../graphql/CreateCarMutation';
import { EditCarMutation, EditCarMutationVariables } from '../graphql/EditCarMutation';
import {
  CreateTemplateMutation,
  CreateTemplateMutationVariables
} from '../graphql/CreateTemplateMutation';
import {
  EditTemplateMutation,
  EditTemplateMutationVariables
} from '../graphql/EditTemplateMutation';
import { SelectTemplateOptionsQuery } from '../graphql/SelectTemplateOptionsQuery';
import { CarDetailsFormFragment } from '../graphql/CarDetailsFormFragment';
import { CarDetailsFormTemplateFragment } from '../graphql/CarDetailsFormTemplateFragment';

// components
import { useSnackbar } from 'components/Snackbar';

// formik
import { Formik } from 'formik';
import * as yup from 'yup';

// libraries
import { useHistory } from 'react-router-dom';

// helpers
import { resizeImage } from 'helpers/imageHelper';

// partials
import { CarDetailsFormComposer } from './CarDetailsFormComposer';

yup.setLocale({
  mixed: {
    notType: 'Pole je povinné',
    required: 'Pole je povinné'
  },
  number: {
    positive: 'Hodnota musí byť kladné číslo'
  }
});

export const isMotorbike = (manufacturer: string) => {
  return manufacturer === '192107';
};

const schema = yup
  .object()
  .noUnknown()
  .shape({
    saveType: yup.string().defined(),
    active: yup.boolean().when('saveType', {
      is: (val: string) => val === 'car',
      then: yup.boolean().default(true).defined(),
      otherwise: yup.boolean().nullable()
    }),
    name: yup.string().when('saveType', {
      is: (val: string) => val === 'template',
      then: yup.string().ensure().required().defined(),
      otherwise: yup.string().nullable()
    }),
    manufacturer: yup
      .string()
      .ensure()
      .when('saveType', {
        is: (val: string) => val === 'car',
        then: yup.string().required()
      }),
    model: yup
      .string()
      .ensure()
      .when('saveType', {
        is: (val: string) => val === 'car',
        then: yup.string().required()
      })
      .defined(),
    title: yup
      .string()
      .ensure()
      .when('saveType', {
        is: (val: string) => val === 'car',
        then: yup.string().required()
      })
      .defined(),
    categories: yup
      .array()
      .ensure()
      .of(
        yup
          .string()
          .ensure()
          .when('saveType', {
            is: (val: string) => val === 'car',
            then: yup.string().required()
          })
          .defined()
      )
      .defined(),
    year: yup
      .string()
      .ensure()
      .when('saveType', {
        is: (val: string) => val === 'car',
        then: yup.string().required()
      })
      .defined(),
    month: yup.string().ensure().defined(),
    state: yup
      .string()
      .ensure()
      .when('saveType', {
        is: (val: string) => val === 'car',
        then: yup.string().required()
      })
      .defined(),
    mileage: yup
      .number()
      .default(null)
      .when('saveType', {
        is: (val: string) => val === 'car',
        then: yup.number().required().positive().integer(),
        otherwise: yup.number().nullable()
      })
      .defined(),
    price: yup
      .number()
      .default(null)
      .when('saveType', {
        is: (val: string) => val === 'car',
        then: yup.number().required().positive(),
        otherwise: yup.number().nullable()
      })
      .defined(),
    vatDeduction: yup.boolean().default(false).defined(),
    discount: yup
      .number()
      .default(0)
      .when('saveType', {
        is: (val: string) => val === 'car',
        then: yup.number(),
        otherwise: yup.number().nullable()
      })
      .defined(),
    discountOfWeek: yup.boolean().when('saveType', {
      is: (val: string) => val === 'car',
      then: yup.boolean().default(false).defined(),
      otherwise: yup.boolean().nullable()
    }),
    automaticDiscount: yup.boolean().when('saveType', {
      is: (val: string) => val === 'car',
      then: yup.boolean().default(false).defined(),
      otherwise: yup.boolean().nullable()
    }),
    automaticDiscountStartDate: yup.string().ensure().defined(),
    body: yup
      .string()
      .ensure()
      .when(['saveType', 'manufacturer'], {
        is: (saveType: string, manufacturer: string) =>
          saveType === 'car' && !isMotorbike(manufacturer),
        then: yup.string().required()
      })
      .defined(),
    vin: yup.string().ensure().defined(),
    color: yup.string().ensure().defined(),
    metallic: yup.boolean().default(false).defined(),
    numDoors: yup
      .string()
      .ensure()
      .when(['saveType', 'manufacturer'], {
        is: (saveType: string, manufacturer: string) =>
          saveType === 'car' && !isMotorbike(manufacturer),
        then: yup.string().required()
      })
      .defined(),
    numSeats: yup
      .string()
      .ensure()
      .when(['saveType', 'manufacturer'], {
        is: (saveType: string, manufacturer: string) =>
          saveType === 'car' && !isMotorbike(manufacturer),
        then: yup.string().required()
      })
      .defined(),
    fuel: yup
      .string()
      .ensure()
      .when('saveType', {
        is: (val: string) => val === 'car',
        then: yup.string().required()
      })
      .defined(),
    emissionClass: yup.string().ensure().defined(),
    engineVolume: yup
      .number()
      .default(null)
      .when('fuel', {
        is: '6',
        then: yup.number().positive().required(),
        otherwise: yup.number().nullable()
      })
      .defined(),
    enginePower: yup
      .number()
      .default(null)
      .when('saveType', {
        is: (val: string) => val === 'car',
        then: yup.number().positive().required(),
        otherwise: yup.number().nullable()
      })
      .defined(),
    gearbox: yup
      .string()
      .ensure()
      .when('saveType', {
        is: (val: string) => val === 'car',
        then: yup.string().required()
      })
      .defined(),
    drive: yup
      .string()
      .ensure()
      .when(['saveType', 'manufacturer'], {
        is: (saveType: string, manufacturer: string) =>
          saveType === 'car' && !isMotorbike(manufacturer),
        then: yup.string().required()
      })
      .defined(),
    consumptionCity: yup.string().ensure().defined(),
    consumptionOutside: yup.string().ensure().defined(),
    consumptionCombined: yup.string().ensure().defined(),
    technicalControl: yup.string().ensure().defined(),
    emissionControl: yup.string().ensure().defined(),
    safetyEquipment: yup.array().of(yup.string().ensure().defined()).ensure().defined(),
    comfortEquipment: yup.array().of(yup.string().ensure().defined()).ensure().defined(),
    otherEquipment: yup.array().of(yup.string().ensure().defined()).ensure().defined(),
    highlights: yup.array().of(yup.string().ensure().defined()).ensure().defined(),
    airbags: yup.array().of(yup.string().ensure().defined()).ensure().defined(),
    carRadio: yup.string().ensure().defined(),
    airConditioning: yup.string().ensure().defined(),
    electricalWindows: yup.string().ensure().defined(),
    parkingSensors: yup.string().ensure().defined(),
    additionalInfo: yup.array().of(yup.string().ensure().defined()).ensure().defined(),
    text: yup.string().ensure().defined(),
    images: yup.array().when('saveType', {
      is: (val: string) => val === 'car',
      then: yup
        .array()
        .of(yup.mixed())
        .required('Nahrajte aspoň jednu fotografiu vozidla')
        .defined(),
      otherwise: yup.array().nullable()
    }),
    authenticityReport: yup
      .mixed()
      .default('')
      .when('saveType', {
        is: (val: string) => val === 'car',
        then: yup.mixed().defined(),
        otherwise: yup.mixed().nullable().default(null)
      }),
    attachments: yup.array().when('saveType', {
      is: (val: string) => val === 'car',
      then: yup.array().of(yup.mixed()).ensure().defined(),
      otherwise: yup.array().nullable()
    }),
    autoskladActive: yup.boolean().when('saveType', {
      is: (val: string) => val === 'car',
      then: yup.boolean().default(true).defined(),
      otherwise: yup.boolean().nullable()
    }),
    autoskladPrice: yup.number().positive().nullable().default(null).defined(),
    autoskladValidUntil: yup.string().ensure().defined()
  })
  .defined();

export type CarDetailsFormValues = yup.InferType<typeof schema>;

export interface Props {
  data: CarDetailsFormFragment | CarDetailsFormTemplateFragment | null;
  isTemplate: boolean;
  templateName?: string | null;
  onCreateTemplate?: (data: CarDetailsFormTemplateFragment) => void;
}

export const CarDetailsForm = ({ data, isTemplate, templateName, onCreateTemplate }: Props) => {
  const { pushSnack } = useSnackbar();

  const id = data?.id || null;

  const [createTemplate] = useMutation<CreateTemplateMutation, CreateTemplateMutationVariables>(
    createTemplateMutation,
    {
      update: (cache, { data }) => {
        const newData = data?.createTemplate;

        if (newData) {
          try {
            const data = cache.readQuery<SelectTemplateOptionsQuery>({
              query: templatesQuery
            });
            cache.writeQuery({
              query: templatesQuery,
              data: [...(data?.templates || []), newData]
            });

            if (typeof onCreateTemplate === 'function') {
              onCreateTemplate(newData);
            }
          } catch (error) {
            console.error(error);
          }
        }
      }
    }
  );
  const [editTemplate] = useMutation<EditTemplateMutation, EditTemplateMutationVariables>(
    editTemplateMutation
  );

  const [createCar] = useMutation<CreateCarMutation, CreateCarMutationVariables>(
    createCarMutation,
    {
      update: (cache, { data }) => {
        const newData = data?.createCar;
        if (newData) {
          cache.evict({ id: 'Car:' + newData.id });
          cache.evict({ id: 'ROOT_QUERY', fieldName: 'carsFilterOptions' });
          cache.evict({ id: 'ROOT_QUERY', fieldName: 'carsFilterBoundaries' });
          cache.evict({ id: 'ROOT_QUERY', fieldName: 'totalCars' });
          cache.gc();
        }
      }
    }
  );
  const [editCar] = useMutation<EditCarMutation, EditCarMutationVariables>(editCarMutation, {
    update: (cache, { data }) => {
      const newData = data?.editCar;
      if (newData) {
        cache.evict({ id: 'Car:' + newData.id });
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'carsFilterOptions' });
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'carsFilterBoundaries' });
        cache.gc();
      }
    }
  });

  const history = useHistory();

  const handleSubmit = useCallback(
    (
      { saveType, images, attachments, ...data }: CarDetailsFormValues,
      { setSubmitting, resetForm }
    ) => {
      setSubmitting(true);

      const saveTemplate = saveType === 'template';
      const mutation = saveTemplate
        ? id && isTemplate
          ? editTemplate
          : createTemplate
        : id && !isTemplate
        ? editCar
        : createCar;

      // remove unecessary values
      if (saveTemplate) {
        delete data.active;
        delete data.discountOfWeek;
        delete data.automaticDiscount;
        delete data.automaticDiscountStartDate;
        delete data.images;
        delete data.authenticityReport;
        delete data.attachments;
        delete data.autoskladActive;
        delete data.autoskladPrice;
        delete data.autoskladValidUntil;
      } else {
        delete data.name;
      }

      // convert values
      data.discount = (data.discount && parseInt(data.discount)) || 0;
      data.year = (data.year && parseInt(data.year)) || null;
      data.month = (data.month && parseInt(data.month)) || null;
      data.numDoors = (data.numDoors && parseInt(data.numDoors)) || null;
      data.numSeats = (data.numSeats && parseInt(data.numSeats)) || null;
      data.consumptionCity = (data.consumptionCity && parseFloat(data.consumptionCity)) || null;
      data.consumptionOutside =
        (data.consumptionOutside && parseFloat(data.consumptionOutside)) || null;
      data.consumptionCombined =
        (data.consumptionCombined && parseFloat(data.consumptionCombined)) || null;
      data.discountOfWeek = saveTemplate && !isTemplate && id ? data.discountOfWeek : undefined;
      data.automaticDiscountStartDate =
        saveTemplate && !isTemplate && id ? data.automaticDiscountStartDate : undefined;

      // prepare images
      const p = saveTemplate
        ? Promise.resolve([])
        : images
        ? Promise.all(
            images.map((file: File | string) =>
              file instanceof File ? resizeImage(file) : Promise.resolve(file)
            ) as Promise<Blob | string>[]
          )
        : Promise.resolve([]);

      p.then((images) => {
        if (!saveTemplate) {
          data.images = images;

          if (id && !isTemplate) {
            data.attachments = attachments;
          } else {
            delete data.attachments;
          }
        }

        return (
          mutation({
            variables: {
              data,
              // @ts-ignore
              id: (saveTemplate && isTemplate) || (!saveTemplate && !isTemplate) ? id : undefined
            }
          }) as Promise<any>
        ).then(({ data }: any) => {
          if (!saveTemplate) {
            const car = data.editCar || data.createCar;

            pushSnack({
              message: 'Vozidlo bolo úspešne uložené',
              type: 'success'
            });
            history.push(`/vozidla-na-predaj/${car.mainCategory.slug}/${car.slug}`);
          } else {
            pushSnack({
              message: 'Vzor bol úspešne uložený',
              type: 'success'
            });
          }
        });
      })
        .catch((e) => {
          console.error(e);
          pushSnack({
            message: 'Chyba pri ukladaní. Skúste to prosím znova.',
            type: 'error'
          });
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [history, isTemplate, id, createTemplate, editTemplate, createCar, editCar, pushSnack]
  );

  return (
    <Formik
      enableReinitialize
      validationSchema={schema}
      initialValues={schema.cast({
        ...data,
        saveType: isTemplate ? 'template' : 'car',
        name: templateName,
        // fill dates
        technicalControl:
          data?.technicalControl && new Date(data.technicalControl).toISOString().substr(0, 7),
        emissionControl:
          data?.emissionControl && new Date(data.emissionControl).toISOString().substr(0, 7),
        autoskladValidUntil:
          // @ts-ignore
          data?.autoskladValidUntil &&
          // @ts-ignore
          new Date(data.autoskladValidUntil).toISOString().substr(0, 16),
        // @ts-ignore
        attachments: !isTemplate ? data?.attachments.map(({ id }) => id) || [] : null
      })}
      onSubmit={handleSubmit}
    >
      <CarDetailsFormComposer isTemplate={isTemplate} data={data as any} />
    </Formik>
  );
};
