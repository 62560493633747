// core
import React, { memo, useMemo } from 'react';

// formik
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';

export interface Props {
  disabled?: boolean;
}

export const AutoskladValidUntilField = memo(function AutoskladValidUntilField({
  disabled
}: Props) {
  const inputProps = useMemo(
    () => ({
      shrink: true
    }),
    []
  );

  return (
    <Field
      name="autoskladValidUntil"
      type="datetime-local"
      label="Platné do"
      margin="dense"
      component={TextField}
      InputLabelProps={inputProps}
      fullWidth
      disabled={disabled}
    />
  );
});
