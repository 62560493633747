// core
import React, { useEffect } from 'react';

// libraries
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

// material
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';

// material icons
import CloseIcon from '@material-ui/icons/Close';
import PrevIcon from '@material-ui/icons/ArrowBackIos';
import NextIcon from '@material-ui/icons/ArrowForwardIos';

export interface Props {
  hasNext: boolean;
  hasPrev: boolean;
  photo: string | null;
  title: string;
  onClose: () => void;
  openPrev: () => void;
  openNext: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: '16px !important',
      marginRight: '16px !important'
    }
  },
  content: {
    padding: 0
  },
  image: {
    display: 'block',
    width: '100%',
    height: 'auto',
    maxHeight: '100%'
  },
  closeButton: {
    position: 'absolute',
    top: 8,
    right: 8,
    color: '#fff'
  },
  prevButton: {
    position: 'absolute',
    top: '50%',
    left: 8,
    marginTop: -24,
    color: '#fff'
  },
  nextButton: {
    position: 'absolute',
    top: '50%',
    right: 8,
    marginTop: -24,
    color: '#fff'
  }
}));

export const PhotoDialog = ({
  hasNext,
  hasPrev,
  photo,
  title,
  onClose,
  openNext,
  openPrev
}: Props) => {
  const classes = useStyles();
  const sendDataToGTM = useGTMDispatch();

  useEffect(() => {
    if (photo) {
      sendDataToGTM({
        event: 'modalview',
        modalName: 'photo/' + photo
      });
    }
  }, [photo, sendDataToGTM]);

  return (
    <Dialog
      open={!!photo}
      onClose={onClose}
      maxWidth="md"
      scroll="body"
      classes={{ paper: classes.root }}
    >
      <IconButton aria-label="Zatvoriť" className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>

      <DialogContent className={classes.content}>
        {photo ? (
          <img
            src={`${global.cdnUrl}/fotografia/${photo}`}
            alt={title}
            onClick={hasNext ? openNext : onClose}
            className={classes.image}
            width="1200"
            height="900"
          />
        ) : null}
      </DialogContent>

      {hasPrev ? (
        <IconButton
          aria-label="Predchádzajúca fotografia"
          className={classes.prevButton}
          onClick={openPrev}
        >
          <PrevIcon />
        </IconButton>
      ) : null}

      {hasNext ? (
        <IconButton
          aria-label="Nasledujúca fotografia"
          className={classes.nextButton}
          onClick={openNext}
        >
          <NextIcon />
        </IconButton>
      ) : null}
    </Dialog>
  );
};
