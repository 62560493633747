// core
import React, { useEffect, useState, useCallback, useRef, ChangeEvent } from 'react';

// libraries
import classnames from 'classnames';
import { useParams, useHistory, Link, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

// apollo
import { useQuery, useMutation } from '@apollo/client';
import query from './graphql/CarQuery.graphql';
import editCarMutation from '../Edit/graphql/EditCarMutation.graphql';
import removeCarMutation from './graphql/RemoveCarMutation.graphql';
import { CarQuery } from './graphql/CarQuery';
import { EditCarMutation, EditCarMutationVariables } from '../Edit/graphql/EditCarMutation';
import { RemoveCarMutation, RemoveCarMutationVariables } from './graphql/RemoveCarMutation';
// import publishToSocialNetworkMutation from './graphql/PublishToSocialNetworkMutation.graphql';
// import unpublishFromSocialNetworkMutation from './graphql/UnpublishFromSocialNetworkMutation.graphql';

// context
import { UserContext } from 'AppRouter';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import NoSsr from '@material-ui/core/NoSsr';
// import Chip from '@material-ui/core/Chip';
// import Paper from '@material-ui/core/Paper';
// import Alert from '@material-ui/lab/Alert';
// import AlertTitle from '@material-ui/lab/AlertTitle';
// import MuiLink from '@material-ui/core/Link';

// material icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/InfoOutlined';

// components
import { Loading } from 'components/Loading';
import { Price } from 'components/Price';
import { ContentWrap } from 'components/ContentWrap';
import { BreadcrumbsItem } from 'components/Breadcrumbs';
import { PageHeading } from 'components/PageHeading';
import { useSnackbar } from 'components/Snackbar';
import { GqlResponseHandler } from 'components/GqlResponseHandler';
import { EdenbazarIcon } from 'components/EdenbazarIcon';
import { AutoskladIcon } from 'components/AutoskladIcon';
import { CarHighlights } from 'components/CarHighlights';

// partials
import { CarBadge } from './components/CarBadge';
import { DiscountBadge } from './components/DiscountBadge';
import { CarYear, getYearString } from './components/CarYear';
import { CarDetailsContainer } from './components/CarDetailsContainer';
import { SendRequestDialog } from './components/SendRequestDialog';
import { PhotoDialog } from './components/PhotoDialog';
import { CarPagination } from './components/CarPagination';
import { BetterCars } from './components/BetterCars';
// import { Calculator } from './components/Calculator';
import { CarBreadcrumbs } from './components/CarBreadcrumbs';
import { ShareIcons } from './components/ShareIcons';
import { ActionButtons, ActionButtonType } from './components/ActionButtons';
import { Gallery } from './components/Gallery';
import { AuthenticityReport } from './components/AuthenticityReport';
import { MileageNote } from './components/MileageNote';
import { CarViews } from './components/CarViews';

export interface Image {
  src: string;
}

const useStyles = makeStyles((theme) => ({
  heading: {
    display: 'flex',
    alignItems: 'flex-start',
    [theme.breakpoints.down('md')]: {
      display: 'block'
    }
  },
  headingWrap: {
    flex: 1,
    '& > h1': {
      '& > div': {
        marginRight: 16
      },
      '& > *': {
        display: 'inline'
      },
      '& svg': {
        fontSize: 'inherit'
      }
    },
    [theme.breakpoints.down('md')]: {
      paddingBottom: 16
    }
  },
  year: {
    fontSize: '0.8em'
  },
  shareWrap: {
    display: 'flex',
    marginBottom: 32,
    '& > div': {
      marginLeft: 8,
      cursor: 'pointer'
    },
    '& > div:first-child': {
      marginLeft: 0
    }
  },
  buttons: {
    position: 'relative',
    top: -32,
    marginBottom: 16,
    '& > button, & > div, & > a': {
      display: 'inline-flex',
      marginRight: 8,
      verticalAlign: 'middle'
    },
    [theme.breakpoints.down('md')]: {
      position: 'static',
      marginBottom: 24
    }
  },
  columns: {
    '& > div:first-child': {
      paddingRight: 32,
      [theme.breakpoints.down('md')]: {
        paddingRight: 16
      },
      [theme.breakpoints.down('xs')]: {
        paddingRight: 0
      }
    },
    '& > div:last-child': {
      paddingLeft: 32,
      [theme.breakpoints.down('md')]: {
        paddingLeft: 16
      },
      [theme.breakpoints.down('xs')]: {
        paddingLeft: 0
      }
    }
  },
  galleryWrap: {
    position: 'sticky',
    top: 98,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 8
    },
    '& > a': {
      minHeight: 190 // for initial loading of image
    }
  },
  image: {
    width: '100%',
    height: 'auto'
  },
  hightlights: {
    marginTop: 16,
    marginBottom: 16,
    paddingBottom: 16,
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: theme.borderColor
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 32,
    '& > svg': {
      marginRight: 8
    },
    '& > span': {
      flex: 1,
      marginRight: 16
    },
    '& > a': {
      textAlign: 'center',
      [theme.breakpoints.down('xs')]: {
        maxWidth: 160
      }
    }
  },
  details: {
    marginBottom: 32
  },
  expansionPanel: {
    marginBottom: 16
  },
  expansionPanelDetails: {
    display: 'block',
    paddingTop: 24,
    '& button': {
      marginTop: 24
    }
  },
  leasingHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  priceWrap: {
    marginTop: 48,
    marginBottom: 48,
    '& > div:first-child': {
      textAlign: 'right',
      paddingRight: 24,
      [theme.breakpoints.down('xs')]: {
        paddingRight: 0
      }
    },
    '& > div:last-child': {
      paddingLeft: 24
    }
  },
  price: {
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.9em'
    }
  },
  hasDiscount: {
    marginTop: theme.spacing(2)
  },
  photoLink: {
    cursor: 'pointer',
    position: 'relative',
    display: 'block'
  },
  sold: {
    '& $image': {
      filter: 'grayscale(100%)'
    }
  },
  pagination: {
    marginTop: 96,
    marginBottom: 32
  },
  paginationCard: {
    backgroundColor: theme.backgroundColor,
    '& > a': {
      display: 'flex',
      alignItems: 'flex-end',
      height: 100
    }
  },
  paginationCardMedia: {
    width: 150,
    height: 'auto'
  },
  paginationCardContent: {
    flex: 1,
    height: '100%',
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
    '& > div': {
      flex: 1,
      overflow: 'hidden'
    },
    '& > div:first-child': {
      marginLeft: 8
    },
    '& > svg:first-child': {
      marginRight: 16
    }
  },
  paginationCardHighlights: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  alertLink: {
    display: 'inline-block',
    color: theme.palette.warning.light
  }
}));

export const Car = () => {
  const classes = useStyles();
  const { pushSnack } = useSnackbar();
  const sendDataToGTM = useGTMDispatch();

  // const title = useRef('');
  const imagesPreloaded = useRef(false);
  const imageLoadingTimeout = useRef<NodeJS.Timeout | undefined>(undefined);
  const preloadedImages = useRef<Image[]>([]);

  const [openRequestDialog, setOpenRequestDialog] = useState<boolean>(false);
  const [withMonthlyPrice, setWithMonthlyPrice] = useState<boolean>(false);
  const [openPhoto, setOpenPhoto] = useState<null | number>(null);
  const [monthlyPayment /* setMonthlyPayment */] = useState<null | number>(null);

  const matchParams = useParams<{ slug?: string; category?: string }>();
  const history = useHistory<{ from?: { category: string; filter: any } }>();

  const slug = matchParams.slug;
  const isSlug = !/^c\w{6,24}$/i.test(slug || '');

  const { data, error, loading } = useQuery<CarQuery>(query, {
    variables: isSlug ? { slug: slug } : { id: slug }
  });

  const car = data?.car || null;

  const [editCar, { loading: editLoading, error: editError }] = useMutation<
    EditCarMutation,
    EditCarMutationVariables
  >(editCarMutation, {
    update: (cache) => {
      if (car) {
        cache.evict({ id: `carsFilterOptions` });
        cache.evict({ id: `carsFilterBoundaries` });
        cache.evict({ id: `totalCars` });
        cache.gc();
      }
    },
    refetchQueries: ['CarPaginationQuery', 'BetterCarsQuery']
  });
  const [removeCar, { loading: removeLoading, error: removeError }] = useMutation<
    RemoveCarMutation,
    RemoveCarMutationVariables
  >(removeCarMutation, {
    update: (cache, { data }) => {
      if (data?.removeCar?.id) {
        cache.evict({ id: `Car:${data.removeCar.id}` });
        cache.evict({ id: 'Query', fieldName: `carsFilterOptions` });
        cache.evict({ id: 'Query', fieldName: `carsFilterBoundaries` });
        cache.evict({ id: 'Query', fieldName: `totalCars` });
        cache.gc();
      }
    }
  });

  if (editError || removeError) {
    console.error(editError || removeError);
  }

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {
      preloadedImages.current.forEach((image) => (image.src = ''));
      preloadedImages.current = [];

      if (imageLoadingTimeout.current) {
        clearTimeout(imageLoadingTimeout.current);
      }
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    imagesPreloaded.current = false;

    preloadedImages.current.forEach((image) => (image.src = ''));
    preloadedImages.current = [];

    if (imageLoadingTimeout.current) {
      clearTimeout(imageLoadingTimeout.current);
      imageLoadingTimeout.current = undefined;
    }
  }, [slug]);

  useEffect(() => {
    if (car) {
      sendDataToGTM({
        event: 'remarketing',
        productId: car.id,
        productPrice: car.price - car.discount
      });
    }
  }, [car, sendDataToGTM]);

  const handleFinancialDetailsClick = useCallback(
    (type: string) => (e: ChangeEvent<{}>, expanded: boolean) => {
      if (expanded) {
        sendDataToGTM({
          event: 'event',
          eventCategory: 'FinancialDetails',
          eventAction: 'Expand',
          eventValue: undefined,
          eventLabel: type
        });
      }
    },
    [sendDataToGTM]
  );

  // const handleMonthlyPaymentChange = useCallback((monthlyPayment) => {
  //   setMonthlyPayment(monthlyPayment);
  // }, []);

  const handleRequestDialogOpen = useCallback(
    (withMonthlyPrice = false) =>
      () => {
        setOpenRequestDialog(true);
        setWithMonthlyPrice(withMonthlyPrice);
      },
    []
  );

  const handleRequestDialogClose = useCallback(() => {
    setOpenRequestDialog(false);
  }, []);

  // preload big images for gallery
  const preloadImages = (images: string[]) => {
    if (!imagesPreloaded.current) {
      // disable on server
      if (typeof Image === 'function') {
        // delay preloading a little bit
        // @ts-ignore
        imageLoadingTimeout.current = setTimeout(
          () =>
            images.forEach((i) => {
              const image = new Image();
              preloadedImages.current.push(image);
              image.src = `${global.cdnUrl}/fotografia/${i}`;
            }),
          2000
        );
        imagesPreloaded.current = true;
      }
    }
  };

  if (loading) {
    return <Loading fullHeight />;
  }

  if (error) {
    return <GqlResponseHandler error={error} />;
  }

  const { category, filter } = (history.location.state && history.location.state.from) || {};
  const hasFilter = !!(filter && Object.keys(filter).find((key) => !!filter[key]));

  if (!car) {
    return <Redirect to="/404" />;
  }

  const canonicalUrl = `/vozidla-na-predaj/${car.mainCategory.slug}/${car.slug}`;

  // check category and slug (may be id in url instead so we want to redirect to slug)
  if (car.mainCategory.slug !== matchParams.category || car.slug !== slug) {
    return <Redirect to={canonicalUrl} />;
  }

  const images = [...(car.imageThumbs || [])];
  const mainImage = images.shift();

  const shareTitle: string = `${car.fullTitle} ${getYearString(car.year, car.month)}`;
  const shareDescription: string = `${[
    car.bodyName,
    car.fuelName,
    car.gearboxName,
    `${car.mileage.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} km`
  ]
    .concat(car.highlightsName || [])
    .join(', ')}`;

  const breadcrumbs: BreadcrumbsItem[] = [
    {
      label: 'Vozidlá na predaj',
      to: '/vozidla-na-predaj'
    }
  ];

  // add category to breadcrumbs
  breadcrumbs.push({
    label: car.mainCategory.name,
    to: `/vozidla-na-predaj/${car.mainCategory.slug}`
  });

  // add car to breadcrumbs
  breadcrumbs.push({
    label: car.fullTitle
  });

  let loadingOnButton: null | ActionButtonType = null;

  const sold = car.sold || !car.active;
  const finalPrice = car.price - car.discount;
  const isMotorbike = car.manufacturer === '192107';

  return (
    <UserContext.Consumer>
      {(user) => (
        <>
          <Helmet>
            <title>{`${car.fullTitle} ${getYearString(car.year, car.month)} - ${
              car.mainCategory.name
            } - Vozidlá na predaj - EDENbazar`}</title>
            <meta name="description" content={shareDescription} />
            <meta property="og:url" content={`${global.baseUrl}${canonicalUrl}`} />
            <meta property="og:title" content={shareTitle} />
            <meta property="og:description" content={shareDescription} />
            <meta
              property="og:image"
              content={`${global.cdnUrl.replace('https', 'http')}/fotografia/${
                car.facebookPostImage
              }`}
            />
            <meta
              property="og:image:secure_url"
              content={`${global.cdnUrl}/fotografia/${car.facebookPostImage}`}
            />
            <link rel="canonical" href={`${global.baseUrl}${canonicalUrl}`} />
            <script type="application/ld+json">
              {JSON.stringify({
                '@context': 'https://schema.org/',
                '@type': 'Product',
                sku: car.id,
                name: car.fullTitle,
                image: car.images?.map((image) => `${global.cdnUrl}/fotografia/${image}`) || [],
                description: shareDescription,
                url: `${global.baseUrl}${canonicalUrl}`,
                brand: car.manufacturerName,
                category: `${global.baseUrl}/vozidla-na-predaj/${car.mainCategory.slug}`,
                color: car.color,
                manufacturer: {
                  '@type': 'Organization',
                  name: car.manufacturerName
                },
                model: car.modelName,
                offers: {
                  '@type': 'Offer',
                  url: `${global.baseUrl}${canonicalUrl}`,
                  priceCurrency: 'EUR',
                  price: finalPrice,
                  itemCondition:
                    car.mileage < 20
                      ? 'https://schema.org/NewCondition'
                      : 'https://schema.org/UsedCondition',
                  availability: sold ? 'https://schema.org/SoldOut' : 'https://schema.org/InStock',
                  priceValidUntil: moment
                    .utc()
                    .startOf('day')
                    .add(sold ? 0 : 1, 'month')
                    .toISOString()
                },
                productionDate:
                  car.month &&
                  moment
                    .utc({ year: car.year, month: car.month - 1 })
                    .startOf('month')
                    .toISOString()
              })}
            </script>
          </Helmet>

          <CarBreadcrumbs breadcrumbs={breadcrumbs} category={category} hasFilter={hasFilter} />

          <ContentWrap paddingTop paddingBottom>
            <div className={classes.heading}>
              <PageHeading className={classes.headingWrap}>
                <div>{car.fullTitle}</div>

                <CarYear year={car.year} month={car.month} classes={{ root: classes.year }} />
              </PageHeading>

              <div className={classes.shareWrap}>
                <ShareIcons
                  url={`${global.baseUrl}${canonicalUrl}`}
                  shareTitle={shareTitle}
                  shareDescription={shareDescription}
                />
              </div>
            </div>

            {user ? (
              <div className={classes.buttons}>
                <ActionButtons
                  id={car.id}
                  soon={car.soon}
                  newOne={car.new}
                  discountOfWeek={car.discountOfWeek}
                  reserved={car.reserved}
                  sold={car.sold}
                  hasDiscount={!!car.discount}
                  loadingOnButton={
                    removeLoading ? 'remove' : (editLoading && loadingOnButton) || null
                  }
                  onPrint={() => {
                    window.open(`/tlac/${car.slug}`);
                  }}
                  onSoonChange={() => {
                    loadingOnButton = 'soon';
                    editCar({
                      variables: { id: car.id, data: { soon: !car.soon } }
                    });
                  }}
                  onNewChange={() => {
                    loadingOnButton = 'new';
                    editCar({
                      variables: { id: car.id, data: { new: !car.new } }
                    });
                  }}
                  onDiscountOfWeekChange={() => {
                    loadingOnButton = 'discountOfWeek';
                    editCar({
                      variables: {
                        id: car.id,
                        data: { discountOfWeek: !car.discountOfWeek }
                      }
                    });
                  }}
                  onReservedChange={() => {
                    loadingOnButton = 'reserved';
                    editCar({
                      variables: { id: car.id, data: { reserved: !car.reserved } }
                    });
                  }}
                  onSoldChange={() => {
                    loadingOnButton = 'sold';
                    editCar({
                      variables: { id: car.id, data: { sold: !car.sold } }
                    });
                  }}
                  onRemove={() =>
                    removeCar({ variables: { id: car.id } }).then(() => {
                      pushSnack({
                        message: 'Vozidlo bolo úspešne odstránené',
                        type: 'success'
                      });
                      history.push(`/vozidla-na-predaj/${car.mainCategory.slug}`);
                    })
                  }
                />

                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-evenly"
                  marginLeft={2}
                >
                  Viditeľné v:
                  {car.active && (
                    <Box mx={1}>
                      <EdenbazarIcon />
                    </Box>
                  )}
                  {car.autoskladActive && (
                    <Box mx={1}>
                      <AutoskladIcon />
                    </Box>
                  )}
                </Box>

                <Box marginLeft={2}>
                  <CarViews views={car.views || 0} />
                </Box>
              </div>
            ) : null}

            <Grid
              container
              spacing={0}
              className={sold ? classnames(classes.columns, classes.sold) : classes.columns}
            >
              <Grid item xs={12} sm={6}>
                <div className={classes.galleryWrap}>
                  <a
                    href={`${global.cdnUrl}/fotografia/${mainImage}`}
                    onClick={(event) => {
                      event.preventDefault();
                      setOpenPhoto(0);
                    }}
                    title={car.fullTitle}
                    className={classes.photoLink}
                  >
                    <img
                      src={`${global.cdnUrl}/fotografia/${mainImage}`}
                      alt={car.fullTitle}
                      className={classes.image}
                      width="600"
                      height="450"
                    />

                    <CarBadge car={car} />
                  </a>

                  <Gallery
                    images={images}
                    carTitle={car.fullTitle}
                    onPhotoClick={(key: number) => setOpenPhoto(key + 1)}
                  />

                  {car.authenticityReport ? (
                    <AuthenticityReport authenticityReport={car.authenticityReport} />
                  ) : null}
                </div>
              </Grid>

              <Grid item xs={12} sm={6}>
                <CarHighlights car={car} className={classes.hightlights} />

                {sold ? (
                  <div className={classes.info}>
                    <InfoIcon color="primary" />

                    <Typography variant="subtitle1" component="span" color="primary">
                      Vozidlo už nie je v ponuke.
                    </Typography>

                    <Button variant="text" component={Link} to="/vozidla-na-predaj" color="primary">
                      Zobraziť aktuálnu ponuku
                    </Button>
                  </div>
                ) : null}

                <div className={classes.details}>
                  <CarDetailsContainer label="Technické údaje" details={car.technicalDetails} />
                  <CarDetailsContainer label="Bezpečnost" details={car.safetyDetails} />
                  <CarDetailsContainer label="Komfort" details={car.comfortDetails} />
                  <CarDetailsContainer label="Ostatné" details={car.otherDetails} />
                  <CarDetailsContainer label="Stav vozidla" details={car.stateDetails} />
                  <CarDetailsContainer label="Poznámky" details={car.notes} />

                  {/* car.manufacturer === '192107' && (
                    <Box clone padding={2} marginTop={2} marginBottom={2}>
                      <Paper variant="outlined">
                        <Typography variant="subtitle1" component="span">
                          Nevyhovuje farba alebo model?
                        </Typography>

                        <Typography variant="body2">
                          Nevadí. Elektrické skútre a motocykle značky Super SOCO si u nás môžete
                          objednať v niekoľkých farebných prevedeniach.
                        </Typography>

                        <MuiLink component={Link} to="/super-soco">
                          Viac informáci
                        </MuiLink>
                      </Paper>
                    </Box>
                  ) */}

                  <MileageNote />
                </div>

                {!sold ? (
                  <>
                    {/* <Alert icon={<InfoIcon />} color="warning">
                              <AlertTitle>Nákup vozidiel v čase pandémie COVID-19</AlertTitle>
                              <div>
                                Pre COVID-19 sme znižili ceny pre väčšinu aút. Lacnejsie to už
                                nejde. Tak nakupujte jednoducho a bezpečne{' '}
                                <MuiLink
                                  component={Link}
                                  to="/online-predaj-vozidiel"
                                  className={classes.alertLink}
                                >
                                  online
                                </MuiLink>
                                .
                              </div>
                            </Alert> */}

                    <Grid container alignItems="center" className={classes.priceWrap}>
                      <Grid item xs={6}>
                        <Typography variant="body1" component="div">
                          Spolu s DPH
                        </Typography>

                        <DiscountBadge
                          discount={car.discount}
                          price={car.price}
                          className={classnames({
                            [classes.hasDiscount]: car.discount > 0
                          })}
                        >
                          <Typography variant="h4" component="div" className={classes.price}>
                            <Price currency="€" decimals={0}>
                              {finalPrice}
                            </Price>
                          </Typography>
                        </DiscountBadge>

                        {car.vatDeduction ? (
                          <Typography variant="caption" component="div" color="textSecondary">
                            možný odpočet DPH
                          </Typography>
                        ) : null}
                      </Grid>

                      <Grid item xs={6}>
                        <Button
                          variant="contained"
                          fullWidth
                          color="primary"
                          size="large"
                          onClick={handleRequestDialogOpen(false)}
                        >
                          Dohodnúť obhliadku
                        </Button>
                      </Grid>
                    </Grid>
                  </>
                ) : null}

                {!sold && !isMotorbike ? (
                  <div className={classes.expansionPanel}>
                    <Accordion
                      onChange={handleFinancialDetailsClick('Leasing')}
                      /* defaultExpanded */
                      expanded={false}
                    >
                      <AccordionSummary
                        // expandIcon={<ExpandMoreIcon />}
                        classes={{ content: classes.leasingHeader }}
                      >
                        <Typography variant="subtitle2">Financovanie </Typography>

                        <Button
                          size="small"
                          variant="contained"
                          color="primary"
                          style={{ marginBottom: -4, marginTop: -4 }}
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`mailto:info@edenbazar.sk?subject=Financovanie pre ${
                            car.fullTitle
                          }&body=%0A%0AVozidlo: ${`${global.baseUrl}${canonicalUrl}`}`}
                        >
                          Vyžiadať ponuku
                        </Button>
                        {/* <Chip
                              size="small"
                              label={`od ${car.lowestMonthlyPrice}€ mesačne`}
                              color="primary"
                            /> */}
                      </AccordionSummary>

                      {/* <AccordionDetails className={classes.expansionPanelDetails}>
                            <Calculator
                              totalPrice={finalPrice}
                              onMountlyPaymentChange={handleMonthlyPaymentChange}
                            />
    
                            <Button
                              fullWidth
                              variant="contained"
                              color="primary"
                              onClick={handleRequestDialogOpen(true)}
                            >
                              Kontaktujte nás
                            </Button>
                          </AccordionDetails> */}
                    </Accordion>
                  </div>
                ) : null}

                {!sold ? (
                  <div className={classes.expansionPanel}>
                    <Accordion onChange={handleFinancialDetailsClick('Insurance')}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="subtitle2">Poistenie</Typography>
                      </AccordionSummary>

                      <AccordionDetails className={classes.expansionPanelDetails}>
                        <Typography variant="body2">
                          Výpočet PZP zatiaľ nie je dostupný.
                          <br />
                          Pre viac informácií nás prosím kontaktujte.
                        </Typography>

                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          onClick={handleRequestDialogOpen(false)}
                        >
                          Kontaktovať
                        </Button>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                ) : null}
              </Grid>
            </Grid>

            <NoSsr>
              <CarPagination cursor={car.id} state={history.location.state} />

              <BetterCars
                id={car.id}
                price={finalPrice}
                category={category || car.mainCategory.slug}
              />
            </NoSsr>
          </ContentWrap>

          <NoSsr>
            <SendRequestDialog
              open={openRequestDialog}
              car={car}
              handleClose={handleRequestDialogClose}
              monthlyPayment={withMonthlyPrice ? monthlyPayment : null}
            />

            <PhotoDialog
              photo={(typeof openPhoto === 'number' && car.images && car.images[openPhoto]) || null}
              title={car.fullTitle}
              hasPrev={typeof openPhoto === 'number' && openPhoto > 0}
              hasNext={
                typeof openPhoto === 'number' && !!car.images && openPhoto < car.images.length - 1
              }
              openPrev={() =>
                setOpenPhoto(typeof openPhoto === 'number' && openPhoto > 0 ? openPhoto - 1 : null)
              }
              openNext={() =>
                setOpenPhoto(
                  typeof openPhoto === 'number' && car.images && openPhoto < car.images.length - 1
                    ? openPhoto + 1
                    : null
                )
              }
              onClose={() => setOpenPhoto(null)}
            />

            {car.images && preloadImages(car.images as string[])}
          </NoSsr>
        </>
      )}
    </UserContext.Consumer>
  );
};
