// core
import React, { useState, useCallback, ChangeEvent } from 'react';

// apollo
import { useQuery } from '@apollo/client';
import query from './graphql/AllCarsQuery.graphql';
import { AllCarsQuery } from './graphql/AllCarsQuery';

// context
import { UserContext } from 'AppRouter';

// libraries
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import debounce from 'lodash.debounce';

// material
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MuiLink from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

// material icons
import SoonIcon from '@material-ui/icons/WatchLater';
import NotSoonIcon from '@material-ui/icons/WatchLaterOutlined';
import NewIcon from '@material-ui/icons/FiberNew';
import NotNewIcon from '@material-ui/icons/FiberNewOutlined';
import DiscountIcon from '@material-ui/icons/Whatshot';
import NotDiscountIcon from '@material-ui/icons/WhatshotOutlined';
import ReservedIcon from '@material-ui/icons/Lock';
import NotReservedIcon from '@material-ui/icons/LockOpen';
import SoldIcon from '@material-ui/icons/MonetizationOn';
import NotSoldIcon from '@material-ui/icons/AttachMoney';
import SearchIcon from '@material-ui/icons/Search';

// components
import { ContentWrap } from 'components/ContentWrap';
import { PageHeading } from 'components/PageHeading';
import { EmptyMessage } from 'components/EmptyMessage';
import { Price } from 'components/Price';
import { GqlResponseHandler } from 'components/GqlResponseHandler';
import { Pagination } from 'components/Pagination';
import { EdenbazarIcon } from 'components/EdenbazarIcon';
import { AutoskladIcon } from 'components/AutoskladIcon';

const useStyles = makeStyles((theme) => ({
  list: {
    paddingTop: 96,
    paddingBottom: 64,
    [theme.breakpoints.down('md')]: {
      paddingTop: 64
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 48
    },
    '& > h1': {
      marginTop: 32
    }
  },
  price: {
    whiteSpace: 'nowrap'
  },
  icons: {
    whiteSpace: 'nowrap',
    '& > svg': {
      marginLeft: 12,
      color: theme.palette.text.secondary
    }
  },
  soonIcon: {
    color: '#673ab7'
  },
  newIcon: {
    color: '#009688 !important'
  },
  discountIcon: {
    color: '#ff5722 !important'
  },
  reservedIcon: {
    color: '#263238 !important'
  },
  soldIcon: {
    color: '#4caf50 !important'
  },
  pagination: {
    width: '100%',
    textAlign: 'right',
    marginTop: 16
  }
}));

export const AllCars = () => {
  const classes = useStyles();

  const [offset, setOffset] = useState(0);
  const [search, setSearch] = useState('');
  const [searchFilter, setSearchFilter] = useState('');

  const { data, loading, error } = useQuery<AllCarsQuery>(query, {
    variables: { offset, search: searchFilter }
  });

  const { cars, totalCars } = data || {};

  const handlePaginationClick = useCallback((e, newOffset) => {
    setOffset(newOffset);
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((value: string) => {
      setSearchFilter(value);
      setOffset(0);
    }, 500),
    []
  );

  const handleSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setSearch(value);
      debouncedSearch(value);
    },
    [debouncedSearch]
  );

  return (
    <UserContext.Consumer>
      {(user) => {
        if (!user) {
          return null;
        }

        return (
          <ContentWrap wider>
            <>
              <Helmet>
                <title>Všetky vozidlá - EDENbazar</title>
              </Helmet>

              <div className={classes.list}>
                <PageHeading
                  buttons={
                    user
                      ? [
                          {
                            label: 'Pridať vozidlo',
                            to: '/pridat-vozidlo'
                          }
                        ]
                      : undefined
                  }
                >
                  Všetky vozidlá
                </PageHeading>

                <TextField
                  label="Vyhľadávanie"
                  value={search}
                  onChange={handleSearch}
                  helperText="Vyhľadať vozidlo podľa výrobcu, modelu, názvu alebo VIN čísla."
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                />

                <GqlResponseHandler loading={loading} error={error}>
                  {!cars || cars.length === 0 ? (
                    <EmptyMessage message="Momentálne nie sú pridané žiadne vozidlá" />
                  ) : (
                    <>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Vozidlo</TableCell>
                            <TableCell>VIN</TableCell>
                            <TableCell>Dátum výroby</TableCell>
                            <TableCell align="right">Cena</TableCell>
                            <TableCell align="right">Zľava</TableCell>
                            <TableCell align="center">Zobrazenie</TableCell>
                            <TableCell />
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {cars.map((car) => (
                            <TableRow key={car.id}>
                              <TableCell component="th" scope="row">
                                <MuiLink
                                  component={Link}
                                  to={`/vozidla-na-predaj/${car.mainCategory.slug}/${car.slug}`}
                                >
                                  {car.fullTitle}
                                </MuiLink>
                              </TableCell>
                              <TableCell>{car.vin}</TableCell>
                              <TableCell>
                                {car.year}
                                {car.month ? `/${car.month}` : null}
                              </TableCell>
                              <TableCell align="right" className={classes.price}>
                                <Price currency="€" decimals={0}>
                                  {car.price}
                                </Price>
                              </TableCell>
                              <TableCell align="right" className={classes.price}>
                                <Price currency="€" decimals={0}>
                                  {car.discount}
                                </Price>
                              </TableCell>
                              <TableCell align="center">
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="space-evenly"
                                >
                                  {car.active && <EdenbazarIcon />}
                                  {car.autoskladActive && <AutoskladIcon />}
                                </Box>
                              </TableCell>
                              <TableCell align="right" className={classes.icons}>
                                {car.soon ? (
                                  <SoonIcon className={classes.soonIcon} />
                                ) : (
                                  <NotSoonIcon />
                                )}
                                {car.new ? <NewIcon className={classes.newIcon} /> : <NotNewIcon />}
                                {car.discountOfWeek ? (
                                  <DiscountIcon className={classes.discountIcon} />
                                ) : (
                                  <NotDiscountIcon />
                                )}
                                {car.reserved ? (
                                  <ReservedIcon className={classes.reservedIcon} />
                                ) : (
                                  <NotReservedIcon />
                                )}
                                {car.sold ? (
                                  <SoldIcon className={classes.soldIcon} />
                                ) : (
                                  <NotSoldIcon />
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>

                      <Pagination
                        limit={10}
                        offset={offset}
                        total={totalCars || 0}
                        onChange={handlePaginationClick}
                        className={classes.pagination}
                      />
                    </>
                  )}
                </GqlResponseHandler>
              </div>
            </>
          </ContentWrap>
        );
      }}
    </UserContext.Consumer>
  );
};
