// core
import React, { useCallback, CSSProperties } from 'react';

// libraries
import classnames from 'classnames';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

// material
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';

// material icons
import FullRemoveIcon from '@material-ui/icons/DeleteForeverOutlined';
import EmptyRemoveIcon from '@material-ui/icons/DeleteOutlined';

export type Attachment = {
  id: string;
  name: string;
  url: string;
  mimetype: string;
};

export interface Props {
  attachments: Attachment[];
  idsToSave: string[];
  disabled: boolean;
  onRemoveToogle: (id: string) => void;
  onSortEnd: (data: { oldIndex: number; newIndex: number }) => void;
}

export type ItemClasses = {
  item: string;
  deleted: string;
};

export interface ItemProps {
  index: number;
  value: string;
  url: string;
  isDeleted: boolean;
  title: string;
  mimetype: string;
  disabled: boolean;
  onRemoveToogle: (value: string) => void;
  classes: ItemClasses;
  style?: CSSProperties;
}

export interface ContainerProps {
  items: Attachment[];
  idsToSave: string[];
  disabled: boolean;
  onRemoveToogle: (value: string) => void;
  classes: ItemClasses;
}

const useStyles = makeStyles((theme) => ({
  item: {
    listStyle: 'none',
    cursor: 'grab'
  },
  deleted: {
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(255, 255, 255, 0.8)'
    }
  }
}));

const SortableItem = SortableElement(
  ({
    index,
    value,
    url,
    isDeleted,
    title,
    mimetype,
    disabled,
    onRemoveToogle,
    classes,
    style
  }: ItemProps) => {
    const handleDeleteToogle = useCallback(() => {
      onRemoveToogle(value);
    }, [onRemoveToogle, value]);

    return (
      <ListItem
        key={index}
        style={style}
        className={classnames(classes.item, { [classes.deleted]: isDeleted })}
      >
        <ListItemText primary={title} secondary={mimetype} />

        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-label="delete"
            onClick={handleDeleteToogle}
            disabled={disabled}
          >
            {isDeleted ? <FullRemoveIcon /> : <EmptyRemoveIcon />}
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    );
  }
);

const SortableList = SortableContainer(
  ({ items, idsToSave, disabled, onRemoveToogle, classes }: ContainerProps) => {
    return (
      <List>
        {items.map((item, key) => {
          const isDeleted = !~idsToSave.indexOf(item.id);

          return (
            <SortableItem
              key={`item-${key}`}
              index={key}
              value={item.id}
              url={item.url}
              isDeleted={isDeleted}
              title={item.name}
              mimetype={item.mimetype}
              disabled={disabled}
              onRemoveToogle={onRemoveToogle}
              classes={classes}
            />
          );
        })}
      </List>
    );
  }
);

export const CarAttachments = ({ attachments, onSortEnd, ...passingProps }: Props) => {
  const classes = useStyles();

  return (
    <SortableList
      axis="y"
      classes={classes}
      items={attachments}
      onSortEnd={onSortEnd}
      helperClass={classes.item}
      shouldCancelStart={(event) =>
        !['li', 'div', 'span'].includes((event.target as HTMLElement).tagName.toLowerCase())
      }
      {...passingProps}
    />
  );
};
