// core
import React, { useState, useCallback } from 'react';

// material
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Link from '@material-ui/core/Link';
import Rating from '@material-ui/lab/Rating';

export interface Props {
  reviewerName: string;
  reviewerPhoto: string;
  rating: number;
  comment: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7)
  },
  cardHeader: {
    paddingBottom: 0
  },
  link: {
    cursor: 'pointer'
  }
}));

export const Review = ({ reviewerName, reviewerPhoto, rating, comment }: Props) => {
  const css = useStyles();

  const [expanded, setExpanded] = useState(false);

  const handleExpand = useCallback(() => {
    setExpanded(true);
  }, []);

  const handleCollapse = useCallback(() => {
    setExpanded(false);
  }, []);

  return (
    <Card variant="outlined" className={css.root}>
      <CardHeader
        avatar={<Avatar alt={reviewerName} src={reviewerPhoto} className={css.avatar} />}
        title={<Typography variant="h6">{reviewerName}</Typography>}
        subheader={<Rating readOnly value={rating} />}
        className={css.cardHeader}
      />

      <CardContent>
        <Typography variant="body1" color="textSecondary">
          {expanded ? comment : comment.substring(0, 130)}
          {comment.length > 130 && expanded ? (
            <>
              {' '}
              <Link onClick={handleCollapse} className={css.link}>
                zavrieť
              </Link>
            </>
          ) : (
            <>
              ...{' '}
              <Link onClick={handleExpand} className={css.link}>
                čítať ďalej
              </Link>
            </>
          )}
        </Typography>
      </CardContent>
    </Card>
  );
};
