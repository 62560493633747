// core
import React, { ReactNode, useState, useEffect, useCallback, SyntheticEvent } from 'react';

// materia-ui
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import UISnackbar from '@material-ui/core/Snackbar';
import WarningIcon from '@material-ui/icons/Warning';
import IconButton from '@material-ui/core/IconButton';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

export interface Snack {
  type?: 'info' | 'error' | 'warning' | 'success' | 'default';
  message: ReactNode;
  action?: {
    label?: ReactNode;
    handler: () => void;
  };
  disableAutohide?: boolean;
}

const useStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: '#43a047'
  },
  error: {
    backgroundColor: '#d32f2f'
  },
  info: {
    backgroundColor: '#1976d2'
  },
  warning: {
    backgroundColor: '#ffa000'
  },
  default: {},
  icon: {
    marginRight: theme.spacing(1),
    opacity: 0.9,
    fontSize: 20
  },
  message: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap'
  }
}));

const INSTANCE: {
  queue: Snack[];
  setSnack: null | ((snack: Snack | null) => void);
  setOpen: null | ((open: boolean) => void);
} = {
  queue: [],
  setSnack: null,
  setOpen: null
};

const SnackType = {
  default: 'default',
  warning: 'warning',
  success: 'success',
  error: 'error',
  info: 'info'
};

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const AUTOHIDE_INTERVAL = 4000;

const processQueue = () => {
  if (INSTANCE.setSnack && INSTANCE.setOpen && INSTANCE.queue.length > 0) {
    INSTANCE.setSnack(INSTANCE.queue.shift() || null);
    INSTANCE.setOpen(true);
  }
};

export const Snackbar = () => {
  const css = useStyles();

  const [snack, setSnack] = useState<null | Snack>(null);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    INSTANCE.setSnack = setSnack;
    INSTANCE.setOpen = setOpen;

    return () => {
      INSTANCE.setSnack = null;
      INSTANCE.setOpen = null;
    };
  }, []);

  const handleExited = useCallback(() => {
    processQueue();
  }, []);

  const handleClose = useCallback(
    (event: SyntheticEvent<HTMLButtonElement>, reason?: 'timeout' | 'clickaway'): void => {
      if (reason === 'clickaway') {
        return;
      }
      setOpen(false);
    },
    []
  );

  const variant = snack && snack.type ? snack.type : 'default';
  const snackAction = snack && snack.action ? snack.action : null;
  let handler;

  if (snackAction) {
    handler = () => {
      setOpen(false);
      if (snackAction.handler) {
        snackAction.handler();
      }
    };
  }

  const action = snackAction ? (
    <Button
      key="snackAction"
      color={variant === 'error' ? 'primary' : 'default'}
      size="small"
      onClick={handler}
    >
      {snackAction.label}
    </Button>
  ) : null;

  const Icon =
    variant !== 'default' && SnackType && Object.keys(SnackType).find((it) => it === variant)
      ? variantIcon[variant]
      : null;

  const autohideDuration =
    snack && snack.disableAutohide === true ? null : AUTOHIDE_INTERVAL || 3000;

  return (
    <UISnackbar
      open={open}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      message={
        <span id="client-snackbar" className={css.message}>
          {Icon && <Icon className={css.icon} />}
          {snack && snack.message}
        </span>
      }
      ContentProps={{ 'aria-describedby': 'client-snackbar', className: css[variant] }}
      TransitionProps={{
        onExited: handleExited
      }}
      onClose={handleClose}
      action={[
        action,
        <IconButton key="close" aria-label="Close" color="inherit" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      ]}
      autoHideDuration={autohideDuration}
    />
  );
};

export const useSnackbar = () => {
  const pushSnack = (snack: Snack) => {
    if (INSTANCE.queue.indexOf(snack) === -1) {
      INSTANCE.queue.push(snack);
    }

    if (INSTANCE.setOpen) {
      INSTANCE.setOpen(false);
      processQueue();
    }
  };

  return {
    pushSnack
  };
};
