// core
import React, { Children } from 'react';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import Grid, { GridProps } from '@material-ui/core/Grid';

export interface Props extends GridProps {}

const useStyles = makeStyles((theme) => ({
  item: {
    paddingRight: 12,
    paddingLeft: 12,
    '&:first-child': {
      paddingLeft: 0
    },
    '&:last-child': {
      paddingRight: 0
    },
    [theme.breakpoints.down('md')]: {
      paddingRight: 8,
      paddingLeft: 8
    },
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0,
      paddingLeft: 0
    }
  }
}));

export const FormFieldsGrid = ({ children, classes, ...passingProps }: Props) => {
  const css = useStyles();

  return (
    <Grid container spacing={0} {...passingProps}>
      {Children.map(
        children,
        (child) =>
          child && (
            <Grid item xs={12} sm className={css.item}>
              {child}
            </Grid>
          )
      )}
    </Grid>
  );
};
