// apollo
import { useQuery } from '@apollo/client';
import categoriesQuery from '../graphql/CategoriesQuery.graphql';
import {
  CategoriesQuery,
  CategoriesQuery_categories as CategoryType
} from '../graphql/CategoriesQuery';

export type Category = CategoryType;

export const useCategories = () => {
  const { data, loading, error } = useQuery<CategoriesQuery>(categoriesQuery);

  const categories = data
    ? [
        {
          __typename: 'Category',
          slug: 'zlava-tyzdna',
          name: 'Zľava týždňa',
          image: ''
        } as Category,
        ...(data?.categories || [])
      ]
    : [];

  return { categories, loading, error };
};
